// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_List = require("rescript/lib/js/belt_List.js");
var LogicGraph$ReactHooksTemplate = require("./LogicGraph.bs.js");

function addLogicToEdges(_questions, _graph) {
  while(true) {
    var graph = _graph;
    var questions = _questions;
    if (!questions) {
      return graph;
    }
    var tl = questions.tl;
    if (!tl) {
      return graph;
    }
    _graph = LogicGraph$ReactHooksTemplate.addEdge({
          TAG: /* DefaultLogic */0,
          _0: questions.hd,
          _1: {
            TAG: /* Question */1,
            _0: tl.hd
          }
        }, graph);
    _questions = tl;
    continue ;
  };
}

function changeEdgeForBranchLogic(fromQuestion, toQuestion, comparator, graph) {
  return LogicGraph$ReactHooksTemplate.changeEdge(fromQuestion, {
              TAG: /* JumpTo */2,
              _0: fromQuestion,
              _1: toQuestion,
              _2: {
                hd: {
                  TAG: /* BranchLogic */0,
                  _0: fromQuestion,
                  _1: comparator
                },
                tl: /* [] */0
              }
            }, graph);
}

function changeEdges(surveyName, questions, thankYouList, graph) {
  if (Belt_List.length(questions) > 0 && surveyName !== "t_180_survey") {
    if (surveyName === "t_20_survey") {
      return LogicGraph$ReactHooksTemplate.addEdge({
                  TAG: /* DefaultLogic */0,
                  _0: Belt_List.getExn(questions, 1),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 3)
                  }
                }, changeEdgeForBranchLogic(Belt_List.getExn(questions, 0), {
                      TAG: /* Question */1,
                      _0: Belt_List.getExn(questions, 2)
                    }, {
                      TAG: /* EqualsFromList */1,
                      _0: {
                        hd: "9",
                        tl: {
                          hd: "10",
                          tl: /* [] */0
                        }
                      }
                    }, changeEdgeForBranchLogic(Belt_List.getExn(questions, 0), {
                          TAG: /* Question */1,
                          _0: Belt_List.getExn(questions, 1)
                        }, {
                          TAG: /* Conditional */2,
                          _0: {
                            TAG: /* LessThan */1,
                            _0: {
                              TAG: /* Int */0,
                              _0: "9"
                            }
                          }
                        }, graph)));
    } else if (surveyName === "retail_asset_onboarding") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 3),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 5)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 3),
                      _1: {
                        TAG: /* EqualsFromList */1,
                        _0: {
                          hd: "1",
                          tl: {
                            hd: "2",
                            tl: {
                              hd: "3",
                              tl: {
                                hd: "4",
                                tl: {
                                  hd: "5",
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, changeEdgeForBranchLogic(Belt_List.getExn(questions, 1), {
                      TAG: /* Question */1,
                      _0: Belt_List.getExn(questions, 4)
                    }, {
                      TAG: /* EqualsFromList */1,
                      _0: {
                        hd: "IZdi14T5We9NtbDr",
                        tl: {
                          hd: "8xoHDHTM9GCELio9",
                          tl: /* [] */0
                        }
                      }
                    }, graph));
    } else if (surveyName === "retail_asset_existing_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), {
                  TAG: /* SkipLogic */1,
                  _0: Belt_List.getExn(questions, 4),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 5)
                  },
                  _2: {
                    hd: {
                      TAG: /* RatingGroupLogic */2,
                      _0: Belt_List.getExn(questions, 4),
                      _1: "OuSsnjtRa9OqqmlX",
                      _2: {
                        TAG: /* Conditional */2,
                        _0: {
                          TAG: /* GreaterThan */2,
                          _0: {
                            TAG: /* Int */0,
                            _0: "3"
                          }
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 2),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 4)
                      },
                      _2: {
                        hd: {
                          TAG: /* MultipleAnswerLogic */1,
                          _0: Belt_List.getExn(questions, 2),
                          _1: {
                            TAG: /* NotContains */1,
                            _0: "Ap2FoSkBBHtGRDDw"
                          }
                        },
                        tl: /* [] */0
                      }
                    }, graph));
    } else if (surveyName === "retail_asset_existing_v01") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), {
                  TAG: /* SkipLogic */1,
                  _0: Belt_List.getExn(questions, 4),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 5)
                  },
                  _2: {
                    hd: {
                      TAG: /* RatingGroupLogic */2,
                      _0: Belt_List.getExn(questions, 4),
                      _1: "OuSsnjtRa9OqqmlX",
                      _2: {
                        TAG: /* Conditional */2,
                        _0: {
                          TAG: /* GreaterThan */2,
                          _0: {
                            TAG: /* Int */0,
                            _0: "3"
                          }
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 2),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 4)
                      },
                      _2: {
                        hd: {
                          TAG: /* MultipleAnswerLogic */1,
                          _0: Belt_List.getExn(questions, 2),
                          _1: {
                            TAG: /* NotContains */1,
                            _0: "AMC3CL9Bz0wG9FOI"
                          }
                        },
                        tl: /* [] */0
                      }
                    }, graph));
    } else if (surveyName === "netbanking") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 6), {
                  TAG: /* SkipLogic */1,
                  _0: Belt_List.getExn(questions, 6),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 7)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 6),
                      _1: {
                        TAG: /* EqualsFromList */1,
                        _0: {
                          hd: "4",
                          tl: {
                            hd: "5",
                            tl: /* [] */0
                          }
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, changeEdgeForBranchLogic(Belt_List.getExn(questions, 4), {
                      TAG: /* Question */1,
                      _0: Belt_List.getExn(questions, 6)
                    }, {
                      TAG: /* EqualsFromList */1,
                      _0: {
                        hd: "4",
                        tl: {
                          hd: "5",
                          tl: /* [] */0
                        }
                      }
                    }, changeEdgeForBranchLogic(Belt_List.getExn(questions, 2), {
                          TAG: /* Question */1,
                          _0: Belt_List.getExn(questions, 4)
                        }, {
                          TAG: /* EqualsFromValue */0,
                          _0: "e-JY9ZOK-jM3CYixPj6u"
                        }, graph)));
    } else if (surveyName === "branch_banking_financial" || surveyName === "branch_banking_non_financial") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 7), {
                  TAG: /* SkipLogic */1,
                  _0: Belt_List.getExn(questions, 7),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 8)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 7),
                      _1: {
                        TAG: /* EqualsFromList */1,
                        _0: {
                          hd: "4",
                          tl: {
                            hd: "5",
                            tl: /* [] */0
                          }
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, changeEdgeForBranchLogic(Belt_List.getExn(questions, 5), {
                      TAG: /* Question */1,
                      _0: Belt_List.getExn(questions, 7)
                    }, {
                      TAG: /* EqualsFromList */1,
                      _0: {
                        hd: "4",
                        tl: {
                          hd: "5",
                          tl: /* [] */0
                        }
                      }
                    }, changeEdgeForBranchLogic(Belt_List.getExn(questions, 3), {
                          TAG: /* Question */1,
                          _0: Belt_List.getExn(questions, 5)
                        }, {
                          TAG: /* EqualsFromList */1,
                          _0: {
                            hd: "4",
                            tl: {
                              hd: "5",
                              tl: /* [] */0
                            }
                          }
                        }, graph)));
    } else if (surveyName === "RM_Survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 1),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 4)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 1),
                      _1: {
                        TAG: /* EqualsFromValue */0,
                        _0: "LLR4rOd2MP-jVcA9GjM"
                      }
                    },
                    tl: /* [] */0
                  }
                }, graph);
    } else if (surveyName === "RM_Survey_Non_Individual") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 1),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 4)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 1),
                      _1: {
                        TAG: /* EqualsFromValue */0,
                        _0: "8ae54a4b-2a91-4531-b753-8938b2a7460e"
                      }
                    },
                    tl: /* [] */0
                  }
                }, graph);
    } else if (surveyName === "rm_affluent_individual_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 1),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 4)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 1),
                      _1: {
                        TAG: /* EqualsFromValue */0,
                        _0: "EvTsh2mKlcDN3D4W"
                      }
                    },
                    tl: /* [] */0
                  }
                }, graph);
    } else if (surveyName === "complaint_resolution_survey_v01") {
      return changeEdgeForBranchLogic(Belt_List.getExn(questions, 4), {
                  TAG: /* Question */1,
                  _0: Belt_List.getExn(questions, 6)
                }, {
                  TAG: /* EqualsFromList */1,
                  _0: {
                    hd: "4",
                    tl: {
                      hd: "5",
                      tl: /* [] */0
                    }
                  }
                }, changeEdgeForBranchLogic(Belt_List.getExn(questions, 0), {
                      TAG: /* Question */1,
                      _0: Belt_List.getExn(questions, 2)
                    }, {
                      TAG: /* EqualsFromList */1,
                      _0: {
                        hd: "4",
                        tl: {
                          hd: "5",
                          tl: /* [] */0
                        }
                      }
                    }, graph));
    } else if (surveyName === "complaint_resolution_survey") {
      return changeEdgeForBranchLogic(Belt_List.getExn(questions, 3), {
                  TAG: /* Question */1,
                  _0: Belt_List.getExn(questions, 5)
                }, {
                  TAG: /* EqualsFromList */1,
                  _0: {
                    hd: "4",
                    tl: {
                      hd: "5",
                      tl: /* [] */0
                    }
                  }
                }, graph);
    } else if (surveyName === "SRM_Survey") {
      return changeEdgeForBranchLogic(Belt_List.getExn(questions, 3), {
                  TAG: /* Question */1,
                  _0: Belt_List.getExn(questions, 5)
                }, {
                  TAG: /* EqualsFromList */1,
                  _0: {
                    hd: "4",
                    tl: {
                      hd: "5",
                      tl: /* [] */0
                    }
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 2),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 5)
                      },
                      _2: {
                        hd: {
                          TAG: /* BranchLogic */0,
                          _0: Belt_List.getExn(questions, 1),
                          _1: {
                            TAG: /* EqualsFromValue */0,
                            _0: "nMe7GqkvRWLZh2qdC1nd"
                          }
                        },
                        tl: /* [] */0
                      }
                    }, graph));
    } else if (surveyName === "seb_mib_renewals_enhancement") {
      return LogicGraph$ReactHooksTemplate.addEdge({
                  TAG: /* DefaultLogic */0,
                  _0: Belt_List.getExn(questions, 1),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 3)
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 0),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 3)
                      },
                      _2: {
                        hd: {
                          TAG: /* BranchLogic */0,
                          _0: Belt_List.getExn(questions, 0),
                          _1: {
                            TAG: /* EqualsFromList */1,
                            _0: {
                              hd: "7",
                              tl: {
                                hd: "8",
                                tl: /* [] */0
                              }
                            }
                          }
                        },
                        tl: /* [] */0
                      }
                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                          TAG: /* JumpTo */2,
                          _0: Belt_List.getExn(questions, 0),
                          _1: {
                            TAG: /* Question */1,
                            _0: Belt_List.getExn(questions, 2)
                          },
                          _2: {
                            hd: {
                              TAG: /* BranchLogic */0,
                              _0: Belt_List.getExn(questions, 0),
                              _1: {
                                TAG: /* Conditional */2,
                                _0: {
                                  TAG: /* LessThan */1,
                                  _0: {
                                    TAG: /* Int */0,
                                    _0: "7"
                                  }
                                }
                              }
                            },
                            tl: /* [] */0
                          }
                        }, graph)));
    } else if (surveyName === "seb_mib_survey_ntb_customers") {
      return LogicGraph$ReactHooksTemplate.addEdge({
                  TAG: /* DefaultLogic */0,
                  _0: Belt_List.getExn(questions, 4),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 6)
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 3),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 6)
                      },
                      _2: {
                        hd: {
                          TAG: /* BranchLogic */0,
                          _0: Belt_List.getExn(questions, 3),
                          _1: {
                            TAG: /* EqualsFromList */1,
                            _0: {
                              hd: "7",
                              tl: {
                                hd: "8",
                                tl: /* [] */0
                              }
                            }
                          }
                        },
                        tl: /* [] */0
                      }
                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), {
                          TAG: /* JumpTo */2,
                          _0: Belt_List.getExn(questions, 3),
                          _1: {
                            TAG: /* Question */1,
                            _0: Belt_List.getExn(questions, 5)
                          },
                          _2: {
                            hd: {
                              TAG: /* BranchLogic */0,
                              _0: Belt_List.getExn(questions, 3),
                              _1: {
                                TAG: /* Conditional */2,
                                _0: {
                                  TAG: /* LessThan */1,
                                  _0: {
                                    TAG: /* Int */0,
                                    _0: "7"
                                  }
                                }
                              }
                            },
                            tl: /* [] */0
                          }
                        }, LogicGraph$ReactHooksTemplate.addEdge({
                              TAG: /* DefaultLogic */0,
                              _0: Belt_List.getExn(questions, 1),
                              _1: {
                                TAG: /* Question */1,
                                _0: Belt_List.getExn(questions, 3)
                              }
                            }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                                  TAG: /* JumpTo */2,
                                  _0: Belt_List.getExn(questions, 0),
                                  _1: {
                                    TAG: /* Question */1,
                                    _0: Belt_List.getExn(questions, 3)
                                  },
                                  _2: {
                                    hd: {
                                      TAG: /* BranchLogic */0,
                                      _0: Belt_List.getExn(questions, 0),
                                      _1: {
                                        TAG: /* EqualsFromList */1,
                                        _0: {
                                          hd: "7",
                                          tl: {
                                            hd: "8",
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    },
                                    tl: /* [] */0
                                  }
                                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                                      TAG: /* JumpTo */2,
                                      _0: Belt_List.getExn(questions, 0),
                                      _1: {
                                        TAG: /* Question */1,
                                        _0: Belt_List.getExn(questions, 2)
                                      },
                                      _2: {
                                        hd: {
                                          TAG: /* BranchLogic */0,
                                          _0: Belt_List.getExn(questions, 0),
                                          _1: {
                                            TAG: /* Conditional */2,
                                            _0: {
                                              TAG: /* LessThan */1,
                                              _0: {
                                                TAG: /* Int */0,
                                                _0: "7"
                                              }
                                            }
                                          }
                                        },
                                        tl: /* [] */0
                                      }
                                    }, graph))))));
    } else if (surveyName === "corporate_banking_survey") {
      return LogicGraph$ReactHooksTemplate.addEdge({
                  TAG: /* DefaultLogic */0,
                  _0: Belt_List.getExn(questions, 1),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 3)
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 0),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 2)
                      },
                      _2: {
                        hd: {
                          TAG: /* BranchLogic */0,
                          _0: Belt_List.getExn(questions, 0),
                          _1: {
                            TAG: /* Conditional */2,
                            _0: {
                              TAG: /* LessThan */1,
                              _0: {
                                TAG: /* Int */0,
                                _0: "9"
                              }
                            }
                          }
                        },
                        tl: /* [] */0
                      }
                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                          TAG: /* JumpTo */2,
                          _0: Belt_List.getExn(questions, 0),
                          _1: {
                            TAG: /* Question */1,
                            _0: Belt_List.getExn(questions, 1)
                          },
                          _2: {
                            hd: {
                              TAG: /* BranchLogic */0,
                              _0: Belt_List.getExn(questions, 0),
                              _1: {
                                TAG: /* EqualsFromList */1,
                                _0: {
                                  hd: "9",
                                  tl: {
                                    hd: "10",
                                    tl: /* [] */0
                                  }
                                }
                              }
                            },
                            tl: /* [] */0
                          }
                        }, graph)));
    } else if (surveyName === "nykaa_beauty_post_delivery_survey" || surveyName === "nykaa_man_post_delivery_survey" || surveyName === "nykaa_fashion_post_delivery_survey" || surveyName === "nykaa_post_delivery_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), {
                  TAG: /* SkipLogic */1,
                  _0: Belt_List.getExn(questions, 3),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 4)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 0),
                      _1: {
                        TAG: /* EqualsFromList */1,
                        _0: {
                          hd: "7",
                          tl: {
                            hd: "8",
                            tl: /* [] */0
                          }
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), {
                      TAG: /* SkipLogic */1,
                      _0: Belt_List.getExn(questions, 3),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 4)
                      },
                      _2: {
                        hd: {
                          TAG: /* BranchLogic */0,
                          _0: Belt_List.getExn(questions, 0),
                          _1: {
                            TAG: /* Conditional */2,
                            _0: {
                              TAG: /* LessThan */1,
                              _0: {
                                TAG: /* Int */0,
                                _0: "7"
                              }
                            }
                          }
                        },
                        tl: /* [] */0
                      }
                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                          TAG: /* SkipLogic */1,
                          _0: Belt_List.getExn(questions, 2),
                          _1: {
                            TAG: /* Question */1,
                            _0: Belt_List.getExn(questions, 4)
                          },
                          _2: {
                            hd: {
                              TAG: /* BranchLogic */0,
                              _0: Belt_List.getExn(questions, 0),
                              _1: {
                                TAG: /* Conditional */2,
                                _0: {
                                  TAG: /* LessThan */1,
                                  _0: {
                                    TAG: /* Int */0,
                                    _0: "7"
                                  }
                                }
                              }
                            },
                            tl: /* [] */0
                          }
                        }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                              TAG: /* SkipLogic */1,
                              _0: Belt_List.getExn(questions, 2),
                              _1: {
                                TAG: /* Question */1,
                                _0: Belt_List.getExn(questions, 3)
                              },
                              _2: {
                                hd: {
                                  TAG: /* BranchLogic */0,
                                  _0: Belt_List.getExn(questions, 0),
                                  _1: {
                                    TAG: /* Conditional */2,
                                    _0: {
                                      TAG: /* LessThan */1,
                                      _0: {
                                        TAG: /* Int */0,
                                        _0: "7"
                                      }
                                    }
                                  }
                                },
                                tl: /* [] */0
                              }
                            }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                                  TAG: /* JumpTo */2,
                                  _0: Belt_List.getExn(questions, 1),
                                  _1: {
                                    TAG: /* Question */1,
                                    _0: Belt_List.getExn(questions, 4)
                                  },
                                  _2: {
                                    hd: {
                                      TAG: /* BranchLogic */0,
                                      _0: Belt_List.getExn(questions, 0),
                                      _1: {
                                        TAG: /* EqualsFromList */1,
                                        _0: {
                                          hd: "9",
                                          tl: {
                                            hd: "10",
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    },
                                    tl: /* [] */0
                                  }
                                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                                      TAG: /* JumpTo */2,
                                      _0: Belt_List.getExn(questions, 1),
                                      _1: {
                                        TAG: /* Question */1,
                                        _0: Belt_List.getExn(questions, 3)
                                      },
                                      _2: {
                                        hd: {
                                          TAG: /* BranchLogic */0,
                                          _0: Belt_List.getExn(questions, 0),
                                          _1: {
                                            TAG: /* EqualsFromList */1,
                                            _0: {
                                              hd: "7",
                                              tl: {
                                                hd: "8",
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        },
                                        tl: /* [] */0
                                      }
                                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                                          TAG: /* JumpTo */2,
                                          _0: Belt_List.getExn(questions, 1),
                                          _1: {
                                            TAG: /* Question */1,
                                            _0: Belt_List.getExn(questions, 2)
                                          },
                                          _2: {
                                            hd: {
                                              TAG: /* BranchLogic */0,
                                              _0: Belt_List.getExn(questions, 0),
                                              _1: {
                                                TAG: /* Conditional */2,
                                                _0: {
                                                  TAG: /* LessThan */1,
                                                  _0: {
                                                    TAG: /* Int */0,
                                                    _0: "7"
                                                  }
                                                }
                                              }
                                            },
                                            tl: /* [] */0
                                          }
                                        }, graph)))))));
    } else if (surveyName === "nykaa_beauty_post_delivery_survey_v01" || surveyName === "nykaa_fashion_post_delivery_survey_v01" || surveyName === "nykaa_man_post_delivery_survey_v01") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 4),
                  _1: {
                    TAG: /* ThankYou */0,
                    _0: Belt_List.getExn(thankYouList, 0)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 0),
                      _1: {
                        TAG: /* Conditional */2,
                        _0: {
                          TAG: /* GreaterThan */2,
                          _0: {
                            TAG: /* Int */0,
                            _0: "6"
                          }
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 3),
                      _1: {
                        TAG: /* ThankYou */0,
                        _0: Belt_List.getExn(thankYouList, 0)
                      },
                      _2: {
                        hd: {
                          TAG: /* BranchLogic */0,
                          _0: Belt_List.getExn(questions, 0),
                          _1: {
                            TAG: /* Conditional */2,
                            _0: {
                              TAG: /* GreaterThan */2,
                              _0: {
                                TAG: /* Int */0,
                                _0: "6"
                              }
                            }
                          }
                        },
                        tl: /* [] */0
                      }
                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                          TAG: /* JumpTo */2,
                          _0: Belt_List.getExn(questions, 2),
                          _1: {
                            TAG: /* ThankYou */0,
                            _0: Belt_List.getExn(thankYouList, 1)
                          },
                          _2: {
                            hd: {
                              TAG: /* BranchLogic */0,
                              _0: Belt_List.getExn(questions, 0),
                              _1: {
                                TAG: /* Conditional */2,
                                _0: {
                                  TAG: /* LessThan */1,
                                  _0: {
                                    TAG: /* Int */0,
                                    _0: "7"
                                  }
                                }
                              }
                            },
                            tl: /* [] */0
                          }
                        }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                              TAG: /* JumpTo */2,
                              _0: Belt_List.getExn(questions, 1),
                              _1: {
                                TAG: /* Question */1,
                                _0: Belt_List.getExn(questions, 4)
                              },
                              _2: {
                                hd: {
                                  TAG: /* BranchLogic */0,
                                  _0: Belt_List.getExn(questions, 0),
                                  _1: {
                                    TAG: /* EqualsFromList */1,
                                    _0: {
                                      hd: "9",
                                      tl: {
                                        hd: "10",
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                },
                                tl: /* [] */0
                              }
                            }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                                  TAG: /* JumpTo */2,
                                  _0: Belt_List.getExn(questions, 1),
                                  _1: {
                                    TAG: /* Question */1,
                                    _0: Belt_List.getExn(questions, 3)
                                  },
                                  _2: {
                                    hd: {
                                      TAG: /* BranchLogic */0,
                                      _0: Belt_List.getExn(questions, 0),
                                      _1: {
                                        TAG: /* EqualsFromList */1,
                                        _0: {
                                          hd: "7",
                                          tl: {
                                            hd: "8",
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    },
                                    tl: /* [] */0
                                  }
                                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                                      TAG: /* JumpTo */2,
                                      _0: Belt_List.getExn(questions, 1),
                                      _1: {
                                        TAG: /* Question */1,
                                        _0: Belt_List.getExn(questions, 2)
                                      },
                                      _2: {
                                        hd: {
                                          TAG: /* BranchLogic */0,
                                          _0: Belt_List.getExn(questions, 0),
                                          _1: {
                                            TAG: /* Conditional */2,
                                            _0: {
                                              TAG: /* LessThan */1,
                                              _0: {
                                                TAG: /* Int */0,
                                                _0: "7"
                                              }
                                            }
                                          }
                                        },
                                        tl: /* [] */0
                                      }
                                    }, graph))))));
    } else if (surveyName === "casa_onboarding_branch_accnt_opening_survey" || surveyName === "casa_onboarding_web_accnt_opening_survey" || surveyName === "casa_onboarding_aims_tablet_accnt_opening_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 2),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 4)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 0),
                      _1: {
                        TAG: /* EqualsFromList */1,
                        _0: {
                          hd: "7",
                          tl: {
                            hd: "8",
                            tl: /* [] */0
                          }
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 1),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 4)
                      },
                      _2: {
                        hd: {
                          TAG: /* BranchLogic */0,
                          _0: Belt_List.getExn(questions, 0),
                          _1: {
                            TAG: /* EqualsFromList */1,
                            _0: {
                              hd: "9",
                              tl: {
                                hd: "10",
                                tl: /* [] */0
                              }
                            }
                          }
                        },
                        tl: /* [] */0
                      }
                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                          TAG: /* JumpTo */2,
                          _0: Belt_List.getExn(questions, 0),
                          _1: {
                            TAG: /* Question */1,
                            _0: Belt_List.getExn(questions, 3)
                          },
                          _2: {
                            hd: {
                              TAG: /* BranchLogic */0,
                              _0: Belt_List.getExn(questions, 0),
                              _1: {
                                TAG: /* Conditional */2,
                                _0: {
                                  TAG: /* LessThan */1,
                                  _0: {
                                    TAG: /* Int */0,
                                    _0: "7"
                                  }
                                }
                              }
                            },
                            tl: /* [] */0
                          }
                        }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                              TAG: /* JumpTo */2,
                              _0: Belt_List.getExn(questions, 0),
                              _1: {
                                TAG: /* Question */1,
                                _0: Belt_List.getExn(questions, 2)
                              },
                              _2: {
                                hd: {
                                  TAG: /* BranchLogic */0,
                                  _0: Belt_List.getExn(questions, 0),
                                  _1: {
                                    TAG: /* EqualsFromList */1,
                                    _0: {
                                      hd: "7",
                                      tl: {
                                        hd: "8",
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                },
                                tl: /* [] */0
                              }
                            }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                                  TAG: /* JumpTo */2,
                                  _0: Belt_List.getExn(questions, 0),
                                  _1: {
                                    TAG: /* Question */1,
                                    _0: Belt_List.getExn(questions, 1)
                                  },
                                  _2: {
                                    hd: {
                                      TAG: /* BranchLogic */0,
                                      _0: Belt_List.getExn(questions, 0),
                                      _1: {
                                        TAG: /* EqualsFromList */1,
                                        _0: {
                                          hd: "9",
                                          tl: {
                                            hd: "10",
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    },
                                    tl: /* [] */0
                                  }
                                }, graph)))));
    } else if (surveyName === "merchant_pos_existing_merchants") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 4),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 6)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 4),
                      _1: {
                        TAG: /* EqualsFromList */1,
                        _0: {
                          hd: "9sYAkUQOJcSgVKnn",
                          tl: /* [] */0
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 3),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 6)
                      },
                      _2: {
                        hd: {
                          TAG: /* BranchLogic */0,
                          _0: Belt_List.getExn(questions, 3),
                          _1: {
                            TAG: /* EqualsFromList */1,
                            _0: {
                              hd: "g9n1rJtTqYGQgyod",
                              tl: /* [] */0
                            }
                          }
                        },
                        tl: /* [] */0
                      }
                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                          TAG: /* JumpTo */2,
                          _0: Belt_List.getExn(questions, 1),
                          _1: {
                            TAG: /* Question */1,
                            _0: Belt_List.getExn(questions, 3)
                          },
                          _2: {
                            hd: {
                              TAG: /* BranchLogic */0,
                              _0: Belt_List.getExn(questions, 0),
                              _1: {
                                TAG: /* EqualsFromList */1,
                                _0: {
                                  hd: "9",
                                  tl: {
                                    hd: "10",
                                    tl: /* [] */0
                                  }
                                }
                              }
                            },
                            tl: /* [] */0
                          }
                        }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                              TAG: /* JumpTo */2,
                              _0: Belt_List.getExn(questions, 0),
                              _1: {
                                TAG: /* Question */1,
                                _0: Belt_List.getExn(questions, 2)
                              },
                              _2: {
                                hd: {
                                  TAG: /* BranchLogic */0,
                                  _0: Belt_List.getExn(questions, 0),
                                  _1: {
                                    TAG: /* Conditional */2,
                                    _0: {
                                      TAG: /* LessThan */1,
                                      _0: {
                                        TAG: /* Int */0,
                                        _0: "9"
                                      }
                                    }
                                  }
                                },
                                tl: /* [] */0
                              }
                            }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                                  TAG: /* JumpTo */2,
                                  _0: Belt_List.getExn(questions, 0),
                                  _1: {
                                    TAG: /* Question */1,
                                    _0: Belt_List.getExn(questions, 1)
                                  },
                                  _2: {
                                    hd: {
                                      TAG: /* BranchLogic */0,
                                      _0: Belt_List.getExn(questions, 0),
                                      _1: {
                                        TAG: /* EqualsFromList */1,
                                        _0: {
                                          hd: "9",
                                          tl: {
                                            hd: "10",
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    },
                                    tl: /* [] */0
                                  }
                                }, graph)))));
    } else if (surveyName === "merchant_pos_new_onboarded") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 7), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 7),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 9)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 7),
                      _1: {
                        TAG: /* EqualsFromList */1,
                        _0: {
                          hd: "Xr4CUe16Cirfcnj7",
                          tl: /* [] */0
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 5), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 5),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 7)
                      },
                      _2: {
                        hd: {
                          TAG: /* BranchLogic */0,
                          _0: Belt_List.getExn(questions, 5),
                          _1: {
                            TAG: /* EqualsFromList */1,
                            _0: {
                              hd: "4",
                              tl: {
                                hd: "5",
                                tl: /* [] */0
                              }
                            }
                          }
                        },
                        tl: /* [] */0
                      }
                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), {
                          TAG: /* JumpTo */2,
                          _0: Belt_List.getExn(questions, 3),
                          _1: {
                            TAG: /* Question */1,
                            _0: Belt_List.getExn(questions, 5)
                          },
                          _2: {
                            hd: {
                              TAG: /* BranchLogic */0,
                              _0: Belt_List.getExn(questions, 3),
                              _1: {
                                TAG: /* EqualsFromList */1,
                                _0: {
                                  hd: "4",
                                  tl: {
                                    hd: "5",
                                    tl: /* [] */0
                                  }
                                }
                              }
                            },
                            tl: /* [] */0
                          }
                        }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                              TAG: /* JumpTo */2,
                              _0: Belt_List.getExn(questions, 1),
                              _1: {
                                TAG: /* Question */1,
                                _0: Belt_List.getExn(questions, 3)
                              },
                              _2: {
                                hd: {
                                  TAG: /* BranchLogic */0,
                                  _0: Belt_List.getExn(questions, 0),
                                  _1: {
                                    TAG: /* EqualsFromList */1,
                                    _0: {
                                      hd: "9",
                                      tl: {
                                        hd: "10",
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                },
                                tl: /* [] */0
                              }
                            }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                                  TAG: /* JumpTo */2,
                                  _0: Belt_List.getExn(questions, 1),
                                  _1: {
                                    TAG: /* Question */1,
                                    _0: Belt_List.getExn(questions, 3)
                                  },
                                  _2: {
                                    hd: {
                                      TAG: /* BranchLogic */0,
                                      _0: Belt_List.getExn(questions, 0),
                                      _1: {
                                        TAG: /* EqualsFromList */1,
                                        _0: {
                                          hd: "9",
                                          tl: {
                                            hd: "10",
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    },
                                    tl: /* [] */0
                                  }
                                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                                      TAG: /* JumpTo */2,
                                      _0: Belt_List.getExn(questions, 0),
                                      _1: {
                                        TAG: /* Question */1,
                                        _0: Belt_List.getExn(questions, 2)
                                      },
                                      _2: {
                                        hd: {
                                          TAG: /* BranchLogic */0,
                                          _0: Belt_List.getExn(questions, 0),
                                          _1: {
                                            TAG: /* Conditional */2,
                                            _0: {
                                              TAG: /* LessThan */1,
                                              _0: {
                                                TAG: /* Int */0,
                                                _0: "9"
                                              }
                                            }
                                          }
                                        },
                                        tl: /* [] */0
                                      }
                                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                                          TAG: /* JumpTo */2,
                                          _0: Belt_List.getExn(questions, 0),
                                          _1: {
                                            TAG: /* Question */1,
                                            _0: Belt_List.getExn(questions, 1)
                                          },
                                          _2: {
                                            hd: {
                                              TAG: /* BranchLogic */0,
                                              _0: Belt_List.getExn(questions, 0),
                                              _1: {
                                                TAG: /* EqualsFromList */1,
                                                _0: {
                                                  hd: "9",
                                                  tl: {
                                                    hd: "10",
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            },
                                            tl: /* [] */0
                                          }
                                        }, graph)))))));
    } else if (surveyName === "mib_existing" || surveyName === "seb_existing") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), {
                  TAG: /* SkipLogic */1,
                  _0: Belt_List.getExn(questions, 4),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 5)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 4),
                      _1: {
                        TAG: /* EqualsFromValue */0,
                        _0: "pmJ0che5X2lVUq5Y"
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 4),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 5)
                      },
                      _2: {
                        hd: {
                          TAG: /* BranchLogic */0,
                          _0: Belt_List.getExn(questions, 4),
                          _1: {
                            TAG: /* EqualsFromValue */0,
                            _0: "ZRjQtYTfBnSiJnvU"
                          }
                        },
                        tl: /* [] */0
                      }
                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                          TAG: /* JumpTo */2,
                          _0: Belt_List.getExn(questions, 1),
                          _1: {
                            TAG: /* Question */1,
                            _0: Belt_List.getExn(questions, 3)
                          },
                          _2: {
                            hd: {
                              TAG: /* BranchLogic */0,
                              _0: Belt_List.getExn(questions, 0),
                              _1: {
                                TAG: /* EqualsFromList */1,
                                _0: {
                                  hd: "9",
                                  tl: {
                                    hd: "10",
                                    tl: /* [] */0
                                  }
                                }
                              }
                            },
                            tl: /* [] */0
                          }
                        }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                              TAG: /* JumpTo */2,
                              _0: Belt_List.getExn(questions, 0),
                              _1: {
                                TAG: /* Question */1,
                                _0: Belt_List.getExn(questions, 3)
                              },
                              _2: {
                                hd: {
                                  TAG: /* BranchLogic */0,
                                  _0: Belt_List.getExn(questions, 0),
                                  _1: {
                                    TAG: /* EqualsFromList */1,
                                    _0: {
                                      hd: "7",
                                      tl: {
                                        hd: "8",
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                },
                                tl: /* [] */0
                              }
                            }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                                  TAG: /* JumpTo */2,
                                  _0: Belt_List.getExn(questions, 0),
                                  _1: {
                                    TAG: /* Question */1,
                                    _0: Belt_List.getExn(questions, 2)
                                  },
                                  _2: {
                                    hd: {
                                      TAG: /* BranchLogic */0,
                                      _0: Belt_List.getExn(questions, 0),
                                      _1: {
                                        TAG: /* Conditional */2,
                                        _0: {
                                          TAG: /* LessThan */1,
                                          _0: {
                                            TAG: /* Int */0,
                                            _0: "7"
                                          }
                                        }
                                      }
                                    },
                                    tl: /* [] */0
                                  }
                                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                                      TAG: /* JumpTo */2,
                                      _0: Belt_List.getExn(questions, 0),
                                      _1: {
                                        TAG: /* Question */1,
                                        _0: Belt_List.getExn(questions, 1)
                                      },
                                      _2: {
                                        hd: {
                                          TAG: /* BranchLogic */0,
                                          _0: Belt_List.getExn(questions, 0),
                                          _1: {
                                            TAG: /* EqualsFromList */1,
                                            _0: {
                                              hd: "9",
                                              tl: {
                                                hd: "10",
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        },
                                        tl: /* [] */0
                                      }
                                    }, graph))))));
    } else if (surveyName === "mib_onboarding" || surveyName === "seb_onboarding") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 7), {
                  TAG: /* SkipLogic */1,
                  _0: Belt_List.getExn(questions, 7),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 8)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 6),
                      _1: {
                        TAG: /* EqualsFromList */1,
                        _0: {
                          hd: "9",
                          tl: {
                            hd: "10",
                            tl: /* [] */0
                          }
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 6), {
                      TAG: /* SkipLogic */1,
                      _0: Belt_List.getExn(questions, 6),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 8)
                      },
                      _2: {
                        hd: {
                          TAG: /* BranchLogic */0,
                          _0: Belt_List.getExn(questions, 6),
                          _1: {
                            TAG: /* EqualsFromList */1,
                            _0: {
                              hd: "9",
                              tl: {
                                hd: "10",
                                tl: /* [] */0
                              }
                            }
                          }
                        },
                        tl: /* [] */0
                      }
                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 6), {
                          TAG: /* SkipLogic */1,
                          _0: Belt_List.getExn(questions, 6),
                          _1: {
                            TAG: /* Question */1,
                            _0: Belt_List.getExn(questions, 7)
                          },
                          _2: {
                            hd: {
                              TAG: /* BranchLogic */0,
                              _0: Belt_List.getExn(questions, 6),
                              _1: {
                                TAG: /* EqualsFromList */1,
                                _0: {
                                  hd: "9",
                                  tl: {
                                    hd: "10",
                                    tl: /* [] */0
                                  }
                                }
                              }
                            },
                            tl: /* [] */0
                          }
                        }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 6), {
                              TAG: /* JumpTo */2,
                              _0: Belt_List.getExn(questions, 6),
                              _1: {
                                TAG: /* Question */1,
                                _0: Belt_List.getExn(questions, 8)
                              },
                              _2: {
                                hd: {
                                  TAG: /* BranchLogic */0,
                                  _0: Belt_List.getExn(questions, 6),
                                  _1: {
                                    TAG: /* Conditional */2,
                                    _0: {
                                      TAG: /* LessThan */1,
                                      _0: {
                                        TAG: /* Int */0,
                                        _0: "7"
                                      }
                                    }
                                  }
                                },
                                tl: /* [] */0
                              }
                            }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 6), {
                                  TAG: /* JumpTo */2,
                                  _0: Belt_List.getExn(questions, 6),
                                  _1: {
                                    TAG: /* Question */1,
                                    _0: Belt_List.getExn(questions, 7)
                                  },
                                  _2: {
                                    hd: {
                                      TAG: /* BranchLogic */0,
                                      _0: Belt_List.getExn(questions, 6),
                                      _1: {
                                        TAG: /* EqualsFromList */1,
                                        _0: {
                                          hd: "7",
                                          tl: {
                                            hd: "8",
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    },
                                    tl: /* [] */0
                                  }
                                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), {
                                      TAG: /* JumpTo */2,
                                      _0: Belt_List.getExn(questions, 4),
                                      _1: {
                                        TAG: /* Question */1,
                                        _0: Belt_List.getExn(questions, 6)
                                      },
                                      _2: {
                                        hd: {
                                          TAG: /* BranchLogic */0,
                                          _0: Belt_List.getExn(questions, 3),
                                          _1: {
                                            TAG: /* EqualsFromList */1,
                                            _0: {
                                              hd: "7",
                                              tl: {
                                                hd: "8",
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        },
                                        tl: /* [] */0
                                      }
                                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), {
                                          TAG: /* JumpTo */2,
                                          _0: Belt_List.getExn(questions, 3),
                                          _1: {
                                            TAG: /* Question */1,
                                            _0: Belt_List.getExn(questions, 6)
                                          },
                                          _2: {
                                            hd: {
                                              TAG: /* BranchLogic */0,
                                              _0: Belt_List.getExn(questions, 3),
                                              _1: {
                                                TAG: /* EqualsFromList */1,
                                                _0: {
                                                  hd: "9",
                                                  tl: {
                                                    hd: "10",
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            },
                                            tl: /* [] */0
                                          }
                                        }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), {
                                              TAG: /* JumpTo */2,
                                              _0: Belt_List.getExn(questions, 3),
                                              _1: {
                                                TAG: /* Question */1,
                                                _0: Belt_List.getExn(questions, 5)
                                              },
                                              _2: {
                                                hd: {
                                                  TAG: /* BranchLogic */0,
                                                  _0: Belt_List.getExn(questions, 3),
                                                  _1: {
                                                    TAG: /* Conditional */2,
                                                    _0: {
                                                      TAG: /* LessThan */1,
                                                      _0: {
                                                        TAG: /* Int */0,
                                                        _0: "7"
                                                      }
                                                    }
                                                  }
                                                },
                                                tl: /* [] */0
                                              }
                                            }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), {
                                                  TAG: /* JumpTo */2,
                                                  _0: Belt_List.getExn(questions, 3),
                                                  _1: {
                                                    TAG: /* Question */1,
                                                    _0: Belt_List.getExn(questions, 4)
                                                  },
                                                  _2: {
                                                    hd: {
                                                      TAG: /* BranchLogic */0,
                                                      _0: Belt_List.getExn(questions, 3),
                                                      _1: {
                                                        TAG: /* EqualsFromList */1,
                                                        _0: {
                                                          hd: "7",
                                                          tl: {
                                                            hd: "8",
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    },
                                                    tl: /* [] */0
                                                  }
                                                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                                                      TAG: /* JumpTo */2,
                                                      _0: Belt_List.getExn(questions, 1),
                                                      _1: {
                                                        TAG: /* Question */1,
                                                        _0: Belt_List.getExn(questions, 3)
                                                      },
                                                      _2: {
                                                        hd: {
                                                          TAG: /* BranchLogic */0,
                                                          _0: Belt_List.getExn(questions, 0),
                                                          _1: {
                                                            TAG: /* EqualsFromList */1,
                                                            _0: {
                                                              hd: "9",
                                                              tl: {
                                                                hd: "10",
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        },
                                                        tl: /* [] */0
                                                      }
                                                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                                                          TAG: /* JumpTo */2,
                                                          _0: Belt_List.getExn(questions, 0),
                                                          _1: {
                                                            TAG: /* Question */1,
                                                            _0: Belt_List.getExn(questions, 3)
                                                          },
                                                          _2: {
                                                            hd: {
                                                              TAG: /* BranchLogic */0,
                                                              _0: Belt_List.getExn(questions, 0),
                                                              _1: {
                                                                TAG: /* EqualsFromList */1,
                                                                _0: {
                                                                  hd: "7",
                                                                  tl: {
                                                                    hd: "8",
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            },
                                                            tl: /* [] */0
                                                          }
                                                        }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                                                              TAG: /* JumpTo */2,
                                                              _0: Belt_List.getExn(questions, 0),
                                                              _1: {
                                                                TAG: /* Question */1,
                                                                _0: Belt_List.getExn(questions, 2)
                                                              },
                                                              _2: {
                                                                hd: {
                                                                  TAG: /* BranchLogic */0,
                                                                  _0: Belt_List.getExn(questions, 0),
                                                                  _1: {
                                                                    TAG: /* Conditional */2,
                                                                    _0: {
                                                                      TAG: /* LessThan */1,
                                                                      _0: {
                                                                        TAG: /* Int */0,
                                                                        _0: "7"
                                                                      }
                                                                    }
                                                                  }
                                                                },
                                                                tl: /* [] */0
                                                              }
                                                            }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                                                                  TAG: /* JumpTo */2,
                                                                  _0: Belt_List.getExn(questions, 0),
                                                                  _1: {
                                                                    TAG: /* Question */1,
                                                                    _0: Belt_List.getExn(questions, 1)
                                                                  },
                                                                  _2: {
                                                                    hd: {
                                                                      TAG: /* BranchLogic */0,
                                                                      _0: Belt_List.getExn(questions, 0),
                                                                      _1: {
                                                                        TAG: /* EqualsFromList */1,
                                                                        _0: {
                                                                          hd: "9",
                                                                          tl: {
                                                                            hd: "10",
                                                                            tl: /* [] */0
                                                                          }
                                                                        }
                                                                      }
                                                                    },
                                                                    tl: /* [] */0
                                                                  }
                                                                }, graph)))))))))))));
    } else if (surveyName === "merchant_pos_new_onboarded_v01" || surveyName === "ra_collections_predelinquent_customer_calls_v01") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 2),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 4)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 0),
                      _1: {
                        TAG: /* Conditional */2,
                        _0: {
                          TAG: /* LessThan */1,
                          _0: {
                            TAG: /* Int */0,
                            _0: "7"
                          }
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 1),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 4)
                      },
                      _2: {
                        hd: {
                          TAG: /* BranchLogic */0,
                          _0: Belt_List.getExn(questions, 0),
                          _1: {
                            TAG: /* EqualsFromList */1,
                            _0: {
                              hd: "9",
                              tl: {
                                hd: "10",
                                tl: /* [] */0
                              }
                            }
                          }
                        },
                        tl: /* [] */0
                      }
                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                          TAG: /* JumpTo */2,
                          _0: Belt_List.getExn(questions, 0),
                          _1: {
                            TAG: /* Question */1,
                            _0: Belt_List.getExn(questions, 3)
                          },
                          _2: {
                            hd: {
                              TAG: /* BranchLogic */0,
                              _0: Belt_List.getExn(questions, 0),
                              _1: {
                                TAG: /* EqualsFromList */1,
                                _0: {
                                  hd: "7",
                                  tl: {
                                    hd: "8",
                                    tl: /* [] */0
                                  }
                                }
                              }
                            },
                            tl: /* [] */0
                          }
                        }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                              TAG: /* JumpTo */2,
                              _0: Belt_List.getExn(questions, 0),
                              _1: {
                                TAG: /* Question */1,
                                _0: Belt_List.getExn(questions, 2)
                              },
                              _2: {
                                hd: {
                                  TAG: /* BranchLogic */0,
                                  _0: Belt_List.getExn(questions, 0),
                                  _1: {
                                    TAG: /* Conditional */2,
                                    _0: {
                                      TAG: /* LessThan */1,
                                      _0: {
                                        TAG: /* Int */0,
                                        _0: "7"
                                      }
                                    }
                                  }
                                },
                                tl: /* [] */0
                              }
                            }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                                  TAG: /* JumpTo */2,
                                  _0: Belt_List.getExn(questions, 0),
                                  _1: {
                                    TAG: /* Question */1,
                                    _0: Belt_List.getExn(questions, 1)
                                  },
                                  _2: {
                                    hd: {
                                      TAG: /* BranchLogic */0,
                                      _0: Belt_List.getExn(questions, 0),
                                      _1: {
                                        TAG: /* EqualsFromList */1,
                                        _0: {
                                          hd: "9",
                                          tl: {
                                            hd: "10",
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    },
                                    tl: /* [] */0
                                  }
                                }, graph)))));
    } else if (surveyName === "merchant_pos_existing_merchants_v01") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 5), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 5),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 7)
                  },
                  _2: {
                    hd: {
                      TAG: /* MultipleAnswerLogic */1,
                      _0: Belt_List.getExn(questions, 4),
                      _1: {
                        TAG: /* Contains */0,
                        _0: "014b75c8-7816-4bfd-a2c2-7dd29d0e9723"
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 4),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 6)
                      },
                      _2: {
                        hd: {
                          TAG: /* MultipleAnswerLogic */1,
                          _0: Belt_List.getExn(questions, 4),
                          _1: {
                            TAG: /* Contains */0,
                            _0: "2143afa3-89ab-4c37-b67a-24db59a5bea8"
                          }
                        },
                        tl: /* [] */0
                      }
                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                          TAG: /* JumpTo */2,
                          _0: Belt_List.getExn(questions, 2),
                          _1: {
                            TAG: /* Question */1,
                            _0: Belt_List.getExn(questions, 4)
                          },
                          _2: {
                            hd: {
                              TAG: /* BranchLogic */0,
                              _0: Belt_List.getExn(questions, 0),
                              _1: {
                                TAG: /* Conditional */2,
                                _0: {
                                  TAG: /* LessThan */1,
                                  _0: {
                                    TAG: /* Int */0,
                                    _0: "7"
                                  }
                                }
                              }
                            },
                            tl: /* [] */0
                          }
                        }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                              TAG: /* JumpTo */2,
                              _0: Belt_List.getExn(questions, 1),
                              _1: {
                                TAG: /* Question */1,
                                _0: Belt_List.getExn(questions, 4)
                              },
                              _2: {
                                hd: {
                                  TAG: /* BranchLogic */0,
                                  _0: Belt_List.getExn(questions, 0),
                                  _1: {
                                    TAG: /* EqualsFromList */1,
                                    _0: {
                                      hd: "9",
                                      tl: {
                                        hd: "10",
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                },
                                tl: /* [] */0
                              }
                            }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                                  TAG: /* JumpTo */2,
                                  _0: Belt_List.getExn(questions, 0),
                                  _1: {
                                    TAG: /* Question */1,
                                    _0: Belt_List.getExn(questions, 3)
                                  },
                                  _2: {
                                    hd: {
                                      TAG: /* BranchLogic */0,
                                      _0: Belt_List.getExn(questions, 0),
                                      _1: {
                                        TAG: /* EqualsFromList */1,
                                        _0: {
                                          hd: "7",
                                          tl: {
                                            hd: "8",
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    },
                                    tl: /* [] */0
                                  }
                                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                                      TAG: /* JumpTo */2,
                                      _0: Belt_List.getExn(questions, 0),
                                      _1: {
                                        TAG: /* Question */1,
                                        _0: Belt_List.getExn(questions, 2)
                                      },
                                      _2: {
                                        hd: {
                                          TAG: /* BranchLogic */0,
                                          _0: Belt_List.getExn(questions, 0),
                                          _1: {
                                            TAG: /* Conditional */2,
                                            _0: {
                                              TAG: /* LessThan */1,
                                              _0: {
                                                TAG: /* Int */0,
                                                _0: "7"
                                              }
                                            }
                                          }
                                        },
                                        tl: /* [] */0
                                      }
                                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                                          TAG: /* JumpTo */2,
                                          _0: Belt_List.getExn(questions, 0),
                                          _1: {
                                            TAG: /* Question */1,
                                            _0: Belt_List.getExn(questions, 1)
                                          },
                                          _2: {
                                            hd: {
                                              TAG: /* BranchLogic */0,
                                              _0: Belt_List.getExn(questions, 0),
                                              _1: {
                                                TAG: /* EqualsFromList */1,
                                                _0: {
                                                  hd: "9",
                                                  tl: {
                                                    hd: "10",
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            },
                                            tl: /* [] */0
                                          }
                                        }, graph)))))));
    } else if (surveyName === "nw_portin_customer_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 9), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 9),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 10)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 4),
                      _1: {
                        TAG: /* EqualsFromList */1,
                        _0: {
                          hd: "B6k3P3xz1CJrbh23",
                          tl: /* [] */0
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 3),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 9)
                      },
                      _2: {
                        hd: {
                          TAG: /* BranchLogic */0,
                          _0: Belt_List.getExn(questions, 3),
                          _1: {
                            TAG: /* EqualsFromList */1,
                            _0: {
                              hd: "B6k3P3xz1CJrbh23",
                              tl: /* [] */0
                            }
                          }
                        },
                        tl: /* [] */0
                      }
                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 8), {
                          TAG: /* JumpTo */2,
                          _0: Belt_List.getExn(questions, 8),
                          _1: {
                            TAG: /* Question */1,
                            _0: Belt_List.getExn(questions, 10)
                          },
                          _2: {
                            hd: {
                              TAG: /* BranchLogic */0,
                              _0: Belt_List.getExn(questions, 3),
                              _1: {
                                TAG: /* EqualsFromList */1,
                                _0: {
                                  hd: "sQwuyiN4knQgsQSI",
                                  tl: /* [] */0
                                }
                              }
                            },
                            tl: /* [] */0
                          }
                        }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), {
                              TAG: /* JumpTo */2,
                              _0: Belt_List.getExn(questions, 3),
                              _1: {
                                TAG: /* Question */1,
                                _0: Belt_List.getExn(questions, 8)
                              },
                              _2: {
                                hd: {
                                  TAG: /* BranchLogic */0,
                                  _0: Belt_List.getExn(questions, 3),
                                  _1: {
                                    TAG: /* EqualsFromList */1,
                                    _0: {
                                      hd: "sQwuyiN4knQgsQSI",
                                      tl: /* [] */0
                                    }
                                  }
                                },
                                tl: /* [] */0
                              }
                            }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 7), {
                                  TAG: /* JumpTo */2,
                                  _0: Belt_List.getExn(questions, 7),
                                  _1: {
                                    TAG: /* Question */1,
                                    _0: Belt_List.getExn(questions, 10)
                                  },
                                  _2: {
                                    hd: {
                                      TAG: /* BranchLogic */0,
                                      _0: Belt_List.getExn(questions, 3),
                                      _1: {
                                        TAG: /* EqualsFromList */1,
                                        _0: {
                                          hd: "j15PG6UhU1czQ3D8",
                                          tl: /* [] */0
                                        }
                                      }
                                    },
                                    tl: /* [] */0
                                  }
                                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), {
                                      TAG: /* JumpTo */2,
                                      _0: Belt_List.getExn(questions, 3),
                                      _1: {
                                        TAG: /* Question */1,
                                        _0: Belt_List.getExn(questions, 7)
                                      },
                                      _2: {
                                        hd: {
                                          TAG: /* BranchLogic */0,
                                          _0: Belt_List.getExn(questions, 3),
                                          _1: {
                                            TAG: /* EqualsFromList */1,
                                            _0: {
                                              hd: "j15PG6UhU1czQ3D8",
                                              tl: /* [] */0
                                            }
                                          }
                                        },
                                        tl: /* [] */0
                                      }
                                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 6), {
                                          TAG: /* JumpTo */2,
                                          _0: Belt_List.getExn(questions, 6),
                                          _1: {
                                            TAG: /* Question */1,
                                            _0: Belt_List.getExn(questions, 10)
                                          },
                                          _2: {
                                            hd: {
                                              TAG: /* BranchLogic */0,
                                              _0: Belt_List.getExn(questions, 2),
                                              _1: {
                                                TAG: /* EqualsFromList */1,
                                                _0: {
                                                  hd: "c7NLXE7up5lltr21",
                                                  tl: /* [] */0
                                                }
                                              }
                                            },
                                            tl: /* [] */0
                                          }
                                        }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                                              TAG: /* JumpTo */2,
                                              _0: Belt_List.getExn(questions, 2),
                                              _1: {
                                                TAG: /* Question */1,
                                                _0: Belt_List.getExn(questions, 6)
                                              },
                                              _2: {
                                                hd: {
                                                  TAG: /* BranchLogic */0,
                                                  _0: Belt_List.getExn(questions, 2),
                                                  _1: {
                                                    TAG: /* EqualsFromList */1,
                                                    _0: {
                                                      hd: "c7NLXE7up5lltr21",
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                },
                                                tl: /* [] */0
                                              }
                                            }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 5), {
                                                  TAG: /* JumpTo */2,
                                                  _0: Belt_List.getExn(questions, 5),
                                                  _1: {
                                                    TAG: /* Question */1,
                                                    _0: Belt_List.getExn(questions, 10)
                                                  },
                                                  _2: {
                                                    hd: {
                                                      TAG: /* BranchLogic */0,
                                                      _0: Belt_List.getExn(questions, 2),
                                                      _1: {
                                                        TAG: /* EqualsFromList */1,
                                                        _0: {
                                                          hd: "TDSViyNoeIrp6glZ",
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    },
                                                    tl: /* [] */0
                                                  }
                                                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                                                      TAG: /* JumpTo */2,
                                                      _0: Belt_List.getExn(questions, 2),
                                                      _1: {
                                                        TAG: /* Question */1,
                                                        _0: Belt_List.getExn(questions, 5)
                                                      },
                                                      _2: {
                                                        hd: {
                                                          TAG: /* BranchLogic */0,
                                                          _0: Belt_List.getExn(questions, 2),
                                                          _1: {
                                                            TAG: /* EqualsFromList */1,
                                                            _0: {
                                                              hd: "TDSViyNoeIrp6glZ",
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        },
                                                        tl: /* [] */0
                                                      }
                                                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), {
                                                          TAG: /* JumpTo */2,
                                                          _0: Belt_List.getExn(questions, 4),
                                                          _1: {
                                                            TAG: /* Question */1,
                                                            _0: Belt_List.getExn(questions, 10)
                                                          },
                                                          _2: {
                                                            hd: {
                                                              TAG: /* BranchLogic */0,
                                                              _0: Belt_List.getExn(questions, 2),
                                                              _1: {
                                                                TAG: /* EqualsFromList */1,
                                                                _0: {
                                                                  hd: "vBC3og8tuBWF4BNV",
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            },
                                                            tl: /* [] */0
                                                          }
                                                        }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                                                              TAG: /* JumpTo */2,
                                                              _0: Belt_List.getExn(questions, 2),
                                                              _1: {
                                                                TAG: /* Question */1,
                                                                _0: Belt_List.getExn(questions, 4)
                                                              },
                                                              _2: {
                                                                hd: {
                                                                  TAG: /* BranchLogic */0,
                                                                  _0: Belt_List.getExn(questions, 2),
                                                                  _1: {
                                                                    TAG: /* EqualsFromList */1,
                                                                    _0: {
                                                                      hd: "vBC3og8tuBWF4BNV",
                                                                      tl: /* [] */0
                                                                    }
                                                                  }
                                                                },
                                                                tl: /* [] */0
                                                              }
                                                            }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                                                                  TAG: /* JumpTo */2,
                                                                  _0: Belt_List.getExn(questions, 1),
                                                                  _1: {
                                                                    TAG: /* Question */1,
                                                                    _0: Belt_List.getExn(questions, 10)
                                                                  },
                                                                  _2: {
                                                                    hd: {
                                                                      TAG: /* BranchLogic */0,
                                                                      _0: Belt_List.getExn(questions, 0),
                                                                      _1: {
                                                                        TAG: /* EqualsFromList */1,
                                                                        _0: {
                                                                          hd: "9",
                                                                          tl: {
                                                                            hd: "10",
                                                                            tl: /* [] */0
                                                                          }
                                                                        }
                                                                      }
                                                                    },
                                                                    tl: /* [] */0
                                                                  }
                                                                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                                                                      TAG: /* JumpTo */2,
                                                                      _0: Belt_List.getExn(questions, 0),
                                                                      _1: {
                                                                        TAG: /* Question */1,
                                                                        _0: Belt_List.getExn(questions, 3)
                                                                      },
                                                                      _2: {
                                                                        hd: {
                                                                          TAG: /* BranchLogic */0,
                                                                          _0: Belt_List.getExn(questions, 0),
                                                                          _1: {
                                                                            TAG: /* Conditional */2,
                                                                            _0: {
                                                                              TAG: /* LessThan */1,
                                                                              _0: {
                                                                                TAG: /* Int */0,
                                                                                _0: "7"
                                                                              }
                                                                            }
                                                                          }
                                                                        },
                                                                        tl: /* [] */0
                                                                      }
                                                                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                                                                          TAG: /* JumpTo */2,
                                                                          _0: Belt_List.getExn(questions, 0),
                                                                          _1: {
                                                                            TAG: /* Question */1,
                                                                            _0: Belt_List.getExn(questions, 2)
                                                                          },
                                                                          _2: {
                                                                            hd: {
                                                                              TAG: /* BranchLogic */0,
                                                                              _0: Belt_List.getExn(questions, 0),
                                                                              _1: {
                                                                                TAG: /* EqualsFromList */1,
                                                                                _0: {
                                                                                  hd: "7",
                                                                                  tl: {
                                                                                    hd: "8",
                                                                                    tl: /* [] */0
                                                                                  }
                                                                                }
                                                                              }
                                                                            },
                                                                            tl: /* [] */0
                                                                          }
                                                                        }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                                                                              TAG: /* JumpTo */2,
                                                                              _0: Belt_List.getExn(questions, 0),
                                                                              _1: {
                                                                                TAG: /* Question */1,
                                                                                _0: Belt_List.getExn(questions, 1)
                                                                              },
                                                                              _2: {
                                                                                hd: {
                                                                                  TAG: /* BranchLogic */0,
                                                                                  _0: Belt_List.getExn(questions, 0),
                                                                                  _1: {
                                                                                    TAG: /* EqualsFromList */1,
                                                                                    _0: {
                                                                                      hd: "9",
                                                                                      tl: {
                                                                                        hd: "10",
                                                                                        tl: /* [] */0
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                },
                                                                                tl: /* [] */0
                                                                              }
                                                                            }, graph))))))))))))))));
    } else if (surveyName === "existing_demat_account_holders_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 6), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 6),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 8)
                  },
                  _2: {
                    hd: {
                      TAG: /* MultipleAnswerLogic */1,
                      _0: Belt_List.getExn(questions, 6),
                      _1: {
                        TAG: /* Contains */0,
                        _0: "Dz6jBO6WreUbbmmt"
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 4),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 6)
                      },
                      _2: {
                        hd: {
                          TAG: /* MultipleAnswerLogic */1,
                          _0: Belt_List.getExn(questions, 3),
                          _1: {
                            TAG: /* Contains */0,
                            _0: "VMUVu0zKBTA7Il2j"
                          }
                        },
                        tl: /* [] */0
                      }
                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), {
                          TAG: /* JumpTo */2,
                          _0: Belt_List.getExn(questions, 3),
                          _1: {
                            TAG: /* Question */1,
                            _0: Belt_List.getExn(questions, 5)
                          },
                          _2: {
                            hd: {
                              TAG: /* MultipleAnswerLogic */1,
                              _0: Belt_List.getExn(questions, 3),
                              _1: {
                                TAG: /* Contains */0,
                                _0: "2Vw0BAGV1jKMcVqD"
                              }
                            },
                            tl: /* [] */0
                          }
                        }, graph)));
    } else if (surveyName === "for_new_demat_accounts_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 3),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 5)
                  },
                  _2: {
                    hd: {
                      TAG: /* MultipleAnswerLogic */1,
                      _0: Belt_List.getExn(questions, 1),
                      _1: {
                        TAG: /* Contains */0,
                        _0: "aRmcL6rmhbfHnZRM"
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 2),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 5)
                      },
                      _2: {
                        hd: {
                          TAG: /* MultipleAnswerLogic */1,
                          _0: Belt_List.getExn(questions, 2),
                          _1: {
                            TAG: /* Contains */0,
                            _0: "M8MHpFhQwamUcLgz"
                          }
                        },
                        tl: /* [] */0
                      }
                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                          TAG: /* JumpTo */2,
                          _0: Belt_List.getExn(questions, 1),
                          _1: {
                            TAG: /* Question */1,
                            _0: Belt_List.getExn(questions, 4)
                          },
                          _2: {
                            hd: {
                              TAG: /* MultipleAnswerLogic */1,
                              _0: Belt_List.getExn(questions, 1),
                              _1: {
                                TAG: /* Contains */0,
                                _0: "A5wY6QWTGgaUZE69"
                              }
                            },
                            tl: /* [] */0
                          }
                        }, graph)));
    } else if (surveyName === "Credit_Card_Contact_Center_Survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                  TAG: /* SkipLogic */1,
                  _0: Belt_List.getExn(questions, 2),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 3)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 2),
                      _1: {
                        TAG: /* EqualsFromList */1,
                        _0: {
                          hd: "xQHzwwcAdHQuuqhZ",
                          tl: /* [] */0
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, graph);
    } else if (surveyName === "contact_center_retail_banking_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                  TAG: /* SkipLogic */1,
                  _0: Belt_List.getExn(questions, 2),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 3)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 2),
                      _1: {
                        TAG: /* EqualsFromList */1,
                        _0: {
                          hd: "rp52TixLIn7eGJ1s",
                          tl: /* [] */0
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, graph);
    } else if (surveyName === "retail_asset_osd") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 0),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 2)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 0),
                      _1: {
                        TAG: /* Conditional */2,
                        _0: {
                          TAG: /* GreaterThan */2,
                          _0: {
                            TAG: /* Int */0,
                            _0: "6"
                          }
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, graph);
    } else if (surveyName === "msme_ntb_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 1),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 3)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 1),
                      _1: {
                        TAG: /* Conditional */2,
                        _0: {
                          TAG: /* GreaterThan */2,
                          _0: {
                            TAG: /* Int */0,
                            _0: "6"
                          }
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, graph);
    } else if (surveyName === "iris_customer_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 6), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 6),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 8)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 6),
                      _1: {
                        TAG: /* Conditional */2,
                        _0: {
                          TAG: /* GreaterThan */2,
                          _0: {
                            TAG: /* Int */0,
                            _0: "3"
                          }
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 4),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 6)
                      },
                      _2: {
                        hd: {
                          TAG: /* BranchLogic */0,
                          _0: Belt_List.getExn(questions, 4),
                          _1: {
                            TAG: /* Conditional */2,
                            _0: {
                              TAG: /* GreaterThan */2,
                              _0: {
                                TAG: /* Int */0,
                                _0: "3"
                              }
                            }
                          }
                        },
                        tl: /* [] */0
                      }
                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                          TAG: /* JumpTo */2,
                          _0: Belt_List.getExn(questions, 2),
                          _1: {
                            TAG: /* Question */1,
                            _0: Belt_List.getExn(questions, 4)
                          },
                          _2: {
                            hd: {
                              TAG: /* BranchLogic */0,
                              _0: Belt_List.getExn(questions, 2),
                              _1: {
                                TAG: /* Conditional */2,
                                _0: {
                                  TAG: /* GreaterThan */2,
                                  _0: {
                                    TAG: /* Int */0,
                                    _0: "3"
                                  }
                                }
                              }
                            },
                            tl: /* [] */0
                          }
                        }, graph)));
    } else if (surveyName === "psrm_co-operate_banking_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 10), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 10),
                  _1: {
                    TAG: /* ThankYou */0,
                    _0: Belt_List.getExn(thankYouList, 0)
                  },
                  _2: {
                    hd: {
                      TAG: /* MultipleAnswerLogic */1,
                      _0: Belt_List.getExn(questions, 9),
                      _1: {
                        TAG: /* Contains */0,
                        _0: "XB86vJcouaaG6SM6"
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 9), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 9),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 11)
                      },
                      _2: {
                        hd: {
                          TAG: /* MultipleAnswerLogic */1,
                          _0: Belt_List.getExn(questions, 9),
                          _1: {
                            TAG: /* Contains */0,
                            _0: "77MJp0OoAK3K7tI7"
                          }
                        },
                        tl: /* [] */0
                      }
                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 6), {
                          TAG: /* JumpTo */2,
                          _0: Belt_List.getExn(questions, 6),
                          _1: {
                            TAG: /* Question */1,
                            _0: Belt_List.getExn(questions, 8)
                          },
                          _2: {
                            hd: {
                              TAG: /* RatingGroupLogic */2,
                              _0: Belt_List.getExn(questions, 1),
                              _1: "0lkExwnWpmJeCLit",
                              _2: {
                                TAG: /* Conditional */2,
                                _0: {
                                  TAG: /* GreaterThan */2,
                                  _0: {
                                    TAG: /* Int */0,
                                    _0: "3"
                                  }
                                }
                              }
                            },
                            tl: /* [] */0
                          }
                        }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 5), {
                              TAG: /* JumpTo */2,
                              _0: Belt_List.getExn(questions, 5),
                              _1: {
                                TAG: /* Question */1,
                                _0: Belt_List.getExn(questions, 7)
                              },
                              _2: {
                                hd: {
                                  TAG: /* RatingGroupLogic */2,
                                  _0: Belt_List.getExn(questions, 1),
                                  _1: "0lkExwnWpmJeCLit",
                                  _2: {
                                    TAG: /* Conditional */2,
                                    _0: {
                                      TAG: /* GreaterThan */2,
                                      _0: {
                                        TAG: /* Int */0,
                                        _0: "3"
                                      }
                                    }
                                  }
                                },
                                tl: /* [] */0
                              }
                            }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 5), {
                                  TAG: /* JumpTo */2,
                                  _0: Belt_List.getExn(questions, 5),
                                  _1: {
                                    TAG: /* Question */1,
                                    _0: Belt_List.getExn(questions, 6)
                                  },
                                  _2: {
                                    hd: {
                                      TAG: /* RatingGroupLogic */2,
                                      _0: Belt_List.getExn(questions, 1),
                                      _1: "0lkExwnWpmJeCLit",
                                      _2: {
                                        TAG: /* Conditional */2,
                                        _0: {
                                          TAG: /* LessThan */1,
                                          _0: {
                                            TAG: /* Int */0,
                                            _0: "4"
                                          }
                                        }
                                      }
                                    },
                                    tl: /* [] */0
                                  }
                                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), {
                                      TAG: /* JumpTo */2,
                                      _0: Belt_List.getExn(questions, 4),
                                      _1: {
                                        TAG: /* Question */1,
                                        _0: Belt_List.getExn(questions, 7)
                                      },
                                      _2: {
                                        hd: {
                                          TAG: /* RatingGroupLogic */2,
                                          _0: Belt_List.getExn(questions, 1),
                                          _1: "0lkExwnWpmJeCLit",
                                          _2: {
                                            TAG: /* Conditional */2,
                                            _0: {
                                              TAG: /* GreaterThan */2,
                                              _0: {
                                                TAG: /* Int */0,
                                                _0: "3"
                                              }
                                            }
                                          }
                                        },
                                        tl: /* [] */0
                                      }
                                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), {
                                          TAG: /* JumpTo */2,
                                          _0: Belt_List.getExn(questions, 4),
                                          _1: {
                                            TAG: /* Question */1,
                                            _0: Belt_List.getExn(questions, 6)
                                          },
                                          _2: {
                                            hd: {
                                              TAG: /* RatingGroupLogic */2,
                                              _0: Belt_List.getExn(questions, 1),
                                              _1: "0lkExwnWpmJeCLit",
                                              _2: {
                                                TAG: /* Conditional */2,
                                                _0: {
                                                  TAG: /* LessThan */1,
                                                  _0: {
                                                    TAG: /* Int */0,
                                                    _0: "4"
                                                  }
                                                }
                                              }
                                            },
                                            tl: /* [] */0
                                          }
                                        }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), {
                                              TAG: /* JumpTo */2,
                                              _0: Belt_List.getExn(questions, 3),
                                              _1: {
                                                TAG: /* Question */1,
                                                _0: Belt_List.getExn(questions, 5)
                                              },
                                              _2: {
                                                hd: {
                                                  TAG: /* RatingGroupLogic */2,
                                                  _0: Belt_List.getExn(questions, 1),
                                                  _1: "fFHZdFzfHaXXMVd3",
                                                  _2: {
                                                    TAG: /* Conditional */2,
                                                    _0: {
                                                      TAG: /* GreaterThan */2,
                                                      _0: {
                                                        TAG: /* Int */0,
                                                        _0: "3"
                                                      }
                                                    }
                                                  }
                                                },
                                                tl: /* [] */0
                                              }
                                            }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), {
                                                  TAG: /* JumpTo */2,
                                                  _0: Belt_List.getExn(questions, 3),
                                                  _1: {
                                                    TAG: /* Question */1,
                                                    _0: Belt_List.getExn(questions, 4)
                                                  },
                                                  _2: {
                                                    hd: {
                                                      TAG: /* RatingGroupLogic */2,
                                                      _0: Belt_List.getExn(questions, 1),
                                                      _1: "fFHZdFzfHaXXMVd3",
                                                      _2: {
                                                        TAG: /* Conditional */2,
                                                        _0: {
                                                          TAG: /* LessThan */1,
                                                          _0: {
                                                            TAG: /* Int */0,
                                                            _0: "4"
                                                          }
                                                        }
                                                      }
                                                    },
                                                    tl: /* [] */0
                                                  }
                                                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                                                      TAG: /* JumpTo */2,
                                                      _0: Belt_List.getExn(questions, 2),
                                                      _1: {
                                                        TAG: /* Question */1,
                                                        _0: Belt_List.getExn(questions, 5)
                                                      },
                                                      _2: {
                                                        hd: {
                                                          TAG: /* RatingGroupLogic */2,
                                                          _0: Belt_List.getExn(questions, 1),
                                                          _1: "fFHZdFzfHaXXMVd3",
                                                          _2: {
                                                            TAG: /* Conditional */2,
                                                            _0: {
                                                              TAG: /* GreaterThan */2,
                                                              _0: {
                                                                TAG: /* Int */0,
                                                                _0: "3"
                                                              }
                                                            }
                                                          }
                                                        },
                                                        tl: /* [] */0
                                                      }
                                                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                                                          TAG: /* JumpTo */2,
                                                          _0: Belt_List.getExn(questions, 2),
                                                          _1: {
                                                            TAG: /* Question */1,
                                                            _0: Belt_List.getExn(questions, 4)
                                                          },
                                                          _2: {
                                                            hd: {
                                                              TAG: /* RatingGroupLogic */2,
                                                              _0: Belt_List.getExn(questions, 1),
                                                              _1: "fFHZdFzfHaXXMVd3",
                                                              _2: {
                                                                TAG: /* Conditional */2,
                                                                _0: {
                                                                  TAG: /* LessThan */1,
                                                                  _0: {
                                                                    TAG: /* Int */0,
                                                                    _0: "4"
                                                                  }
                                                                }
                                                              }
                                                            },
                                                            tl: /* [] */0
                                                          }
                                                        }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                                                              TAG: /* JumpTo */2,
                                                              _0: Belt_List.getExn(questions, 1),
                                                              _1: {
                                                                TAG: /* Question */1,
                                                                _0: Belt_List.getExn(questions, 6)
                                                              },
                                                              _2: {
                                                                hd: {
                                                                  TAG: /* RatingGroupLogic */2,
                                                                  _0: Belt_List.getExn(questions, 1),
                                                                  _1: "0lkExwnWpmJeCLit",
                                                                  _2: {
                                                                    TAG: /* Conditional */2,
                                                                    _0: {
                                                                      TAG: /* LessThan */1,
                                                                      _0: {
                                                                        TAG: /* Int */0,
                                                                        _0: "4"
                                                                      }
                                                                    }
                                                                  }
                                                                },
                                                                tl: /* [] */0
                                                              }
                                                            }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                                                                  TAG: /* JumpTo */2,
                                                                  _0: Belt_List.getExn(questions, 1),
                                                                  _1: {
                                                                    TAG: /* Question */1,
                                                                    _0: Belt_List.getExn(questions, 6)
                                                                  },
                                                                  _2: {
                                                                    hd: {
                                                                      TAG: /* RatingGroupLogic */2,
                                                                      _0: Belt_List.getExn(questions, 1),
                                                                      _1: "0lkExwnWpmJeCLit",
                                                                      _2: {
                                                                        TAG: /* Conditional */2,
                                                                        _0: {
                                                                          TAG: /* LessThan */1,
                                                                          _0: {
                                                                            TAG: /* Int */0,
                                                                            _0: "4"
                                                                          }
                                                                        }
                                                                      }
                                                                    },
                                                                    tl: /* [] */0
                                                                  }
                                                                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                                                                      TAG: /* JumpTo */2,
                                                                      _0: Belt_List.getExn(questions, 1),
                                                                      _1: {
                                                                        TAG: /* Question */1,
                                                                        _0: Belt_List.getExn(questions, 7)
                                                                      },
                                                                      _2: {
                                                                        hd: {
                                                                          TAG: /* RatingGroupLogic */2,
                                                                          _0: Belt_List.getExn(questions, 1),
                                                                          _1: "fFHZdFzfHaXXMVd3",
                                                                          _2: {
                                                                            TAG: /* Conditional */2,
                                                                            _0: {
                                                                              TAG: /* GreaterThan */2,
                                                                              _0: {
                                                                                TAG: /* Int */0,
                                                                                _0: "3"
                                                                              }
                                                                            }
                                                                          }
                                                                        },
                                                                        tl: /* [] */0
                                                                      }
                                                                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                                                                          TAG: /* JumpTo */2,
                                                                          _0: Belt_List.getExn(questions, 1),
                                                                          _1: {
                                                                            TAG: /* Question */1,
                                                                            _0: Belt_List.getExn(questions, 4)
                                                                          },
                                                                          _2: {
                                                                            hd: {
                                                                              TAG: /* RatingGroupLogic */2,
                                                                              _0: Belt_List.getExn(questions, 1),
                                                                              _1: "fFHZdFzfHaXXMVd3",
                                                                              _2: {
                                                                                TAG: /* Conditional */2,
                                                                                _0: {
                                                                                  TAG: /* LessThan */1,
                                                                                  _0: {
                                                                                    TAG: /* Int */0,
                                                                                    _0: "4"
                                                                                  }
                                                                                }
                                                                              }
                                                                            },
                                                                            tl: /* [] */0
                                                                          }
                                                                        }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                                                                              TAG: /* JumpTo */2,
                                                                              _0: Belt_List.getExn(questions, 1),
                                                                              _1: {
                                                                                TAG: /* Question */1,
                                                                                _0: Belt_List.getExn(questions, 5)
                                                                              },
                                                                              _2: {
                                                                                hd: {
                                                                                  TAG: /* RatingGroupLogic */2,
                                                                                  _0: Belt_List.getExn(questions, 1),
                                                                                  _1: "fFHZdFzfHaXXMVd3",
                                                                                  _2: {
                                                                                    TAG: /* Conditional */2,
                                                                                    _0: {
                                                                                      TAG: /* GreaterThan */2,
                                                                                      _0: {
                                                                                        TAG: /* Int */0,
                                                                                        _0: "3"
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                },
                                                                                tl: /* [] */0
                                                                              }
                                                                            }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                                                                                  TAG: /* JumpTo */2,
                                                                                  _0: Belt_List.getExn(questions, 1),
                                                                                  _1: {
                                                                                    TAG: /* Question */1,
                                                                                    _0: Belt_List.getExn(questions, 2)
                                                                                  },
                                                                                  _2: {
                                                                                    hd: {
                                                                                      TAG: /* RatingGroupLogic */2,
                                                                                      _0: Belt_List.getExn(questions, 1),
                                                                                      _1: "yrq9Fm6lHMlEHbpu",
                                                                                      _2: {
                                                                                        TAG: /* Conditional */2,
                                                                                        _0: {
                                                                                          TAG: /* LessThan */1,
                                                                                          _0: {
                                                                                            TAG: /* Int */0,
                                                                                            _0: "4"
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    tl: /* [] */0
                                                                                  }
                                                                                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                                                                                      TAG: /* JumpTo */2,
                                                                                      _0: Belt_List.getExn(questions, 1),
                                                                                      _1: {
                                                                                        TAG: /* Question */1,
                                                                                        _0: Belt_List.getExn(questions, 3)
                                                                                      },
                                                                                      _2: {
                                                                                        hd: {
                                                                                          TAG: /* RatingGroupLogic */2,
                                                                                          _0: Belt_List.getExn(questions, 1),
                                                                                          _1: "yrq9Fm6lHMlEHbpu",
                                                                                          _2: {
                                                                                            TAG: /* Conditional */2,
                                                                                            _0: {
                                                                                              TAG: /* GreaterThan */2,
                                                                                              _0: {
                                                                                                TAG: /* Int */0,
                                                                                                _0: "3"
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        },
                                                                                        tl: /* [] */0
                                                                                      }
                                                                                    }, graph))))))))))))))))));
    } else if (surveyName === "trade_n_forex_transaction_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 2),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 4)
                  },
                  _2: {
                    hd: {
                      TAG: /* MultipleAnswerLogic */1,
                      _0: Belt_List.getExn(questions, 2),
                      _1: {
                        TAG: /* Contains */0,
                        _0: "LieEnO0jsMNDq45n"
                      }
                    },
                    tl: /* [] */0
                  }
                }, graph);
    } else if (surveyName === "ybl_adro_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                  TAG: /* SkipLogic */1,
                  _0: Belt_List.getExn(questions, 2),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 3)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 2),
                      _1: {
                        TAG: /* EqualsFromList */1,
                        _0: {
                          hd: "4",
                          tl: {
                            hd: "5",
                            tl: /* [] */0
                          }
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, graph);
    } else if (surveyName === "ybl_msme_etb_survey_v01") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 1),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 3)
                  },
                  _2: {
                    hd: {
                      TAG: /* MultipleAnswerLogic */1,
                      _0: Belt_List.getExn(questions, 1),
                      _1: {
                        TAG: /* Contains */0,
                        _0: "hQRlpLZjafe28Col"
                      }
                    },
                    tl: /* [] */0
                  }
                }, graph);
    } else if (surveyName === "ybl_msme_ntb_survey_v01") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 1),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 3)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 1),
                      _1: {
                        TAG: /* EqualsFromList */1,
                        _0: {
                          hd: "4",
                          tl: {
                            hd: "5",
                            tl: /* [] */0
                          }
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, graph);
    } else if (surveyName === "contact_center_retail_banking_survey_v02") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 2),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 4)
                  },
                  _2: {
                    hd: {
                      TAG: /* MultipleAnswerLogic */1,
                      _0: Belt_List.getExn(questions, 2),
                      _1: {
                        TAG: /* NotContains */1,
                        _0: "Ha0zWyoZj1ZCDRgO"
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 1),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 4)
                      },
                      _2: {
                        hd: {
                          TAG: /* BranchLogic */0,
                          _0: Belt_List.getExn(questions, 0),
                          _1: {
                            TAG: /* Conditional */2,
                            _0: {
                              TAG: /* GreaterThan */2,
                              _0: {
                                TAG: /* Int */0,
                                _0: "8"
                              }
                            }
                          }
                        },
                        tl: /* [] */0
                      }
                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                          TAG: /* JumpTo */2,
                          _0: Belt_List.getExn(questions, 0),
                          _1: {
                            TAG: /* Question */1,
                            _0: Belt_List.getExn(questions, 2)
                          },
                          _2: {
                            hd: {
                              TAG: /* BranchLogic */0,
                              _0: Belt_List.getExn(questions, 0),
                              _1: {
                                TAG: /* Conditional */2,
                                _0: {
                                  TAG: /* LessThan */1,
                                  _0: {
                                    TAG: /* Int */0,
                                    _0: "9"
                                  }
                                }
                              }
                            },
                            tl: /* [] */0
                          }
                        }, graph)));
    } else if (surveyName === "ybl_rm_affluent_individual_survey_v01" || surveyName === "ybl_rm_affluent_nonindividual_survey_v01" || surveyName === "ybl_service_rm_affluent_individual_survey" || surveyName === "ybl_service_rm_affluent_nonindividual_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 0),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 2)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 0),
                      _1: {
                        TAG: /* Conditional */2,
                        _0: {
                          TAG: /* GreaterThan */2,
                          _0: {
                            TAG: /* Int */0,
                            _0: "8"
                          }
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, graph);
    } else if (surveyName === "ybl_wholesale_banking_service_contact_centre" || surveyName === "ybl_wholesale_banking_service_relationship_manager") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 1),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 3)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 0),
                      _1: {
                        TAG: /* Conditional */2,
                        _0: {
                          TAG: /* LessThan */1,
                          _0: {
                            TAG: /* Int */0,
                            _0: "9"
                          }
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 0),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 2)
                      },
                      _2: {
                        hd: {
                          TAG: /* BranchLogic */0,
                          _0: Belt_List.getExn(questions, 0),
                          _1: {
                            TAG: /* Conditional */2,
                            _0: {
                              TAG: /* GreaterThan */2,
                              _0: {
                                TAG: /* Int */0,
                                _0: "8"
                              }
                            }
                          }
                        },
                        tl: /* [] */0
                      }
                    }, graph));
    } else if (surveyName === "ybl_yes_private_contact_center") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 2),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 4)
                  },
                  _2: {
                    hd: {
                      TAG: /* MultipleAnswerLogic */1,
                      _0: Belt_List.getExn(questions, 2),
                      _1: {
                        TAG: /* NotContains */1,
                        _0: "QQUCxSF28GyxkZov"
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 1),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 4)
                      },
                      _2: {
                        hd: {
                          TAG: /* BranchLogic */0,
                          _0: Belt_List.getExn(questions, 0),
                          _1: {
                            TAG: /* Conditional */2,
                            _0: {
                              TAG: /* GreaterThan */2,
                              _0: {
                                TAG: /* Int */0,
                                _0: "8"
                              }
                            }
                          }
                        },
                        tl: /* [] */0
                      }
                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                          TAG: /* JumpTo */2,
                          _0: Belt_List.getExn(questions, 0),
                          _1: {
                            TAG: /* Question */1,
                            _0: Belt_List.getExn(questions, 2)
                          },
                          _2: {
                            hd: {
                              TAG: /* BranchLogic */0,
                              _0: Belt_List.getExn(questions, 0),
                              _1: {
                                TAG: /* Conditional */2,
                                _0: {
                                  TAG: /* LessThan */1,
                                  _0: {
                                    TAG: /* Int */0,
                                    _0: "9"
                                  }
                                }
                              }
                            },
                            tl: /* [] */0
                          }
                        }, graph)));
    } else if (surveyName === "ybl_vrm_sa_suvey_v01" || surveyName === "ybl_vrm_ca_survey_v01" || surveyName === "ybl_vrm_nri_survey_v01") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 1),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 3)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 0),
                      _1: {
                        TAG: /* Conditional */2,
                        _0: {
                          TAG: /* LessThan */1,
                          _0: {
                            TAG: /* Int */0,
                            _0: "9"
                          }
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 0),
                      _1: {
                        TAG: /* Question */1,
                        _0: Belt_List.getExn(questions, 2)
                      },
                      _2: {
                        hd: {
                          TAG: /* BranchLogic */0,
                          _0: Belt_List.getExn(questions, 0),
                          _1: {
                            TAG: /* Conditional */2,
                            _0: {
                              TAG: /* GreaterThan */2,
                              _0: {
                                TAG: /* Int */0,
                                _0: "8"
                              }
                            }
                          }
                        },
                        tl: /* [] */0
                      }
                    }, graph));
    } else if (surveyName === "ybl_corporate_netbanking") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 5), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 5),
                  _1: {
                    TAG: /* ThankYou */0,
                    _0: Belt_List.getExn(thankYouList, 0)
                  },
                  _2: {
                    hd: {
                      TAG: /* MultipleAnswerLogic */1,
                      _0: Belt_List.getExn(questions, 5),
                      _1: {
                        TAG: /* NotContains */1,
                        _0: "cKAa2xzcGkB22xYK"
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 4),
                      _1: {
                        TAG: /* ThankYou */0,
                        _0: Belt_List.getExn(thankYouList, 0)
                      },
                      _2: {
                        hd: {
                          TAG: /* BranchLogic */0,
                          _0: Belt_List.getExn(questions, 4),
                          _1: {
                            TAG: /* Conditional */2,
                            _0: {
                              TAG: /* GreaterThan */2,
                              _0: {
                                TAG: /* Int */0,
                                _0: "3"
                              }
                            }
                          }
                        },
                        tl: /* [] */0
                      }
                    }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                          TAG: /* JumpTo */2,
                          _0: Belt_List.getExn(questions, 2),
                          _1: {
                            TAG: /* Question */1,
                            _0: Belt_List.getExn(questions, 4)
                          },
                          _2: {
                            hd: {
                              TAG: /* MultipleAnswerLogic */1,
                              _0: Belt_List.getExn(questions, 2),
                              _1: {
                                TAG: /* Contains */0,
                                _0: "f6dHV4qqytIgVBUl"
                              }
                            },
                            tl: /* [] */0
                          }
                        }, graph)));
    } else if (surveyName === "hospitality_demo_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 4),
                  _1: {
                    TAG: /* ThankYou */0,
                    _0: Belt_List.getExn(thankYouList, 0)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 0),
                      _1: {
                        TAG: /* Conditional */2,
                        _0: {
                          TAG: /* LessThan */1,
                          _0: {
                            TAG: /* Int */0,
                            _0: "9"
                          }
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                      TAG: /* JumpTo */2,
                      _0: Belt_List.getExn(questions, 0),
                      _1: {
                        TAG: /* ThankYou */0,
                        _0: Belt_List.getExn(thankYouList, 1)
                      },
                      _2: {
                        hd: {
                          TAG: /* BranchLogic */0,
                          _0: Belt_List.getExn(questions, 0),
                          _1: {
                            TAG: /* Conditional */2,
                            _0: {
                              TAG: /* GreaterThan */2,
                              _0: {
                                TAG: /* Int */0,
                                _0: "8"
                              }
                            }
                          }
                        },
                        tl: /* [] */0
                      }
                    }, graph));
    } else if (surveyName === "ybl_contact_centre_voice_bot_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 1),
                  _1: {
                    TAG: /* Question */1,
                    _0: Belt_List.getExn(questions, 3)
                  },
                  _2: {
                    hd: {
                      TAG: /* MultipleAnswerLogic */1,
                      _0: Belt_List.getExn(questions, 1),
                      _1: {
                        TAG: /* Contains */0,
                        _0: "hQRlpLZjafe28Col"
                      }
                    },
                    tl: /* [] */0
                  }
                }, graph);
    } else if (surveyName === "grant_hotel_nps_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), {
                  TAG: /* JumpTo */2,
                  _0: Belt_List.getExn(questions, 2),
                  _1: {
                    TAG: /* ThankYou */0,
                    _0: Belt_List.getExn(thankYouList, 1)
                  },
                  _2: {
                    hd: {
                      TAG: /* BranchLogic */0,
                      _0: Belt_List.getExn(questions, 0),
                      _1: {
                        TAG: /* Conditional */2,
                        _0: {
                          TAG: /* GreaterThan */2,
                          _0: {
                            TAG: /* Int */0,
                            _0: "8"
                          }
                        }
                      }
                    },
                    tl: /* [] */0
                  }
                }, graph);
    } else {
      return graph;
    }
  } else {
    return graph;
  }
}

exports.addLogicToEdges = addLogicToEdges;
exports.changeEdgeForBranchLogic = changeEdgeForBranchLogic;
exports.changeEdges = changeEdges;
/* LogicGraph-ReactHooksTemplate Not a pure module */
