// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var OpinionScale$ReactHooksTemplate = require("./OpinionScale.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var OpinionScaleLabel$ReactHooksTemplate = require("./OpinionScaleLabel.bs.js");

function OpinionScaleDefaultForm$OpinionScaleItem(Props) {
  var step = Props.step;
  var className = Props.className;
  var onSelection = Props.onSelection;
  var style = Props.style;
  return React.createElement("button", {
              className: className,
              style: style,
              onClick: (function ($$event) {
                  $$event.preventDefault();
                  return Curry._1(onSelection, step);
                })
            }, React.createElement("div", undefined, React.createElement("span", undefined, String(step))));
}

var OpinionScaleItem = {
  make: OpinionScaleDefaultForm$OpinionScaleItem
};

function colorBasedOnLikelyness(index) {
  switch (index) {
    case 0 :
    case 1 :
    case 2 :
    case 3 :
    case 4 :
        return "#f5d0d0";
    case 5 :
    case 6 :
        return "#d9daf8";
    case 7 :
    case 8 :
    case 9 :
    case 10 :
        return "#c7f8d8";
    default:
      return "#ffffff";
  }
}

function OpinionScaleDefaultForm(Props) {
  var opinionScale = Props.opinionScale;
  var language = Props.language;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var borderCss = Props.borderCss;
  var selectedCss = function (x) {
    if (OpinionScale$ReactHooksTemplate.isAnswer(opinionScale, x)) {
      return "text-vistablue border-2 border-bleudefrance";
    } else {
      return "";
    }
  };
  var items = function (a, b) {
    return $$Array.of_list(Belt_List.mapWithIndex(Belt_List.makeBy((b - a | 0) + 1 | 0, (function (x) {
                          return x;
                        })), (function (i, x) {
                      return React.createElement(OpinionScaleDefaultForm$OpinionScaleItem, {
                                  step: x + a | 0,
                                  className: selectedCss(x) + " small:w-6 small:h-6 w-12 h-12 small:rounded rounded-xl focus:outline-none\n                  text-darkcharcoal small:text-sm text-lg font-medium",
                                  onSelection: (function (scale) {
                                      return Curry._1(onChange, OpinionScale$ReactHooksTemplate.setAnswer(opinionScale, scale));
                                    }),
                                  style: {
                                    backgroundColor: colorBasedOnLikelyness(i)
                                  },
                                  key: String(i + a | 0)
                                });
                    })));
  };
  var questionText = OpinionScale$ReactHooksTemplate.getTextTranslation(opinionScale, SurveyLanguage$ReactHooksTemplate.name(language));
  return React.createElement("div", {
              className: "w-full py-8 px-16 small:px-6 " + borderCss
            }, React.createElement("div", undefined, React.createElement("div", {
                      className: "text-lg font-medium text-darkcharcoal py-6"
                    }, mandatory ? "" + questionText + " *" : "" + questionText), React.createElement("div", {
                      className: "text-base font-normal text-darkcharcoal pb-8"
                    }, OpinionScale$ReactHooksTemplate.getDescriptionTranslation(opinionScale, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("div", {
                  className: "flex w-full"
                }, React.createElement("div", {
                      className: "flex flex-col justify-between items-start"
                    }, React.createElement("div", {
                          className: "flex small:gap-1 gap-2"
                        }, items(0, 10)), React.createElement("div", {
                          className: " flex justify-between w-full pt-6 pb-10 text-spanishgray text-sm "
                        }, React.createElement("p", undefined, OpinionScaleLabel$ReactHooksTemplate.left(OpinionScale$ReactHooksTemplate.getLabelTranslation(opinionScale, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("p", undefined, OpinionScaleLabel$ReactHooksTemplate.right(OpinionScale$ReactHooksTemplate.getLabelTranslation(opinionScale, SurveyLanguage$ReactHooksTemplate.name(language))))), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                            language: language
                          }) : React.createElement(React.Fragment, undefined))));
}

var make = OpinionScaleDefaultForm;

exports.OpinionScaleItem = OpinionScaleItem;
exports.colorBasedOnLikelyness = colorBasedOnLikelyness;
exports.make = make;
/* react Not a pure module */
