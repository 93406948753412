// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var SurveyTheme$ReactHooksTemplate = require("./surveyTheme.bs.js");

function QuestionText(Props) {
  var text = Props.text;
  var description = Props.description;
  var mandatory = Props.mandatory;
  var surveyTheme = Props.surveyTheme;
  var match = SurveyTheme$ReactHooksTemplate.name(surveyTheme);
  switch (match) {
    case "bhima" :
    case "yesbank" :
        break;
    default:
      return React.createElement("div", {
                  className: "flex flex-col items-start mb-8 small:mb-6"
                }, React.createElement("p", {
                      className: " font-normal text-lg leading-8 tracking-0.8 small:text-base\n        small:leading-6 small:tracking-wide"
                    }, mandatory ? "" + text + " *" : "" + text), React.createElement("p", {
                      className: " text-base small:text-xs mt-10-px "
                    }, description));
  }
  return React.createElement("div", {
              className: "flex flex-col"
            }, React.createElement("p", {
                  className: " text-2xl small:text-lg small:py-5 font-semibold\n        mb-4 small:mb-2 "
                }, mandatory ? "" + text + " *" : "" + text), React.createElement("p", {
                  className: " text-base mb-12 small:mb-4 "
                }, description));
}

var make = QuestionText;

exports.make = make;
/* react Not a pure module */
