// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function key(t) {
  return t.key;
}

function stackId(t) {
  return t.stackId;
}

function type_(t) {
  return t.type_;
}

function yAxisId(t) {
  return t.yAxisId;
}

function yAxisOrientation(t) {
  return t.yAxisOrientation;
}

function fill(t) {
  return t.fill;
}

function min(t) {
  return t.min;
}

function max(t) {
  return t.max;
}

function decode(json) {
  var axisType = Json_decode.withDefault("", (function (param) {
          return Json_decode.field("type", Json_decode.string, param);
        }), json);
  var tmp;
  switch (axisType) {
    case "bar" :
        tmp = /* Bar */1;
        break;
    case "line" :
        tmp = /* Line */0;
        break;
    case "scatter" :
        tmp = /* Scatter */2;
        break;
    default:
      tmp = /* Bar */1;
  }
  return {
          key: Json_decode.field("key", Json_decode.string, json),
          stackId: Json_decode.withDefault(undefined, (function (param) {
                  return Json_decode.field("stack_id", (function (param) {
                                return Json_decode.optional(Json_decode.string, param);
                              }), param);
                }), json),
          type_: tmp,
          fill: Json_decode.withDefault(undefined, (function (param) {
                  return Json_decode.field("fill", (function (param) {
                                return Json_decode.optional(Json_decode.string, param);
                              }), param);
                }), json),
          yAxisId: Json_decode.withDefault(undefined, (function (param) {
                  return Json_decode.field("y_axis_id", (function (param) {
                                return Json_decode.optional(Json_decode.string, param);
                              }), param);
                }), json),
          yAxisOrientation: Json_decode.withDefault(undefined, (function (param) {
                  return Json_decode.field("orientation", (function (param) {
                                return Json_decode.optional(Json_decode.string, param);
                              }), param);
                }), json),
          yAxisStroke: Json_decode.withDefault(undefined, (function (param) {
                  return Json_decode.field("stroke", (function (param) {
                                return Json_decode.optional(Json_decode.string, param);
                              }), param);
                }), json),
          min: Json_decode.withDefault(undefined, (function (param) {
                  return Json_decode.field("min", (function (param) {
                                return Json_decode.optional(Json_decode.$$int, param);
                              }), param);
                }), json),
          max: Json_decode.withDefault(undefined, (function (param) {
                  return Json_decode.field("max", (function (param) {
                                return Json_decode.optional(Json_decode.$$int, param);
                              }), param);
                }), json)
        };
}

exports.key = key;
exports.stackId = stackId;
exports.yAxisId = yAxisId;
exports.yAxisOrientation = yAxisOrientation;
exports.decode = decode;
exports.type_ = type_;
exports.fill = fill;
exports.min = min;
exports.max = max;
/* No side effect */
