// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Welcome$ReactHooksTemplate = require("./Welcome.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");

function WelcomeForm(Props) {
  var $$default = Props.default;
  var welcome = Props.welcome;
  var handleStart = Props.handleStart;
  var buttonBgColor = Props.buttonBgColor;
  var language = Props.language;
  var withDefault = function ($$default, x) {
    if (x.length === 0) {
      return $$default;
    } else {
      return x;
    }
  };
  return React.createElement("div", {
              className: "flex flex-col w-full",
              style: {
                minHeight: "calc(100vh - 200px)"
              }
            }, React.createElement("div", {
                  className: "flex flex-col items-center justify-center my-auto"
                }, React.createElement("p", {
                      className: " pb-16-px font-bold text-xl text-center\n        small:text-lg "
                    }, withDefault(Welcome$ReactHooksTemplate.title($$default), Welcome$ReactHooksTemplate.getTitleTranslation(welcome, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("p", {
                      className: " mb-10-px pb-16-px font-bold text-l\n        text-center small:text-sm "
                    }, withDefault(Welcome$ReactHooksTemplate.description($$default), Welcome$ReactHooksTemplate.getDescriptionTranslation(welcome, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("button", {
                      className: "w-full h-48-px small:h-40-px rounded-lg text-white\n        text-base leading-4 font-semibold text-center",
                      style: {
                        backgroundColor: buttonBgColor === "" ? "rgb(43, 97, 171)" : buttonBgColor
                      },
                      onClick: (function ($$event) {
                          $$event.preventDefault();
                          return Curry._1(handleStart, undefined);
                        })
                    }, withDefault(Welcome$ReactHooksTemplate.button($$default), Welcome$ReactHooksTemplate.getButtonTranslation(welcome, SurveyLanguage$ReactHooksTemplate.name(language))))), React.createElement("div", {
                  className: "w-full px-8 flex flex-col justify-center"
                }, React.createElement("div", {
                      className: "flex justify-center text-xs font-normal"
                    }, "Powered by"), React.createElement("div", {
                      className: "flex justify-center items-center mt-1"
                    }, React.createElement("a", {
                          href: "https://www.geckolyst.com/"
                        }, React.createElement("img", {
                              className: "h-30-px",
                              src: "https://geckolyst.sgp1.cdn.digitaloceanspaces.com/geckolyst_logo.svg"
                            })))));
}

var make = WelcomeForm;

exports.make = make;
/* react Not a pure module */
