// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var ApplicationSetting$ReactHooksTemplate = require("./applicationSetting.bs.js");
var SavePartialResponse$ReactHooksTemplate = require("./savePartialResponse.bs.js");

function empty(param) {
  return /* [] */0;
}

function savePartialResponse(t) {
  var applicationSetting = List.find((function (x) {
          var match = ApplicationSetting$ReactHooksTemplate.individualSetting(x);
          switch (match.TAG | 0) {
            case /* SavePartialResponse */0 :
                return true;
            case /* GenerateTicket */1 :
            case /* CampaignModes */2 :
                return false;
            
          }
        }), t);
  var y = ApplicationSetting$ReactHooksTemplate.individualSetting(applicationSetting);
  switch (y.TAG | 0) {
    case /* SavePartialResponse */0 :
        return SavePartialResponse$ReactHooksTemplate.value(y._0);
    case /* GenerateTicket */1 :
    case /* CampaignModes */2 :
        return false;
    
  }
}

function generateTicket(t) {
  var applicationSetting = List.find((function (x) {
          var match = ApplicationSetting$ReactHooksTemplate.individualSetting(x);
          switch (match.TAG | 0) {
            case /* SavePartialResponse */0 :
                return true;
            case /* GenerateTicket */1 :
            case /* CampaignModes */2 :
                return false;
            
          }
        }), t);
  var y = ApplicationSetting$ReactHooksTemplate.individualSetting(applicationSetting);
  switch (y.TAG | 0) {
    case /* SavePartialResponse */0 :
        return SavePartialResponse$ReactHooksTemplate.value(y._0);
    case /* GenerateTicket */1 :
    case /* CampaignModes */2 :
        return false;
    
  }
}

function decoder(param) {
  return Json_decode.list(ApplicationSetting$ReactHooksTemplate.decode, param);
}

exports.empty = empty;
exports.savePartialResponse = savePartialResponse;
exports.generateTicket = generateTicket;
exports.decoder = decoder;
/* No side effect */
