// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Logic$ReactHooksTemplate = require("./Logic.bs.js");
var Modal$ReactHooksTemplate = require("./Modal.bs.js");
var Survey$ReactHooksTemplate = require("./survey.bs.js");
var Welcome$ReactHooksTemplate = require("./Welcome.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var ThankYou$ReactHooksTemplate = require("./ThankYou.bs.js");
var LogicPage$ReactHooksTemplate = require("./LogicPage.bs.js");
var LogicGraph$ReactHooksTemplate = require("./LogicGraph.bs.js");
var LogicalJump$ReactHooksTemplate = require("./LogicalJump.bs.js");
var SurveyLogic$ReactHooksTemplate = require("./SurveyLogic.bs.js");
var SurveyTheme$ReactHooksTemplate = require("./surveyTheme.bs.js");
var CustomerInfo$ReactHooksTemplate = require("./CustomerInfo.bs.js");
var Organization$ReactHooksTemplate = require("../settings/organization.bs.js");
var WelcomeEditor$ReactHooksTemplate = require("./WelcomeEditor.bs.js");
var AlertComponent$ReactHooksTemplate = require("../common/AlertComponent.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var ThankYouEditor$ReactHooksTemplate = require("./ThankYouEditor.bs.js");
var SurveyConfiguration$ReactHooksTemplate = require("./SurveyConfiguration.bs.js");
var SurveyBuilderPreview$ReactHooksTemplate = require("./SurveyBuilderPreview.bs.js");
var SurveyPreviewWrapper$ReactHooksTemplate = require("./SurveyPreviewWrapper.bs.js");

var bhimaQuestiontemplate = "\n  <div class=\"h-full\">\n    <div class=\"pt-40-px w-full pb-20-px absolute\">\n      <div id=\"logo\">\n        logo\n      </div>\n    </div>\n    <div class=\"max-w-840-px mx-auto px-60-px min-h-screen flex flex-col\n    justify-center pt-160-px small:pt-142-px\">\n\n      <div class=\"pb-100-px\">\n        <div id=\"question\">question</div>\n      </div>\n      <div class=\"flex justify-center items-center mb-8\">\n        <div id=\"previousButton\"></div>\n        <div id=\"nextButton\"></div>\n      </div>\n    </div>\n  </div>\n";

var defaultQuestiontemplate = "\n  <div class=\"h-full\">\n    <div class=\"pt-40-px w-full pb-20-px\">\n      <div id=\"logo\">\n        logo\n      </div>\n    </div>\n    <div class=\"max-w-960-px mx-auto p-30-px min-h-screen flex flex-col\n    justify-center\">\n      <div class=\"pb-60-px\">\n        <div id=\"question\">question</div>\n      </div>\n      <div class=\"flex justify-center items-center mb-8\">\n        <div id=\"previousButton\"></div>\n        <div id=\"nextButton\"></div>\n      </div>\n    </div>\n  </div>\n";

var defaultQuestionBuildertemplate = "\n  <div>\n    <div>\n      <div>\n        <div id=\"question\">question</div>\n      </div>\n    </div>\n  </div>\n";

var questiontemplates = {
  bhima: bhimaQuestiontemplate,
  default: defaultQuestiontemplate
};

var questionBuilderTemplates = {
  bhima: bhimaQuestiontemplate,
  default: defaultQuestionBuildertemplate
};

function questionsOrPageToString(x) {
  switch (x) {
    case /* Welcome */0 :
        return "welcome";
    case /* ThankYou */1 :
        return "thank_you";
    case /* LongText */2 :
        return "LongText";
    case /* ShortText */3 :
        return "ShortText";
    case /* StarRating */4 :
        return "StarRating";
    case /* OpinionScale */5 :
        return "OpinionScale";
    case /* Nps */6 :
        return "Nps";
    case /* MultipleChoice */7 :
        return "MultipleChoice";
    case /* RatingGroup */8 :
        return "RatingGroup";
    case /* SmileyRating */9 :
        return "SmileyRating";
    case /* Csat */10 :
        return "Csat";
    case /* MultipleSelect */11 :
        return "MultipleSelect";
    case /* DropDown */12 :
        return "DropDown";
    case /* MobileNumber */13 :
        return "MobileNumber";
    
  }
}

var initialAccordionState = {
  questionSettingsTab: true
};

function decodeSurvey(json) {
  var name = Json_decode.field("survey", (function (param) {
          return Json_decode.field("name", Json_decode.string, param);
        }), json);
  var logo = Json_decode.field("survey", (function (param) {
          return Json_decode.field("logo", (function (param) {
                        return Json_decode.optional(Json_decode.string, param);
                      }), param);
        }), json);
  var questionFromJson = Question$ReactHooksTemplate.fromJson(json);
  var theme = Json_decode.field("survey", (function (param) {
          return Json_decode.field("theme", SurveyTheme$ReactHooksTemplate.decode, param);
        }), json);
  var thankYouList = Json_decode.field("survey", (function (param) {
          return Json_decode.field("thank_yous", (function (param) {
                        return Json_decode.list(ThankYou$ReactHooksTemplate.fromJson, param);
                      }), param);
        }), json);
  var surveyLanguages = Json_decode.field("survey", (function (param) {
          return Json_decode.field("survey_languages", (function (param) {
                        return Json_decode.list(SurveyLanguage$ReactHooksTemplate.fromJson, param);
                      }), param);
        }), json);
  return [
          name,
          logo,
          theme,
          Welcome$ReactHooksTemplate.fromJson(json),
          questionFromJson,
          LogicalJump$ReactHooksTemplate.fromJson(questionFromJson, json),
          thankYouList,
          surveyLanguages
        ];
}

function addQuestionsToGraph(questions) {
  return List.fold_left((function (graph, item) {
                return LogicGraph$ReactHooksTemplate.addNode({
                            TAG: /* Question */1,
                            _0: item
                          }, graph);
              }), LogicGraph$ReactHooksTemplate.empty(undefined), questions);
}

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* RemoveWelcomeEditorBlock */0 :
          var welcome = Survey$ReactHooksTemplate.welcome(state.survey);
          var survey;
          if (welcome !== undefined) {
            var match = Welcome$ReactHooksTemplate.id(Caml_option.valFromOption(welcome));
            survey = match !== undefined ? Survey$ReactHooksTemplate.setDeletedWelcome(state.survey, Survey$ReactHooksTemplate.welcome(state.survey)) : state.survey;
          } else {
            survey = state.survey;
          }
          var match$1 = Survey$ReactHooksTemplate.questions(state.survey);
          var nextQuestion;
          if (match$1) {
            nextQuestion = {
              TAG: /* Question */2,
              _0: match$1.hd
            };
          } else {
            var match$2 = Survey$ReactHooksTemplate.thankYouList(state.survey);
            nextQuestion = match$2 ? ({
                  TAG: /* ThankYou */1,
                  _0: match$2.hd
                }) : /* Empty */0;
          }
          if (typeof nextQuestion !== "number") {
            switch (nextQuestion.TAG | 0) {
              case /* Welcome */0 :
                  break;
              case /* ThankYou */1 :
                  var x = nextQuestion._0;
                  var newrecord = Caml_obj.caml_obj_dup(state);
                  newrecord.builderPreview = {
                    TAG: /* ThankYou */2,
                    _0: Caml_option.some(x)
                  };
                  newrecord.selectedId = ThankYou$ReactHooksTemplate.uuid(x);
                  newrecord.selected = ThankYou$ReactHooksTemplate.categoryType;
                  newrecord.survey = Survey$ReactHooksTemplate.setWelcome(survey, undefined);
                  return newrecord;
              case /* Question */2 :
                  var x$1 = nextQuestion._0;
                  var newrecord$1 = Caml_obj.caml_obj_dup(state);
                  newrecord$1.builderPreview = {
                    TAG: /* QuestionPage */1,
                    _0: questionBuilderTemplates,
                    _1: x$1,
                    _2: CustomerInfo$ReactHooksTemplate.empty(undefined)
                  };
                  newrecord$1.selectedId = Question$ReactHooksTemplate.uuid(x$1);
                  newrecord$1.selected = Question$ReactHooksTemplate.categoryType(x$1);
                  newrecord$1.survey = Survey$ReactHooksTemplate.setWelcome(survey, undefined);
                  return newrecord$1;
              
            }
          }
          var newrecord$2 = Caml_obj.caml_obj_dup(state);
          newrecord$2.builderPreview = /* Empty */0;
          newrecord$2.selectedId = RandomId$ReactHooksTemplate.generate(undefined);
          newrecord$2.selected = Welcome$ReactHooksTemplate.categoryType;
          newrecord$2.survey = Survey$ReactHooksTemplate.setWelcome(survey, undefined);
          return newrecord$2;
      case /* PreviewWelcome */1 :
          var newrecord$3 = Caml_obj.caml_obj_dup(state);
          newrecord$3.builderPreview = {
            TAG: /* Welcome */0,
            _0: Survey$ReactHooksTemplate.welcome(state.survey)
          };
          return newrecord$3;
      case /* TogglePreview */2 :
          var newrecord$4 = Caml_obj.caml_obj_dup(state);
          newrecord$4.showPreview = !state.showPreview;
          return newrecord$4;
      case /* ToggleAddQuestion */3 :
          var newrecord$5 = Caml_obj.caml_obj_dup(state);
          newrecord$5.showAddQuestions = !state.showAddQuestions;
          return newrecord$5;
      case /* ToggleSurveyConfiguration */4 :
          var newrecord$6 = Caml_obj.caml_obj_dup(state);
          newrecord$6.showConfigurations = !state.showConfigurations;
          return newrecord$6;
      case /* AddLogicalJump */5 :
          var newrecord$7 = Caml_obj.caml_obj_dup(state);
          newrecord$7.logicalJumpGroup = LogicalJump$ReactHooksTemplate.addToGroup(LogicalJump$ReactHooksTemplate.empty(undefined), state.logicalJumpGroup);
          return newrecord$7;
      case /* InvalidName */6 :
          var newrecord$8 = Caml_obj.caml_obj_dup(state);
          newrecord$8.nameInvalid = true;
          return newrecord$8;
      case /* ValidName */7 :
          var newrecord$9 = Caml_obj.caml_obj_dup(state);
          newrecord$9.nameInvalid = false;
          return newrecord$9;
      case /* RemoveNotification */8 :
          var newrecord$10 = Caml_obj.caml_obj_dup(state);
          newrecord$10.notification = undefined;
          return newrecord$10;
      
    }
  } else {
    switch (action.TAG | 0) {
      case /* UpdateSurvey */0 :
          var newrecord$11 = Caml_obj.caml_obj_dup(state);
          newrecord$11.survey = action._0;
          return newrecord$11;
      case /* SetClient */1 :
          var newrecord$12 = Caml_obj.caml_obj_dup(state);
          newrecord$12.client = action._0;
          return newrecord$12;
      case /* AddEditorBlock */2 :
          var selected = questionsOrPageToString(action._0);
          if (selected === Welcome$ReactHooksTemplate.categoryType) {
            var welcome$1 = Welcome$ReactHooksTemplate.empty(undefined);
            var newrecord$13 = Caml_obj.caml_obj_dup(state);
            newrecord$13.builderPreview = {
              TAG: /* Welcome */0,
              _0: Caml_option.some(welcome$1)
            };
            newrecord$13.selected = Welcome$ReactHooksTemplate.categoryType;
            newrecord$13.survey = Survey$ReactHooksTemplate.setWelcome(state.survey, Caml_option.some(welcome$1));
            return newrecord$13;
          }
          if (selected === ThankYou$ReactHooksTemplate.categoryType) {
            var thankYou = ThankYou$ReactHooksTemplate.empty(undefined);
            var thankYouList = Belt_List.concat(Survey$ReactHooksTemplate.thankYouList(state.survey), {
                  hd: thankYou,
                  tl: /* [] */0
                });
            var noOfThankYous = Belt_List.length(thankYouList);
            var addedThankYouEl = List.nth(thankYouList, noOfThankYous - 1 | 0);
            var newrecord$14 = Caml_obj.caml_obj_dup(state);
            newrecord$14.builderPreview = {
              TAG: /* ThankYou */2,
              _0: Caml_option.some(thankYou)
            };
            newrecord$14.selectedId = ThankYou$ReactHooksTemplate.uuid(addedThankYouEl);
            newrecord$14.selected = ThankYou$ReactHooksTemplate.categoryType;
            newrecord$14.survey = Survey$ReactHooksTemplate.setThankYouList(state.survey, thankYouList);
            return newrecord$14;
          }
          var question = Question$ReactHooksTemplate.fromCategoryType(selected);
          var questions = Belt_List.concat(Survey$ReactHooksTemplate.questions(state.survey), Belt_Option.mapWithDefault(question, /* [] */0, (function (x) {
                      return {
                              hd: x,
                              tl: /* [] */0
                            };
                    })));
          var graph$p = SurveyLogic$ReactHooksTemplate.addLogicToEdges(questions, SurveyLogic$ReactHooksTemplate.changeEdges(Survey$ReactHooksTemplate.name(state.survey), questions, Survey$ReactHooksTemplate.thankYouList(state.survey), addQuestionsToGraph(questions)));
          var noOfQuestions = Belt_List.length(questions);
          var addedQuestionEl = List.nth(questions, noOfQuestions - 1 | 0);
          var newrecord$15 = Caml_obj.caml_obj_dup(state);
          newrecord$15.graph = graph$p;
          newrecord$15.builderPreview = {
            TAG: /* QuestionPage */1,
            _0: questionBuilderTemplates,
            _1: question,
            _2: CustomerInfo$ReactHooksTemplate.empty(undefined)
          };
          newrecord$15.selectedId = Question$ReactHooksTemplate.uuid(addedQuestionEl);
          newrecord$15.selected = selected;
          newrecord$15.survey = Survey$ReactHooksTemplate.setQuestions(state.survey, questions);
          return newrecord$15;
      case /* AddEditorBlockInBetween */3 :
          var index = action._0;
          var selected$1 = questionsOrPageToString(action._1);
          if (selected$1 === Welcome$ReactHooksTemplate.categoryType) {
            var welcome$2 = Welcome$ReactHooksTemplate.empty(undefined);
            var newrecord$16 = Caml_obj.caml_obj_dup(state);
            newrecord$16.builderPreview = {
              TAG: /* Welcome */0,
              _0: Caml_option.some(welcome$2)
            };
            newrecord$16.selected = Welcome$ReactHooksTemplate.categoryType;
            newrecord$16.survey = Survey$ReactHooksTemplate.setWelcome(state.survey, Caml_option.some(welcome$2));
            return newrecord$16;
          }
          if (selected$1 === ThankYou$ReactHooksTemplate.categoryType) {
            var thankYou$1 = ThankYou$ReactHooksTemplate.empty(undefined);
            console.log(index);
            var match$3 = Belt_List.splitAt(Survey$ReactHooksTemplate.thankYouList(state.survey), index);
            var thankYouList$1 = match$3 !== undefined ? Belt_List.concatMany([
                    match$3[0],
                    {
                      hd: thankYou$1,
                      tl: /* [] */0
                    },
                    match$3[1]
                  ]) : Belt_List.concat(Survey$ReactHooksTemplate.thankYouList(state.survey), {
                    hd: thankYou$1,
                    tl: /* [] */0
                  });
            var addedThankYouEl$1 = List.nth(thankYouList$1, index);
            var newrecord$17 = Caml_obj.caml_obj_dup(state);
            newrecord$17.builderPreview = {
              TAG: /* ThankYou */2,
              _0: Caml_option.some(addedThankYouEl$1)
            };
            newrecord$17.selectedId = ThankYou$ReactHooksTemplate.uuid(addedThankYouEl$1);
            newrecord$17.selected = ThankYou$ReactHooksTemplate.categoryType;
            newrecord$17.survey = Survey$ReactHooksTemplate.setThankYouList(state.survey, thankYouList$1);
            return newrecord$17;
          }
          var question$1 = Question$ReactHooksTemplate.fromCategoryType(selected$1);
          var match$4 = Belt_List.splitAt(Survey$ReactHooksTemplate.questions(state.survey), index);
          var questions$1 = match$4 !== undefined ? Belt_List.concatMany([
                  match$4[0],
                  Belt_Option.mapWithDefault(question$1, /* [] */0, (function (x) {
                          return {
                                  hd: x,
                                  tl: /* [] */0
                                };
                        })),
                  match$4[1]
                ]) : Belt_List.concat(Survey$ReactHooksTemplate.questions(state.survey), Belt_Option.mapWithDefault(question$1, /* [] */0, (function (x) {
                        return {
                                hd: x,
                                tl: /* [] */0
                              };
                      })));
          var graph$p$1 = SurveyLogic$ReactHooksTemplate.addLogicToEdges(questions$1, SurveyLogic$ReactHooksTemplate.changeEdges(Survey$ReactHooksTemplate.name(state.survey), questions$1, Survey$ReactHooksTemplate.thankYouList(state.survey), addQuestionsToGraph(questions$1)));
          var addedQuestionEl$1 = List.nth(questions$1, index);
          var newrecord$18 = Caml_obj.caml_obj_dup(state);
          newrecord$18.graph = graph$p$1;
          newrecord$18.builderPreview = {
            TAG: /* QuestionPage */1,
            _0: questionBuilderTemplates,
            _1: addedQuestionEl$1,
            _2: CustomerInfo$ReactHooksTemplate.empty(undefined)
          };
          newrecord$18.selectedId = Question$ReactHooksTemplate.uuid(addedQuestionEl$1);
          newrecord$18.selected = selected$1;
          newrecord$18.survey = Survey$ReactHooksTemplate.setQuestions(state.survey, questions$1);
          return newrecord$18;
      case /* RemoveThankYouEditorBlock */4 :
          var logics = action._2;
          var thankYou$2 = action._1;
          var index$1 = action._0;
          var match$5 = ThankYou$ReactHooksTemplate.id(thankYou$2);
          var deletedThankYouList = match$5 !== undefined ? Belt_List.concat(Survey$ReactHooksTemplate.deletedThankYouList(state.survey), {
                  hd: thankYou$2,
                  tl: /* [] */0
                }) : Survey$ReactHooksTemplate.deletedThankYouList(state.survey);
          var logics$p = Belt_List.keep(Survey$ReactHooksTemplate.logics(state.survey), (function (x) {
                  return !List.exists((function (y) {
                                return Caml_obj.caml_equal(y, x);
                              }), logics);
                }));
          var deletedLogics = Belt_List.concat(Survey$ReactHooksTemplate.deletedLogics(state.survey), logics);
          var x$2 = Belt_List.get(Survey$ReactHooksTemplate.thankYouList(state.survey), index$1 + 1 | 0);
          var nextQuestion$1;
          if (x$2 !== undefined) {
            nextQuestion$1 = {
              TAG: /* ThankYou */1,
              _0: Caml_option.valFromOption(x$2)
            };
          } else {
            var x$3 = Belt_List.get(Survey$ReactHooksTemplate.thankYouList(state.survey), index$1 - 1 | 0);
            if (x$3 !== undefined) {
              nextQuestion$1 = {
                TAG: /* ThankYou */1,
                _0: Caml_option.valFromOption(x$3)
              };
            } else {
              var length = Belt_List.length(Survey$ReactHooksTemplate.questions(state.survey));
              var match$6 = Survey$ReactHooksTemplate.questions(state.survey);
              if (match$6) {
                var x$4 = Belt_List.get(Survey$ReactHooksTemplate.questions(state.survey), length - 1 | 0);
                if (x$4 !== undefined) {
                  nextQuestion$1 = {
                    TAG: /* Question */2,
                    _0: x$4
                  };
                } else {
                  var x$5 = Survey$ReactHooksTemplate.welcome(state.survey);
                  nextQuestion$1 = x$5 !== undefined ? ({
                        TAG: /* Welcome */0,
                        _0: Caml_option.valFromOption(x$5)
                      }) : /* Empty */0;
                }
              } else {
                var x$6 = Survey$ReactHooksTemplate.welcome(state.survey);
                nextQuestion$1 = x$6 !== undefined ? ({
                      TAG: /* Welcome */0,
                      _0: Caml_option.valFromOption(x$6)
                    }) : /* Empty */0;
              }
            }
          }
          if (typeof nextQuestion$1 === "number") {
            var newrecord$19 = Caml_obj.caml_obj_dup(state);
            newrecord$19.builderPreview = /* Empty */0;
            newrecord$19.selectedId = RandomId$ReactHooksTemplate.generate(undefined);
            newrecord$19.selected = Welcome$ReactHooksTemplate.categoryType;
            newrecord$19.survey = Survey$ReactHooksTemplate.setLogics(Survey$ReactHooksTemplate.setDeletedLogics(Survey$ReactHooksTemplate.setDeletedThankYouList(Survey$ReactHooksTemplate.setThankYouList(state.survey, Belt_List.keep(Survey$ReactHooksTemplate.thankYouList(state.survey), (function (x) {
                                    return Caml_obj.caml_notequal(ThankYou$ReactHooksTemplate.uuid(x), ThankYou$ReactHooksTemplate.uuid(thankYou$2));
                                  }))), deletedThankYouList), deletedLogics), logics$p);
            return newrecord$19;
          }
          switch (nextQuestion$1.TAG | 0) {
            case /* Welcome */0 :
                var newrecord$20 = Caml_obj.caml_obj_dup(state);
                newrecord$20.selected = Welcome$ReactHooksTemplate.categoryType;
                newrecord$20.survey = Survey$ReactHooksTemplate.setLogics(Survey$ReactHooksTemplate.setDeletedLogics(Survey$ReactHooksTemplate.setDeletedThankYouList(Survey$ReactHooksTemplate.setThankYouList(state.survey, Belt_List.keep(Survey$ReactHooksTemplate.thankYouList(state.survey), (function (x) {
                                        return Caml_obj.caml_notequal(ThankYou$ReactHooksTemplate.uuid(x), ThankYou$ReactHooksTemplate.uuid(thankYou$2));
                                      }))), deletedThankYouList), deletedLogics), logics$p);
                return newrecord$20;
            case /* ThankYou */1 :
                var x$7 = nextQuestion$1._0;
                var newrecord$21 = Caml_obj.caml_obj_dup(state);
                newrecord$21.builderPreview = {
                  TAG: /* ThankYou */2,
                  _0: Caml_option.some(x$7)
                };
                newrecord$21.selectedId = ThankYou$ReactHooksTemplate.uuid(x$7);
                newrecord$21.selected = ThankYou$ReactHooksTemplate.categoryType;
                newrecord$21.survey = Survey$ReactHooksTemplate.setLogics(Survey$ReactHooksTemplate.setDeletedLogics(Survey$ReactHooksTemplate.setDeletedThankYouList(Survey$ReactHooksTemplate.setThankYouList(state.survey, Belt_List.keep(Survey$ReactHooksTemplate.thankYouList(state.survey), (function (x) {
                                        return Caml_obj.caml_notequal(ThankYou$ReactHooksTemplate.uuid(x), ThankYou$ReactHooksTemplate.uuid(thankYou$2));
                                      }))), deletedThankYouList), deletedLogics), logics$p);
                return newrecord$21;
            case /* Question */2 :
                var x$8 = nextQuestion$1._0;
                var newrecord$22 = Caml_obj.caml_obj_dup(state);
                newrecord$22.builderPreview = {
                  TAG: /* QuestionPage */1,
                  _0: questionBuilderTemplates,
                  _1: x$8,
                  _2: CustomerInfo$ReactHooksTemplate.empty(undefined)
                };
                newrecord$22.selectedId = Question$ReactHooksTemplate.uuid(x$8);
                newrecord$22.selected = Question$ReactHooksTemplate.categoryType(x$8);
                newrecord$22.survey = Survey$ReactHooksTemplate.setLogics(Survey$ReactHooksTemplate.setDeletedLogics(Survey$ReactHooksTemplate.setDeletedThankYouList(Survey$ReactHooksTemplate.setThankYouList(state.survey, Belt_List.keep(Survey$ReactHooksTemplate.thankYouList(state.survey), (function (x) {
                                        return Caml_obj.caml_notequal(ThankYou$ReactHooksTemplate.uuid(x), ThankYou$ReactHooksTemplate.uuid(thankYou$2));
                                      }))), deletedThankYouList), deletedLogics), logics$p);
                return newrecord$22;
            
          }
      case /* RemoveQuestionEditorBlock */5 :
          var logics$1 = action._2;
          var question$2 = action._1;
          var index$2 = action._0;
          var deletedLogics$1 = Belt_List.concat(Survey$ReactHooksTemplate.deletedLogics(state.survey), logics$1);
          var logics$p$1 = List.length(logics$1) !== 0 ? Belt_List.keep(Survey$ReactHooksTemplate.logics(state.survey), (function (x) {
                    return !List.exists((function (y) {
                                  return Caml_obj.caml_equal(y, x);
                                }), logics$1);
                  })) : Survey$ReactHooksTemplate.logics(state.survey);
          var match$7 = Question$ReactHooksTemplate.id(question$2);
          var deletedQuestions = match$7 !== undefined ? Belt_List.concat(Survey$ReactHooksTemplate.deletedQuestions(state.survey), {
                  hd: question$2,
                  tl: /* [] */0
                }) : Survey$ReactHooksTemplate.deletedQuestions(state.survey);
          var questions$p = Belt_List.keep(Survey$ReactHooksTemplate.questions(state.survey), (function (x) {
                  return Caml_obj.caml_notequal(Question$ReactHooksTemplate.uuid(x), Question$ReactHooksTemplate.uuid(question$2));
                }));
          var graph$p$2 = SurveyLogic$ReactHooksTemplate.addLogicToEdges(questions$p, SurveyLogic$ReactHooksTemplate.changeEdges(Survey$ReactHooksTemplate.name(state.survey), questions$p, Survey$ReactHooksTemplate.thankYouList(state.survey), addQuestionsToGraph(questions$p)));
          var x$9 = Belt_List.get(Survey$ReactHooksTemplate.questions(state.survey), index$2 + 1 | 0);
          var nextQuestion$2;
          if (x$9 !== undefined) {
            nextQuestion$2 = {
              TAG: /* Question */2,
              _0: x$9
            };
          } else {
            var match$8 = Survey$ReactHooksTemplate.thankYouList(state.survey);
            if (match$8) {
              nextQuestion$2 = {
                TAG: /* ThankYou */1,
                _0: match$8.hd
              };
            } else {
              var x$10 = Belt_List.get(Survey$ReactHooksTemplate.questions(state.survey), index$2 - 1 | 0);
              if (x$10 !== undefined) {
                nextQuestion$2 = {
                  TAG: /* Question */2,
                  _0: x$10
                };
              } else {
                var x$11 = Survey$ReactHooksTemplate.welcome(state.survey);
                nextQuestion$2 = x$11 !== undefined ? ({
                      TAG: /* Welcome */0,
                      _0: Caml_option.valFromOption(x$11)
                    }) : /* Empty */0;
              }
            }
          }
          if (typeof nextQuestion$2 === "number") {
            var newrecord$23 = Caml_obj.caml_obj_dup(state);
            newrecord$23.graph = graph$p$2;
            newrecord$23.builderPreview = /* Empty */0;
            newrecord$23.selectedId = RandomId$ReactHooksTemplate.generate(undefined);
            newrecord$23.selected = Welcome$ReactHooksTemplate.categoryType;
            newrecord$23.survey = Survey$ReactHooksTemplate.setDeletedLogics(Survey$ReactHooksTemplate.setLogics(Survey$ReactHooksTemplate.setDeletedQuestions(Survey$ReactHooksTemplate.setQuestions(state.survey, questions$p), deletedQuestions), logics$p$1), deletedLogics$1);
            return newrecord$23;
          }
          switch (nextQuestion$2.TAG | 0) {
            case /* Welcome */0 :
                var newrecord$24 = Caml_obj.caml_obj_dup(state);
                newrecord$24.graph = graph$p$2;
                newrecord$24.selected = Welcome$ReactHooksTemplate.categoryType;
                newrecord$24.survey = Survey$ReactHooksTemplate.setDeletedLogics(Survey$ReactHooksTemplate.setLogics(Survey$ReactHooksTemplate.setDeletedQuestions(Survey$ReactHooksTemplate.setQuestions(state.survey, questions$p), deletedQuestions), logics$p$1), deletedLogics$1);
                return newrecord$24;
            case /* ThankYou */1 :
                var x$12 = nextQuestion$2._0;
                var newrecord$25 = Caml_obj.caml_obj_dup(state);
                newrecord$25.graph = graph$p$2;
                newrecord$25.builderPreview = {
                  TAG: /* ThankYou */2,
                  _0: Caml_option.some(x$12)
                };
                newrecord$25.selectedId = ThankYou$ReactHooksTemplate.uuid(x$12);
                newrecord$25.selected = ThankYou$ReactHooksTemplate.categoryType;
                newrecord$25.survey = Survey$ReactHooksTemplate.setDeletedLogics(Survey$ReactHooksTemplate.setLogics(Survey$ReactHooksTemplate.setDeletedQuestions(Survey$ReactHooksTemplate.setQuestions(state.survey, questions$p), deletedQuestions), logics$p$1), deletedLogics$1);
                return newrecord$25;
            case /* Question */2 :
                var x$13 = nextQuestion$2._0;
                var newrecord$26 = Caml_obj.caml_obj_dup(state);
                newrecord$26.graph = graph$p$2;
                newrecord$26.builderPreview = {
                  TAG: /* QuestionPage */1,
                  _0: questionBuilderTemplates,
                  _1: x$13,
                  _2: CustomerInfo$ReactHooksTemplate.empty(undefined)
                };
                newrecord$26.selectedId = Question$ReactHooksTemplate.uuid(x$13);
                newrecord$26.selected = Question$ReactHooksTemplate.categoryType(x$13);
                newrecord$26.survey = Survey$ReactHooksTemplate.setDeletedLogics(Survey$ReactHooksTemplate.setLogics(Survey$ReactHooksTemplate.setDeletedQuestions(Survey$ReactHooksTemplate.setQuestions(state.survey, questions$p), deletedQuestions), logics$p$1), deletedLogics$1);
                return newrecord$26;
            
          }
      case /* SetSelected */6 :
          var newrecord$27 = Caml_obj.caml_obj_dup(state);
          newrecord$27.selected = action._0;
          return newrecord$27;
      case /* SetSelectedId */7 :
          var newrecord$28 = Caml_obj.caml_obj_dup(state);
          newrecord$28.selectedId = action._0;
          return newrecord$28;
      case /* UpdateWelcome */8 :
          var welcome$3 = action._0;
          var newrecord$29 = Caml_obj.caml_obj_dup(state);
          newrecord$29.builderPreview = {
            TAG: /* Welcome */0,
            _0: Caml_option.some(welcome$3)
          };
          newrecord$29.survey = Survey$ReactHooksTemplate.setWelcome(state.survey, Caml_option.some(welcome$3));
          return newrecord$29;
      case /* UpdateThankYou */9 :
          var thankYou$3 = action._0;
          var thankYouList$p = Belt_List.map(Survey$ReactHooksTemplate.thankYouList(state.survey), (function (x) {
                  if (Caml_obj.caml_equal(ThankYou$ReactHooksTemplate.uuid(x), ThankYou$ReactHooksTemplate.uuid(thankYou$3))) {
                    return thankYou$3;
                  } else {
                    return x;
                  }
                }));
          var newrecord$30 = Caml_obj.caml_obj_dup(state);
          newrecord$30.builderPreview = {
            TAG: /* ThankYou */2,
            _0: Caml_option.some(thankYou$3)
          };
          newrecord$30.survey = Survey$ReactHooksTemplate.setThankYouList(state.survey, thankYouList$p);
          return newrecord$30;
      case /* UpdateQuestion */10 :
          var question$3 = action._0;
          var questions$p$1 = Belt_List.map(Survey$ReactHooksTemplate.questions(state.survey), (function (x) {
                  if (Caml_obj.caml_equal(Question$ReactHooksTemplate.uuid(x), Question$ReactHooksTemplate.uuid(question$3))) {
                    return question$3;
                  } else {
                    return x;
                  }
                }));
          var graph$p$3 = SurveyLogic$ReactHooksTemplate.addLogicToEdges(questions$p$1, SurveyLogic$ReactHooksTemplate.changeEdges(Survey$ReactHooksTemplate.name(state.survey), questions$p$1, Survey$ReactHooksTemplate.thankYouList(state.survey), addQuestionsToGraph(questions$p$1)));
          var newrecord$31 = Caml_obj.caml_obj_dup(state);
          newrecord$31.graph = graph$p$3;
          newrecord$31.builderPreview = {
            TAG: /* QuestionPage */1,
            _0: questionBuilderTemplates,
            _1: question$3,
            _2: CustomerInfo$ReactHooksTemplate.empty(undefined)
          };
          newrecord$31.survey = Survey$ReactHooksTemplate.setQuestions(state.survey, questions$p$1);
          return newrecord$31;
      case /* PreviewQuestion */11 :
          var newrecord$32 = Caml_obj.caml_obj_dup(state);
          newrecord$32.builderPreview = {
            TAG: /* QuestionPage */1,
            _0: questionBuilderTemplates,
            _1: action._0,
            _2: CustomerInfo$ReactHooksTemplate.empty(undefined)
          };
          return newrecord$32;
      case /* PreviewThankYou */12 :
          var newrecord$33 = Caml_obj.caml_obj_dup(state);
          newrecord$33.builderPreview = {
            TAG: /* ThankYou */2,
            _0: Caml_option.some(action._0)
          };
          return newrecord$33;
      case /* ShowWelcomeThankYou */13 :
          var newrecord$34 = Caml_obj.caml_obj_dup(state);
          newrecord$34.showThankyou = action._1;
          newrecord$34.showWelcome = action._0;
          return newrecord$34;
      case /* AddLogo */14 :
          var newrecord$35 = Caml_obj.caml_obj_dup(state);
          newrecord$35.survey = Survey$ReactHooksTemplate.setLogo(state.survey, action._0);
          return newrecord$35;
      case /* ReplaceLogic */15 :
          var newrecord$36 = Caml_obj.caml_obj_dup(state);
          newrecord$36.logicalJumpGroup = LogicalJump$ReactHooksTemplate.replaceInGroup(action._0, state.logicalJumpGroup);
          return newrecord$36;
      case /* ChangeLanguage */16 :
          var newrecord$37 = Caml_obj.caml_obj_dup(state);
          newrecord$37.language = action._0;
          return newrecord$37;
      case /* AddNotification */17 :
          var newrecord$38 = Caml_obj.caml_obj_dup(state);
          newrecord$38.notification = action._0;
          return newrecord$38;
      case /* SetOutsideClick */18 :
          var newrecord$39 = Caml_obj.caml_obj_dup(state);
          newrecord$39.outsideClick = action._0;
          return newrecord$39;
      case /* SetAddQuestionBelow */19 :
          var newrecord$40 = Caml_obj.caml_obj_dup(state);
          newrecord$40.addQuestionBelow = action._0;
          return newrecord$40;
      case /* SetAddThankYouToTop */20 :
          var newrecord$41 = Caml_obj.caml_obj_dup(state);
          newrecord$41.addThankYouToTop = action._0;
          return newrecord$41;
      case /* SetToBeAddedBelowIndex */21 :
          var newrecord$42 = Caml_obj.caml_obj_dup(state);
          newrecord$42.toBeAddedBelowIndex = action._0;
          return newrecord$42;
      case /* SetSelectedEditor */22 :
          var newrecord$43 = Caml_obj.caml_obj_dup(state);
          newrecord$43.selectedEditor = action._0;
          return newrecord$43;
      case /* SetPopUp */23 :
          var newrecord$44 = Caml_obj.caml_obj_dup(state);
          newrecord$44.popUp = action._0;
          return newrecord$44;
      
    }
  }
}

function SurveyForm(Props) {
  var survey = Props.survey;
  var projectId = Props.projectId;
  var flagEnabled = Props.flagEnabled;
  var initialState = survey !== undefined ? ({
        survey: survey,
        selected: Welcome$ReactHooksTemplate.categoryType,
        selectedId: RandomId$ReactHooksTemplate.generate(undefined),
        nameInvalid: Survey$ReactHooksTemplate.name(survey).length === 0,
        showPreview: false,
        showSurveyNameInput: false,
        showAddQuestions: false,
        logicalJumpGroup: LogicalJump$ReactHooksTemplate.makeGroup(undefined),
        builderPreview: {
          TAG: /* Welcome */0,
          _0: Survey$ReactHooksTemplate.welcome(survey)
        },
        graph: SurveyLogic$ReactHooksTemplate.addLogicToEdges(Survey$ReactHooksTemplate.questions(survey), SurveyLogic$ReactHooksTemplate.changeEdges(Survey$ReactHooksTemplate.name(survey), Survey$ReactHooksTemplate.questions(survey), Survey$ReactHooksTemplate.thankYouList(survey), addQuestionsToGraph(Survey$ReactHooksTemplate.questions(survey)))),
        client: Organization$ReactHooksTemplate.empty(undefined),
        language: SurveyLanguage$ReactHooksTemplate.$$default,
        surveyLanguages: /* [] */0,
        notification: undefined,
        showConfigurations: false,
        outsideClick: true,
        showWelcome: true,
        showThankyou: true,
        addQuestionBelow: false,
        toBeAddedBelowIndex: 0,
        addThankYouToTop: false,
        selectedEditor: /* QuestionsEditor */0,
        popUp: undefined
      }) : ({
        survey: Survey$ReactHooksTemplate.empty(undefined),
        selected: Welcome$ReactHooksTemplate.categoryType,
        selectedId: RandomId$ReactHooksTemplate.generate(undefined),
        nameInvalid: false,
        showPreview: false,
        showSurveyNameInput: false,
        showAddQuestions: false,
        logicalJumpGroup: LogicalJump$ReactHooksTemplate.makeGroup(undefined),
        builderPreview: /* Empty */0,
        graph: LogicGraph$ReactHooksTemplate.empty(undefined),
        client: Organization$ReactHooksTemplate.empty(undefined),
        language: SurveyLanguage$ReactHooksTemplate.$$default,
        surveyLanguages: /* [] */0,
        notification: undefined,
        showConfigurations: false,
        outsideClick: true,
        showWelcome: true,
        showThankyou: true,
        addQuestionBelow: false,
        toBeAddedBelowIndex: 0,
        addThankYouToTop: false,
        selectedEditor: /* QuestionsEditor */0,
        popUp: undefined
      });
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return /* Loading */0;
      });
  var setMode = match$1[1];
  var match$2 = React.useState(function () {
        return initialAccordionState;
      });
  var setAccordionState = match$2[1];
  var accordionState = match$2[0];
  var match$3 = React.useState(function () {
        return true;
      });
  var setViewQuestion = match$3[1];
  var viewQuestion = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setViewLogic = match$4[1];
  var viewLogic = match$4[0];
  var saveForm = function (survey) {
    var params = {};
    params["survey"] = Survey$ReactHooksTemplate.toJson(survey);
    var id = Survey$ReactHooksTemplate.id(survey);
    var tmp;
    if (id !== undefined) {
      var surveyId = String(id);
      tmp = Http$ReactHooksTemplate.fetchPutJSON("/api/v1/surveys/" + surveyId, params);
    } else {
      tmp = Http$ReactHooksTemplate.fetchPostJSON("/api/v1/surveys", params);
    }
    return tmp.then(function (response) {
                var status = Json_decode.field("status", (function (param) {
                        return Json_decode.withDefault("failed", Json_decode.string, param);
                      }), response);
                if (status === "success") {
                  console.log(status);
                  Curry._1(dispatch, {
                        TAG: /* UpdateSurvey */0,
                        _0: Json_decode.field("survey", Survey$ReactHooksTemplate.fromJson, response)
                      });
                  if (state.selectedEditor === /* QuestionsEditor */0) {
                    Curry._1(dispatch, {
                          TAG: /* AddNotification */17,
                          _0: {
                            TAG: /* Success */1,
                            _0: "Updated the survey"
                          }
                        });
                  } else {
                    Curry._1(dispatch, {
                          TAG: /* AddNotification */17,
                          _0: {
                            TAG: /* Success */1,
                            _0: "Updated the Logic"
                          }
                        });
                  }
                } else {
                  Curry._1(dispatch, {
                        TAG: /* AddNotification */17,
                        _0: {
                          TAG: /* Failure */2,
                          _0: "Something went wrong. Please contact admin"
                        }
                      });
                }
                return Promise.resolve(undefined);
              });
  };
  React.useEffect((function () {
          Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/organization_profiles/0", (function (param) {
                    return Json_decode.field("organization_profile", Organization$ReactHooksTemplate.decode, param);
                  })).then(function (organization) {
                Curry._1(dispatch, {
                      TAG: /* SetClient */1,
                      _0: organization
                    });
                return Promise.resolve(Curry._1(setMode, (function (param) {
                                  return /* Ready */1;
                                })));
              });
          
        }), []);
  var handleSaveForm = function ($$event) {
    $$event.preventDefault();
    if (Survey$ReactHooksTemplate.name(state.survey).length === 0) {
      return Curry._1(dispatch, /* InvalidName */6);
    } else {
      Curry._1(setMode, (function (param) {
              return /* Saving */2;
            }));
      saveForm(state.survey).then(function (param) {
            return Promise.resolve(Curry._1(setMode, (function (param) {
                              return /* Ready */1;
                            })));
          });
      return ;
    }
  };
  var updateLogicInSurvey = function (survey) {
    Curry._1(dispatch, {
          TAG: /* SetPopUp */23,
          _0: /* SaveLogic */1
        });
    return Curry._1(dispatch, {
                TAG: /* UpdateSurvey */0,
                _0: survey
              });
  };
  var toggleEditor = function (param) {
    var match = state.selectedEditor;
    if (match) {
      return Curry._1(dispatch, {
                  TAG: /* SetSelectedEditor */22,
                  _0: /* QuestionsEditor */0
                });
    } else {
      return Curry._1(dispatch, {
                  TAG: /* SetSelectedEditor */22,
                  _0: /* LogicEditor */1
                });
    }
  };
  var saveSurveyPopUp = function (message, saveButtonText, handleClose, handleSave) {
    return React.createElement("div", {
                className: "w-screen z-10 disabled-wrapper absolute inset-0 flex justify-center items-center",
                style: {
                  height: "calc(100vh + 5rem)"
                }
              }, React.createElement("div", {
                    className: "bg-white z-10 shadow relative border border-gray-300 rounded-lg h-200-px w-5/12"
                  }, React.createElement("div", {
                        className: "p-8 text-lg font-bold flex justify-center items-center"
                      }, React.createElement("div", undefined, message)), React.createElement("div", {
                        className: "p-8 flex gap-3 absolute bottom-0 w-full justify-end"
                      }, React.createElement("div", {
                            className: "bg-ghostwhite border border-gray-400 cursor-pointer\n                  flex justify-center items-center rounded p-2 text-sm hover:border-pigmentblue w-3/12",
                            onClick: (function (param) {
                                return Curry._1(handleClose, undefined);
                              })
                          }, React.createElement("div", {
                                className: "text-darkcharcoal hover:text-pigmentblue"
                              }, React.createElement("p", undefined, "Cancel"))), React.createElement("div", {
                            className: "bg-pigmentblue border border-gray-400 cursor-pointer flex justify-center items-center rounded p-2 text-sm hover:bg-cetaceanblue w-3/12",
                            onClick: Curry.__1(handleSave)
                          }, React.createElement("div", {
                                className: "text-white"
                              }, React.createElement("p", undefined, saveButtonText))))));
  };
  var handleThankYouDeletion = function (index, thankYou) {
    var logics = Logic$ReactHooksTemplate.getThankYouAssociatedLogic(thankYou, Survey$ReactHooksTemplate.logics(state.survey));
    if (List.length(logics) !== 0) {
      return Curry._1(dispatch, {
                  TAG: /* SetPopUp */23,
                  _0: {
                    TAG: /* DeleteThankYou */1,
                    _0: index,
                    _1: thankYou,
                    _2: logics
                  }
                });
    } else {
      return Curry._1(dispatch, {
                  TAG: /* RemoveThankYouEditorBlock */4,
                  _0: index,
                  _1: thankYou,
                  _2: logics
                });
    }
  };
  var handleQuestionDeletion = function (index, question) {
    var logics = Logic$ReactHooksTemplate.questionInLogic(Survey$ReactHooksTemplate.logics(state.survey), question);
    if (List.length(logics) !== 0) {
      return Curry._1(dispatch, {
                  TAG: /* SetPopUp */23,
                  _0: {
                    TAG: /* DeleteQuestion */0,
                    _0: index,
                    _1: question,
                    _2: logics
                  }
                });
    } else {
      Curry._1(dispatch, {
            TAG: /* RemoveQuestionEditorBlock */5,
            _0: index,
            _1: question,
            _2: logics
          });
      return Curry._1(dispatch, {
                  TAG: /* SetPopUp */23,
                  _0: /* SaveQuestion */0
                });
    }
  };
  var surveyBuilderPreview = function (param) {
    var match = state.builderPreview;
    var page;
    page = typeof match === "number" || !(match.TAG === /* QuestionPage */1 && match._1 === undefined) ? state.builderPreview : ({
          TAG: /* ThankYou */2,
          _0: Belt_List.head(Survey$ReactHooksTemplate.thankYouList(state.survey))
        });
    return React.createElement(React.Fragment, undefined, React.createElement(SurveyBuilderPreview$ReactHooksTemplate.make, {
                    page: page,
                    theme: Survey$ReactHooksTemplate.surveyTheme(state.survey),
                    client: state.client,
                    handleQuestionPreview: (function (y) {
                        return Curry._1(dispatch, {
                                    TAG: /* UpdateQuestion */10,
                                    _0: y
                                  });
                      }),
                    onWelcomeChange: (function (welcome) {
                        return Curry._1(dispatch, {
                                    TAG: /* UpdateWelcome */8,
                                    _0: welcome
                                  });
                      }),
                    onThankYouChange: (function (thankYou) {
                        return Curry._1(dispatch, {
                                    TAG: /* UpdateThankYou */9,
                                    _0: thankYou
                                  });
                      }),
                    handleFormOnSubmit: (function (param) {
                        
                      }),
                    languageSelected: state.language
                  }));
  };
  var handleQuestionPageButtonClick = function (questionOrPage) {
    var match = questionsOrPageToString(questionOrPage);
    if (match === "thank_you") {
      if (state.addQuestionBelow) {
        Curry._1(dispatch, {
              TAG: /* AddEditorBlockInBetween */3,
              _0: state.addThankYouToTop ? 0 : state.toBeAddedBelowIndex,
              _1: questionOrPage
            });
      } else {
        Curry._1(dispatch, {
              TAG: /* AddEditorBlock */2,
              _0: questionOrPage
            });
      }
    } else if (state.addQuestionBelow) {
      Curry._1(dispatch, {
            TAG: /* AddEditorBlockInBetween */3,
            _0: state.toBeAddedBelowIndex,
            _1: questionOrPage
          });
    } else {
      Curry._1(dispatch, {
            TAG: /* AddEditorBlock */2,
            _0: questionOrPage
          });
    }
    Curry._1(dispatch, /* ToggleAddQuestion */3);
    return Curry._1(dispatch, {
                TAG: /* SetPopUp */23,
                _0: /* SaveQuestion */0
              });
  };
  var welcomeEditorBlock = Belt_Option.mapWithDefault(Survey$ReactHooksTemplate.welcome(state.survey), null, (function (welcome) {
          return React.createElement("div", {
                      className: "p-6",
                      onFocus: (function (param) {
                          return Curry._1(dispatch, /* PreviewWelcome */1);
                        })
                    }, React.createElement("div", undefined, React.createElement(WelcomeEditor$ReactHooksTemplate.make, {
                              welcome: welcome,
                              onWelcomeChange: (function (welcome) {
                                  return Curry._1(dispatch, {
                                              TAG: /* UpdateWelcome */8,
                                              _0: welcome
                                            });
                                })
                            })));
        }));
  var welcomeListBlock = Belt_Option.mapWithDefault(Survey$ReactHooksTemplate.welcome(state.survey), null, (function (welcome) {
          return React.createElement("div", {
                      className: "p-3 border-b border-gray-400 mx-4 my-12-px flex rounded border bg-gray-100\n           hover:bg-alicebluesolid h-56-px justify-between cursor-pointer " + (
                        state.selected === Welcome$ReactHooksTemplate.categoryType ? "bg-lavenderweb" : ""
                      ),
                      onClick: (function (param) {
                          Curry._1(dispatch, {
                                TAG: /* SetSelected */6,
                                _0: Welcome$ReactHooksTemplate.categoryType
                              });
                          return Curry._1(dispatch, /* PreviewWelcome */1);
                        })
                    }, React.createElement("div", {
                          className: "flex items-center"
                        }, React.createElement("div", {
                              className: "flex items-center text-sm text-darkcharcoal font-normal"
                            }, React.createElement("img", {
                                  className: "mr-2 cusor-default",
                                  src: Image$ReactHooksTemplate.dragHandle
                                }), React.createElement("div", {
                                  className: "mr-3 text-aurometalsaurus text-xs"
                                }, "1")), React.createElement("div", {
                              className: "flex flex-col items-center gap-1 justify-items-start"
                            }, Welcome$ReactHooksTemplate.title(welcome) === "" ? React.createElement(React.Fragment, undefined) : React.createElement("div", {
                                    className: "w-full"
                                  }, React.createElement("div", {
                                        className: "truncate max-w-110-px float-left text-darkcharcoal text-sm font-normal ml-px"
                                      }, Welcome$ReactHooksTemplate.title(welcome))), React.createElement("div", {
                                  className: "w-full"
                                }, React.createElement("div", {
                                      className: "bg-magnolia px-2 py-1 text-10-px font-light text-darkcharcoal rounded float-left"
                                    }, "Welcome")))), React.createElement("div", {
                          className: "flex justify-end items-center relative"
                        }, React.createElement("img", {
                              className: "h-18-px",
                              src: Image$ReactHooksTemplate.verticalDots,
                              onClick: (function (param) {
                                  return Curry._1(dispatch, {
                                              TAG: /* SetOutsideClick */18,
                                              _0: false
                                            });
                                })
                            }), React.createElement("div", {
                              className: "flex flex-wrap items-search p-2 absolute rounded border border-gray-400 bg-white\n                          text-darkcharcoal text-xs question-dropdown z-50 opacity-100 w-40 gap-2 " + (
                                state.selected === Welcome$ReactHooksTemplate.categoryType && state.outsideClick === false ? "block" : "hidden"
                              )
                            }, React.createElement("a", {
                                  className: "flex text-xs gap-2 cursor-pointer"
                                }, React.createElement("img", {
                                      className: "h-12-px",
                                      src: Image$ReactHooksTemplate.plusCircle
                                    }), "Duplicate"), React.createElement("a", {
                                  className: "flex text-xs gap-2 cursor-pointer",
                                  onClick: (function (param) {
                                      Curry._1(dispatch, {
                                            TAG: /* SetOutsideClick */18,
                                            _0: true
                                          });
                                      Curry._1(dispatch, {
                                            TAG: /* ShowWelcomeThankYou */13,
                                            _0: false,
                                            _1: Belt_List.length(Survey$ReactHooksTemplate.questions(state.survey)) === 0
                                          });
                                      Curry._1(dispatch, {
                                            TAG: /* SetAddQuestionBelow */19,
                                            _0: true
                                          });
                                      Curry._1(dispatch, {
                                            TAG: /* SetToBeAddedBelowIndex */21,
                                            _0: 0
                                          });
                                      Curry._1(dispatch, /* ToggleAddQuestion */3);
                                      return Curry._1(dispatch, {
                                                  TAG: /* SetPopUp */23,
                                                  _0: /* SaveQuestion */0
                                                });
                                    })
                                }, React.createElement("img", {
                                      className: "h-12-px",
                                      src: Image$ReactHooksTemplate.plusCircle
                                    }), "Add question below"), React.createElement("a", {
                                  className: "flex text-xs gap-2 cursor-pointer",
                                  href: "#",
                                  onClick: (function ($$event) {
                                      $$event.preventDefault();
                                      $$event.stopPropagation();
                                      Curry._1(dispatch, /* RemoveWelcomeEditorBlock */0);
                                      Curry._1(dispatch, {
                                            TAG: /* SetOutsideClick */18,
                                            _0: true
                                          });
                                      return Curry._1(dispatch, {
                                                  TAG: /* SetPopUp */23,
                                                  _0: /* SaveQuestion */0
                                                });
                                    })
                                }, React.createElement("img", {
                                      className: "h-12-px",
                                      src: Image$ReactHooksTemplate.trash
                                    }), "Delete"))));
        }));
  var thankYouEditorBlock = function (thankYou) {
    var id = ThankYou$ReactHooksTemplate.uuid(thankYou);
    return React.createElement("div", {
                key: RandomId$ReactHooksTemplate.toString(id),
                className: "p-6",
                onFocus: (function (param) {
                    return Curry._1(dispatch, {
                                TAG: /* PreviewThankYou */12,
                                _0: thankYou
                              });
                  })
              }, React.createElement(ThankYouEditor$ReactHooksTemplate.make, {}));
  };
  var questionFormItem = function (x) {
    var id = Question$ReactHooksTemplate.uuid(x);
    return React.createElement("div", {
                key: RandomId$ReactHooksTemplate.toString(id),
                className: "p-6",
                onFocus: (function (param) {
                    return Curry._1(dispatch, {
                                TAG: /* PreviewQuestion */11,
                                _0: x
                              });
                  })
              }, Question$ReactHooksTemplate.toEditorBlock((function (x) {
                      return Curry._1(dispatch, {
                                  TAG: /* UpdateQuestion */10,
                                  _0: x
                                });
                    }), x));
  };
  var questionsModal = function (showWelcome, showThankYou) {
    return React.createElement("div", {
                className: "flex flex-wrap items-stretch shadow-md border absolute z-50 w-640-px bg-white\n          opacity-100 gap-2 rounded  " + (
                  state.showAddQuestions ? "block" : "hidden"
                )
              }, React.createElement("div", {
                    className: "p-5 w-full flex border-b border-gray-300 justify-between"
                  }, React.createElement("p", {
                        className: "text-base"
                      }, "Choose a question"), React.createElement("img", {
                        className: "h-3 cursor-pointer",
                        src: Image$ReactHooksTemplate.closeIcon,
                        onClick: (function (param) {
                            return Curry._1(dispatch, /* ToggleAddQuestion */3);
                          })
                      })), React.createElement("div", {
                    className: "relative w-full px-5"
                  }, React.createElement("img", {
                        className: "absolute search-icon-questions cursor-pointer",
                        src: Image$ReactHooksTemplate.search
                      }), React.createElement("input", {
                        className: "h-32-px py-2 px-8 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs w-full",
                        placeholder: "Search Question Types",
                        type: "text"
                      })), React.createElement("div", undefined, React.createElement("div", {
                        className: "border-b border-gray-300 mx-5 pb-5"
                      }, React.createElement("div", {
                            className: "text-xs text-aurometalsaurus py-6"
                          }, "Frequently used"), React.createElement("div", {
                            className: "flex flex-wrap justify-items-center"
                          }, React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center " + (
                                  showWelcome ? "" : "disabled-text"
                                ),
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* Welcome */0);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.welcomeThankyou
                                  }), "Welcome page"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* Nps */6);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.opinionScale
                                  }), "NPS"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* MultipleChoice */7);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.radioButtonChecked
                                  }), "Multiple Choice"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* ShortText */3);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.shortText
                                  }), "Short Text"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* LongText */2);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.longText
                                  }), "Long Text"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* StarRating */4);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.rating
                                  }), "Star Rating"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center " + (
                                  showThankYou ? "" : "disabled-text"
                                ),
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* ThankYou */1);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.welcomeThankyou
                                  }), "Thank you page"))), React.createElement("div", {
                        className: "mx-5 pb-5"
                      }, React.createElement("div", {
                            className: "text-xs text-aurometalsaurus py-6"
                          }, "Others"), React.createElement("div", {
                            className: "flex flex-wrap justify-items-center"
                          }, React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* RatingGroup */8);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.rating
                                  }), "Rating Group"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* OpinionScale */5);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.opinionScale
                                  }), "Opinion Scale"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* SmileyRating */9);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.rating
                                  }), "Smiley Rating"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* Csat */10);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.defaultQuestion
                                  }), "CSAT"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* MultipleSelect */11);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.radioButtonChecked
                                  }), "Multiple Select"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* DropDown */12);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.defaultQuestion
                                  }), "Drop Down"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* MobileNumber */13);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.shortText
                                  }), "MobileNumber")))));
  };
  var addQuestionButton = React.createElement("div", {
        className: "relative"
      }, React.createElement("div", {
            className: "flex rounded p-2 text-sm cursor-pointer",
            onClick: (function (param) {
                Curry._1(dispatch, {
                      TAG: /* SetAddQuestionBelow */19,
                      _0: false
                    });
                Curry._1(dispatch, {
                      TAG: /* SetAddThankYouToTop */20,
                      _0: false
                    });
                Curry._1(dispatch, /* ToggleAddQuestion */3);
                return Curry._1(setViewQuestion, (function (param) {
                              return true;
                            }));
              })
          }, React.createElement("div", undefined, React.createElement("img", {
                    src: Image$ReactHooksTemplate.pluslight
                  }))), questionsModal(state.showWelcome, state.showThankyou));
  var editor = React.createElement("div", undefined, React.createElement("div", {
            className: "text-darkcharcoal font-medium text-base flex items-center\n                   p-6 justify-between cursor-pointer",
            onClick: (function (param) {
                return Curry._1(setAccordionState, (function (accordionState) {
                              return {
                                      questionSettingsTab: !accordionState.questionSettingsTab
                                    };
                            }));
              })
          }, React.createElement("div", {
                className: "flex gap-1"
              }, React.createElement("img", {
                    src: Image$ReactHooksTemplate.settings
                  }), "Question Settings"), accordionState.questionSettingsTab ? React.createElement("img", {
                  className: "h-7-px",
                  src: Image$ReactHooksTemplate.chevronDown
                }) : React.createElement("img", {
                  className: "h-7-px",
                  src: Image$ReactHooksTemplate.chevronUp
                })), accordionState.questionSettingsTab ? (
          state.selected === Welcome$ReactHooksTemplate.categoryType ? welcomeEditorBlock : (
              state.selected === ThankYou$ReactHooksTemplate.categoryType ? $$Array.of_list(List.map(thankYouEditorBlock, List.filter(function (x) {
                                return ThankYou$ReactHooksTemplate.uuid(x) === state.selectedId;
                              })(Survey$ReactHooksTemplate.thankYouList(state.survey)))) : $$Array.of_list(List.map(questionFormItem, List.filter(function (x) {
                                return Question$ReactHooksTemplate.uuid(x) === state.selectedId;
                              })(Survey$ReactHooksTemplate.questions(state.survey))))
            )
        ) : React.createElement(React.Fragment, undefined));
  var questionList = React.createElement("div", {
        className: "overflow-auto pb-12",
        style: Curry._1(flagEnabled, /* SurveyLogicNewImplementation */6) ? ({
              height: "calc(100vh - 15rem)"
            }) : ({
              height: "calc(100vh - 12rem)"
            })
      }, welcomeListBlock, React.createElement("div", {
            className: "list-decimal"
          }, $$Array.of_list(List.mapi((function (i) {
                      return function (param) {
                        var match = state.survey.welcome;
                        var slNo = match !== undefined ? i + 2 | 0 : i + 1 | 0;
                        var id = Question$ReactHooksTemplate.uuid(param);
                        return React.createElement("div", {
                                    key: RandomId$ReactHooksTemplate.toString(id),
                                    className: "p-2 border-b border-gray-400 mx-4 my-12-px cursor-pointer\n      flex justify-between rounded border bg-gray-100 hover:bg-alicebluesolid h-56-px " + (
                                      state.selected === Question$ReactHooksTemplate.categoryType(param) && state.selectedId === Question$ReactHooksTemplate.uuid(param) ? "bg-lavenderweb" : ""
                                    ),
                                    onClick: (function (param$1) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSelected */6,
                                              _0: Question$ReactHooksTemplate.categoryType(param)
                                            });
                                        Curry._1(dispatch, {
                                              TAG: /* SetSelectedId */7,
                                              _0: id
                                            });
                                        return Curry._1(dispatch, {
                                                    TAG: /* PreviewQuestion */11,
                                                    _0: param
                                                  });
                                      })
                                  }, React.createElement("div", {
                                        className: "flex items-center"
                                      }, React.createElement("div", {
                                            className: "flex items-center text-sm text-darkcharcoal font-normal"
                                          }, React.createElement("img", {
                                                className: "mr-2 cursor-default",
                                                src: Image$ReactHooksTemplate.dragHandle
                                              }), React.createElement("div", {
                                                className: "mr-3 text-aurometalsaurus text-xs"
                                              }, String(slNo))), React.createElement("div", {
                                            className: "flex flex-col items-center gap-1 justify-items-start"
                                          }, Question$ReactHooksTemplate.text(param) === "" ? React.createElement(React.Fragment, undefined) : React.createElement("div", {
                                                  className: "w-full"
                                                }, React.createElement("div", {
                                                      className: "truncate max-w-110-px float-left text-darkcharcoal text-sm font-normal ml-px"
                                                    }, Question$ReactHooksTemplate.text(param))), React.createElement("div", {
                                                className: "w-full"
                                              }, React.createElement("div", {
                                                    className: "bg-magnolia px-2 py-1 text-10-px font-light text-darkcharcoal rounded float-left"
                                                  }, Question$ReactHooksTemplate.categoryType(param))))), React.createElement("div", {
                                        className: "flex justify-end items-center relative cursor-pointer"
                                      }, React.createElement("img", {
                                            className: "h-18-px",
                                            src: Image$ReactHooksTemplate.verticalDots,
                                            onClick: (function (param) {
                                                return Curry._1(dispatch, {
                                                            TAG: /* SetOutsideClick */18,
                                                            _0: false
                                                          });
                                              })
                                          }), React.createElement("div", {
                                            className: "flex flex-wrap items-search p-2 absolute rounded border border-gray-400 bg-white\n                        text-darkcharcoal text-xs question-dropdown z-50 opacity-100 w-40 gap-2 " + (
                                              state.selected === Question$ReactHooksTemplate.categoryType(param) && state.selectedId === Question$ReactHooksTemplate.uuid(param) && state.outsideClick === false ? "block" : "hidden"
                                            )
                                          }, React.createElement("a", {
                                                className: "flex text-xs gap-2 cursor-pointer",
                                                href: "#"
                                              }, React.createElement("img", {
                                                    className: "h-12-px",
                                                    src: Image$ReactHooksTemplate.plusCircle
                                                  }), "Duplicate"), React.createElement("a", {
                                                className: "flex text-xs gap-2 cursor-pointer",
                                                onClick: (function (param) {
                                                    Curry._1(dispatch, {
                                                          TAG: /* SetOutsideClick */18,
                                                          _0: true
                                                        });
                                                    Curry._1(dispatch, {
                                                          TAG: /* ShowWelcomeThankYou */13,
                                                          _0: false,
                                                          _1: (i + 1 | 0) === Belt_List.length(Survey$ReactHooksTemplate.questions(state.survey))
                                                        });
                                                    Curry._1(dispatch, {
                                                          TAG: /* SetAddQuestionBelow */19,
                                                          _0: true
                                                        });
                                                    Curry._1(dispatch, {
                                                          TAG: /* SetAddThankYouToTop */20,
                                                          _0: (i + 1 | 0) === Belt_List.length(Survey$ReactHooksTemplate.questions(state.survey))
                                                        });
                                                    Curry._1(dispatch, {
                                                          TAG: /* SetToBeAddedBelowIndex */21,
                                                          _0: i + 1 | 0
                                                        });
                                                    Curry._1(dispatch, /* ToggleAddQuestion */3);
                                                    return Curry._1(dispatch, {
                                                                TAG: /* SetPopUp */23,
                                                                _0: /* SaveQuestion */0
                                                              });
                                                  })
                                              }, React.createElement("img", {
                                                    className: "h-12-px",
                                                    src: Image$ReactHooksTemplate.plusCircle
                                                  }), "Add question below"), React.createElement("a", {
                                                className: "flex text-xs gap-2 cursor-pointer",
                                                href: "#",
                                                onClick: (function ($$event) {
                                                    $$event.preventDefault();
                                                    $$event.stopPropagation();
                                                    handleQuestionDeletion(i, param);
                                                    return Curry._1(dispatch, {
                                                                TAG: /* SetOutsideClick */18,
                                                                _0: true
                                                              });
                                                  })
                                              }, React.createElement("img", {
                                                    className: "h-12-px",
                                                    src: Image$ReactHooksTemplate.trash
                                                  }), "Delete"))));
                      };
                    }), Survey$ReactHooksTemplate.questions(state.survey))), $$Array.of_list(List.mapi((function (i) {
                      return function (param) {
                        var id = ThankYou$ReactHooksTemplate.uuid(param);
                        var noOfQuestions = List.length(state.survey.questions);
                        var match = state.survey.welcome;
                        var slNo = match !== undefined ? (i + noOfQuestions | 0) + 2 | 0 : (i + noOfQuestions | 0) + 1 | 0;
                        return React.createElement("li", {
                                    key: RandomId$ReactHooksTemplate.toString(id),
                                    className: "p-3 border-b border-gray-400 mx-4 my-12-px flex justify-between\n                 rounded border bg-gray-100 hover:bg-alicebluesolid h-56-px cursor-pointer " + (
                                      state.selected === ThankYou$ReactHooksTemplate.categoryType && state.selectedId === ThankYou$ReactHooksTemplate.uuid(param) ? "bg-lavenderweb" : ""
                                    ),
                                    onClick: (function (param$1) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSelected */6,
                                              _0: ThankYou$ReactHooksTemplate.categoryType
                                            });
                                        Curry._1(dispatch, {
                                              TAG: /* SetSelectedId */7,
                                              _0: ThankYou$ReactHooksTemplate.uuid(param)
                                            });
                                        return Curry._1(dispatch, {
                                                    TAG: /* PreviewThankYou */12,
                                                    _0: param
                                                  });
                                      })
                                  }, React.createElement("div", {
                                        className: "flex items-center"
                                      }, React.createElement("div", {
                                            className: "flex items-center text-sm text-darkcharcoal font-normal"
                                          }, React.createElement("img", {
                                                className: "mr-2 cursor-default",
                                                src: Image$ReactHooksTemplate.dragHandle
                                              }), React.createElement("div", {
                                                className: "mr-3 text-aurometalsaurus text-xs"
                                              }, String(slNo))), React.createElement("div", {
                                            className: "flex flex-col items-center gap-1 justify-items-start"
                                          }, ThankYou$ReactHooksTemplate.text(param) === "" ? React.createElement(React.Fragment, undefined) : React.createElement("div", {
                                                  className: "w-full"
                                                }, React.createElement("div", {
                                                      className: "truncate max-w-110-px float-left text-darkcharcoal text-sm font-normal ml-px"
                                                    }, ThankYou$ReactHooksTemplate.text(param))), React.createElement("div", {
                                                className: "w-full"
                                              }, React.createElement("div", {
                                                    className: "bg-magnolia px-2 py-1 text-10-px font-light text-darkcharcoal rounded float-left"
                                                  }, "Thank You")))), React.createElement("div", {
                                        className: "flex justify-end items-center relative"
                                      }, React.createElement("img", {
                                            className: "h-18-px",
                                            src: Image$ReactHooksTemplate.verticalDots,
                                            onClick: (function (param) {
                                                return Curry._1(dispatch, {
                                                            TAG: /* SetOutsideClick */18,
                                                            _0: false
                                                          });
                                              })
                                          }), React.createElement("div", {
                                            className: "flex flex-wrap items-search p-2 absolute rounded border border-gray-400 bg-white\n                        text-darkcharcoal text-xs question-dropdown z-50 opacity-100 w-40 gap-2 " + (
                                              state.selected === ThankYou$ReactHooksTemplate.categoryType && state.selectedId === ThankYou$ReactHooksTemplate.uuid(param) && state.outsideClick === false ? "block" : "hidden"
                                            )
                                          }, React.createElement("a", {
                                                className: "flex text-xs gap-2 cursor-pointer",
                                                href: "#"
                                              }, React.createElement("img", {
                                                    className: "h-12-px",
                                                    src: Image$ReactHooksTemplate.plusCircle
                                                  }), "Duplicate"), React.createElement("a", {
                                                className: "flex text-xs gap-2 cursor-pointer",
                                                href: "#",
                                                onClick: (function (param) {
                                                    Curry._1(dispatch, {
                                                          TAG: /* SetOutsideClick */18,
                                                          _0: true
                                                        });
                                                    Curry._1(dispatch, {
                                                          TAG: /* AddEditorBlockInBetween */3,
                                                          _0: i + 1 | 0,
                                                          _1: /* ThankYou */1
                                                        });
                                                    return Curry._1(dispatch, {
                                                                TAG: /* SetPopUp */23,
                                                                _0: /* SaveQuestion */0
                                                              });
                                                  })
                                              }, React.createElement("img", {
                                                    className: "h-12-px",
                                                    src: Image$ReactHooksTemplate.plusCircle
                                                  }), "Add thank you below"), React.createElement("a", {
                                                className: "flex text-xs gap-2 cursor-pointer",
                                                href: "#",
                                                onClick: (function ($$event) {
                                                    $$event.preventDefault();
                                                    $$event.stopPropagation();
                                                    handleThankYouDeletion(i, param);
                                                    return Curry._1(dispatch, {
                                                                TAG: /* SetOutsideClick */18,
                                                                _0: true
                                                              });
                                                  })
                                              }, React.createElement("img", {
                                                    className: "h-12-px",
                                                    src: Image$ReactHooksTemplate.trash
                                                  }), "Delete"))));
                      };
                    }), Survey$ReactHooksTemplate.thankYouList(state.survey)))));
  var editorTabView = React.createElement("div", {
        className: "bg-ghostwhiteblue w-2/5 flex flex-col overflow-y-auto",
        style: {
          height: "calc(100vh - 4rem)"
        },
        onClick: (function (param) {
            Curry._1(dispatch, {
                  TAG: /* SetOutsideClick */18,
                  _0: true
                });
            return Curry._1(dispatch, {
                        TAG: /* SetPopUp */23,
                        _0: /* SaveQuestion */0
                      });
          })
      }, editor);
  var listTabView = React.createElement("div", {
        className: "bg-ghostwhiteblue w-2/5 flex flex-col",
        style: {
          height: "calc(100vh - 4rem)"
        }
      }, React.createElement("div", {
            className: "px-16-px"
          }, React.createElement("div", {
                className: "border-b border-lavenderweb border-x-8"
              }, React.createElement("div", {
                    className: "flex justify-between items-center p-4 h-60-px"
                  }, React.createElement("div", {
                        className: "flex items-center gap-2 cursor-pointer",
                        onClick: (function (param) {
                            Curry._1(setViewQuestion, (function (param) {
                                    return !viewQuestion;
                                  }));
                            if (state.popUp !== undefined) {
                              
                            } else {
                              Curry._1(dispatch, {
                                    TAG: /* SetSelectedEditor */22,
                                    _0: /* QuestionsEditor */0
                                  });
                            }
                            return Curry._1(setViewLogic, (function (param) {
                                          return false;
                                        }));
                          })
                      }, React.createElement("div", {
                            className: "flex items-center justify-center rounded-md " + (
                              state.selectedEditor === /* QuestionsEditor */0 ? "bg-lavenderweb w-8 h-8" : "bg-white w-8 h-8"
                            )
                          }, viewQuestion ? React.createElement("img", {
                                  src: Image$ReactHooksTemplate.vectorDown
                                }) : React.createElement("img", {
                                  src: Image$ReactHooksTemplate.vectorRight
                                })), React.createElement("div", {
                            className: "text-base text-darkcharcoal"
                          }, "Questions")), state.selectedEditor === /* QuestionsEditor */0 ? addQuestionButton : React.createElement(React.Fragment, undefined)), viewQuestion ? questionList : React.createElement(React.Fragment, undefined)), React.createElement("div", {
                className: Curry._1(flagEnabled, /* SurveyLogicNewImplementation */6) ? "flex justify-between items-center p-4 h-60-px border-b border-lavenderweb" : "hidden"
              }, React.createElement("div", {
                    className: "flex items-center gap-2 cursor-pointer",
                    onClick: (function (param) {
                        Curry._1(setViewLogic, (function (param) {
                                return !viewLogic;
                              }));
                        Curry._1(setViewQuestion, (function (param) {
                                return false;
                              }));
                        if (state.popUp !== undefined) {
                          return ;
                        } else {
                          return Curry._1(dispatch, {
                                      TAG: /* SetSelectedEditor */22,
                                      _0: /* LogicEditor */1
                                    });
                        }
                      })
                  }, React.createElement("div", {
                        className: "flex items-center justify-center rounded-md " + (
                          state.selectedEditor === /* LogicEditor */1 ? "bg-lavenderweb w-8 h-8" : "bg-white w-8 h-8"
                        )
                      }, viewLogic ? React.createElement("img", {
                              src: Image$ReactHooksTemplate.vectorDown
                            }) : React.createElement("img", {
                              src: Image$ReactHooksTemplate.vectorRight
                            })), React.createElement("div", {
                        className: "text-base text-darkcharcoal"
                      }, "Logic")))));
  var questionEditor = React.createElement("div", {
        className: "flex w-full",
        style: {
          height: "calc(100vh - 5rem)"
        }
      }, listTabView, React.createElement("div", {
            className: "bg-white border-l border-r border-gray-300 w-full overflow-y-auto",
            style: {
              height: "calc(100vh - 4rem)"
            },
            onClick: (function (param) {
                Curry._1(dispatch, {
                      TAG: /* SetOutsideClick */18,
                      _0: true
                    });
                return Curry._1(dispatch, {
                            TAG: /* SetPopUp */23,
                            _0: /* SaveQuestion */0
                          });
              })
          }, React.createElement("div", undefined, surveyBuilderPreview(undefined))), editorTabView);
  var logicEditor = React.createElement("div", {
        className: "flex w-full",
        style: {
          height: "calc(100vh - 110px)"
        }
      }, listTabView, React.createElement("div", {
            className: "bg-white border-l border-r border-gray-300 w-full overflow-y-auto",
            style: {
              height: "calc(100vh - 4rem)"
            },
            onClick: (function (param) {
                return Curry._1(dispatch, {
                            TAG: /* SetOutsideClick */18,
                            _0: true
                          });
              })
          }, React.createElement("div", undefined, React.createElement(LogicPage$ReactHooksTemplate.make, {
                    survey: state.survey,
                    updateSurvey: updateLogicInSurvey
                  }))), editorTabView);
  var match$5 = state.popUp;
  var handlePopUpDisplay;
  if (match$5 !== undefined) {
    if (typeof match$5 === "number") {
      if (match$5 === /* SaveQuestion */0) {
        if (viewLogic) {
          if (viewQuestion) {
            var match$6 = state.selectedEditor;
            handlePopUpDisplay = match$6 ? logicEditor : questionEditor;
          } else {
            handlePopUpDisplay = saveSurveyPopUp("Kindly save questions before switching", "Save", (function (param) {
                    Curry._1(setViewLogic, (function (param) {
                            return !viewLogic;
                          }));
                    return Curry._1(setViewQuestion, (function (param) {
                                  return !viewQuestion;
                                }));
                  }), (function ($$event) {
                    toggleEditor(undefined);
                    handleSaveForm($$event);
                    return Curry._1(dispatch, {
                                TAG: /* SetPopUp */23,
                                _0: undefined
                              });
                  }));
          }
        } else {
          var match$7 = state.selectedEditor;
          handlePopUpDisplay = match$7 ? logicEditor : questionEditor;
        }
      } else if (viewLogic) {
        var match$8 = state.selectedEditor;
        handlePopUpDisplay = match$8 ? logicEditor : questionEditor;
      } else if (viewQuestion) {
        handlePopUpDisplay = saveSurveyPopUp("Kindly save logics before switching", "Save", (function (param) {
                Curry._1(setViewLogic, (function (param) {
                        return !viewLogic;
                      }));
                return Curry._1(setViewQuestion, (function (param) {
                              return !viewQuestion;
                            }));
              }), (function ($$event) {
                toggleEditor(undefined);
                handleSaveForm($$event);
                return Curry._1(dispatch, {
                            TAG: /* SetPopUp */23,
                            _0: undefined
                          });
              }));
      } else {
        var match$9 = state.selectedEditor;
        handlePopUpDisplay = match$9 ? logicEditor : questionEditor;
      }
    } else if (match$5.TAG === /* DeleteQuestion */0) {
      var logics = match$5._2;
      var question = match$5._1;
      var index = match$5._0;
      handlePopUpDisplay = saveSurveyPopUp("Deleting this question will also remove all associated logic. Please confirm.", "Delete", (function (param) {
              if (state.popUp === undefined) {
                return Curry._1(dispatch, {
                            TAG: /* SetPopUp */23,
                            _0: undefined
                          });
              } else {
                return Curry._1(dispatch, {
                            TAG: /* SetPopUp */23,
                            _0: /* SaveQuestion */0
                          });
              }
            }), (function (param) {
              Curry._1(dispatch, {
                    TAG: /* RemoveQuestionEditorBlock */5,
                    _0: index,
                    _1: question,
                    _2: logics
                  });
              return Curry._1(dispatch, {
                          TAG: /* SetPopUp */23,
                          _0: /* SaveQuestion */0
                        });
            }));
    } else {
      var logics$1 = match$5._2;
      var thankYou = match$5._1;
      var index$1 = match$5._0;
      handlePopUpDisplay = saveSurveyPopUp("Deleting the thankyou will also remove all the associated logic. Please confirm.", "Delete", (function (param) {
              if (state.popUp === undefined) {
                return Curry._1(dispatch, {
                            TAG: /* SetPopUp */23,
                            _0: undefined
                          });
              } else {
                return Curry._1(dispatch, {
                            TAG: /* SetPopUp */23,
                            _0: /* SaveQuestion */0
                          });
              }
            }), (function (param) {
              Curry._1(dispatch, {
                    TAG: /* RemoveThankYouEditorBlock */4,
                    _0: index$1,
                    _1: thankYou,
                    _2: logics$1
                  });
              return Curry._1(dispatch, {
                          TAG: /* SetPopUp */23,
                          _0: /* SaveQuestion */0
                        });
            }));
    }
  } else {
    var match$10 = state.selectedEditor;
    handlePopUpDisplay = match$10 ? logicEditor : questionEditor;
  }
  switch (match$1[0]) {
    case /* Loading */0 :
        return React.createElement("p", undefined, "Loading survey from API in progress...");
    case /* Ready */1 :
        var alertType = state.notification;
        return React.createElement(React.Fragment, undefined, React.createElement(Modal$ReactHooksTemplate.make, {
                        show: state.showPreview,
                        children: React.createElement("div", {
                              className: "w-screen bg-white"
                            }, React.createElement("div", {
                                  className: ""
                                }, React.createElement(SurveyPreviewWrapper$ReactHooksTemplate.make, {
                                      survey: state.survey,
                                      client: state.client,
                                      showPreview: state.showPreview,
                                      flagEnabled: flagEnabled
                                    }))),
                        handleClose: (function (param) {
                            Curry._1(dispatch, /* TogglePreview */2);
                            
                          })
                      }), state.showConfigurations ? React.createElement("div", {
                          onClick: (function (param) {
                              return Curry._1(dispatch, {
                                          TAG: /* SetOutsideClick */18,
                                          _0: true
                                        });
                            })
                        }, React.createElement(SurveyConfiguration$ReactHooksTemplate.make, {
                              onClose: (function (param) {
                                  return Curry._1(dispatch, /* ToggleSurveyConfiguration */4);
                                }),
                              survey: state.survey,
                              updateSurvey: (function (survey) {
                                  return Curry._1(dispatch, {
                                              TAG: /* UpdateSurvey */0,
                                              _0: survey
                                            });
                                })
                            })) : React.createElement(React.Fragment, undefined), React.createElement("div", {
                        className: "bg-white w-full border border-gray-400 flex\n                      justify-between p-4 items-center",
                        onClick: (function (param) {
                            return Curry._1(dispatch, {
                                        TAG: /* SetOutsideClick */18,
                                        _0: true
                                      });
                          })
                      }, alertType !== undefined ? React.createElement(AlertComponent$ReactHooksTemplate.make, {
                              alertType: alertType,
                              handleClose: (function (param) {
                                  return Curry._1(dispatch, /* RemoveNotification */8);
                                })
                            }) : React.createElement(React.Fragment, undefined), React.createElement("div", {
                            className: "text-lg font-semibold flex items-center"
                          }, React.createElement("a", {
                                className: "mr-3",
                                href: "/projects/all?selectedProjectId=" + projectId
                              }, React.createElement("img", {
                                    src: Image$ReactHooksTemplate.backArrow
                                  })), React.createElement("div", {
                                className: "truncate max-w-360-px"
                              }, Survey$ReactHooksTemplate.name(state.survey))), React.createElement("div", {
                            className: "flex items-center gap-4"
                          }, React.createElement("button", {
                                className: "bg-pigmentblue border border-gray-400\n              flex rounded p-2 text-sm",
                                onClick: (function (param) {
                                    Curry._1(dispatch, /* TogglePreview */2);
                                    
                                  })
                              }, React.createElement("div", {
                                    className: "flex items-center justify-between gap-1 text-white"
                                  }, React.createElement("img", {
                                        src: Image$ReactHooksTemplate.preview
                                      }), React.createElement("p", undefined, "Preview"))), React.createElement("button", {
                                className: "bg-ghostwhite border border-gray-400\n              flex rounded p-2 text-sm",
                                onClick: (function ($$event) {
                                    Curry._1(dispatch, {
                                          TAG: /* SetPopUp */23,
                                          _0: undefined
                                        });
                                    return handleSaveForm($$event);
                                  })
                              }, React.createElement("div", {
                                    className: "flex items-center justify-between gap-1 text-darkcharcoal"
                                  }, React.createElement("img", {
                                        src: Image$ReactHooksTemplate.save
                                      }), React.createElement("p", undefined, "Save"))), React.createElement("button", {
                                className: "bg-ghostwhite border border-gray-400\n              flex rounded p-2 text-sm",
                                onClick: (function (param) {
                                    return Curry._1(dispatch, /* ToggleSurveyConfiguration */4);
                                  })
                              }, React.createElement("div", {
                                    className: "flex items-center justify-between gap-1 text-darkcharcoal"
                                  }, React.createElement("img", {
                                        src: Image$ReactHooksTemplate.cogTransparent
                                      }), React.createElement("p", undefined, "Configuration"))))), React.createElement("div", {
                        className: "flex w-full"
                      }, handlePopUpDisplay));
    case /* Saving */2 :
        return React.createElement("p", undefined, "Saving survey in progress...");
    
  }
}

var yesbankQuestiontemplate = "\n  <div class=\"flex flex-col h-full min-h-screen\">\n    <div class=\"h-80-px small:h-60-px mb-5 small:mb-6 bg-cobalt\">\n      <div id=\"logo\">\n        logo\n      </div>\n    </div>\n    <div class=\"mx-12 small:mx-4 flex flex-col\">\n      <div id=\"language\">language</div>\n      <div class=\"pl-142-px small:pl-4 pr-80-px small:pr-4 pt-24 small:pt-4 question-box-gradient rounded-lg mb-8 flex-grow overflow-y-auto pb-16 small:pb-12\">\n        <div id=\"question\">question</div>\n      </div>\n    </div>\n    <div class=\"flex justify-center items-center mb-8\">\n      <div id=\"previousButton\"></div>\n      <div id=\"nextButton\"></div>\n    </div>\n  </div>";

var make = SurveyForm;

exports.yesbankQuestiontemplate = yesbankQuestiontemplate;
exports.bhimaQuestiontemplate = bhimaQuestiontemplate;
exports.defaultQuestiontemplate = defaultQuestiontemplate;
exports.defaultQuestionBuildertemplate = defaultQuestionBuildertemplate;
exports.questiontemplates = questiontemplates;
exports.questionBuilderTemplates = questionBuilderTemplates;
exports.questionsOrPageToString = questionsOrPageToString;
exports.initialAccordionState = initialAccordionState;
exports.decodeSurvey = decodeSurvey;
exports.addQuestionsToGraph = addQuestionsToGraph;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
