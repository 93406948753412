// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var CampaignMode$ReactHooksTemplate = require("./CampaignMode.bs.js");
var Organization$ReactHooksTemplate = require("../settings/organization.bs.js");
var AlertComponent$ReactHooksTemplate = require("../common/AlertComponent.bs.js");
var ApplicationSetting$ReactHooksTemplate = require("./applicationSetting.bs.js");

var initialState_organization = Organization$ReactHooksTemplate.empty(undefined);

var initialState = {
  edited: false,
  organization: initialState_organization,
  notification: undefined
};

function CampaignPolicy(Props) {
  var match = React.useState(function () {
        return initialState;
      });
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          Http$ReactHooksTemplate.fetchGetJSON("/api/v1/organization_profiles/1").then(function (json) {
                  return Promise.resolve(Curry._1(setState, (function (state) {
                                    return {
                                            edited: state.edited,
                                            organization: Json_decode.field("organization_profile", Organization$ReactHooksTemplate.decode, json),
                                            notification: state.notification
                                          };
                                  })));
                }).catch(function (err) {
                return Promise.resolve((console.log(err), undefined));
              });
          
        }), []);
  var updateOrganizationSettings = function (e) {
    e.preventDefault();
    var organizationId = Belt_Option.getWithDefault(Organization$ReactHooksTemplate.id(state.organization), 0);
    var params = {};
    params["organization_profile"] = Organization$ReactHooksTemplate.encode(state.organization);
    Http$ReactHooksTemplate.fetchPutJSON("/api/v1/organization_profiles/" + String(organizationId), params).then(function (response) {
          var status = Json_decode.field("status", (function (param) {
                  return Json_decode.withDefault("error", Json_decode.string, param);
                }), response);
          var tmp;
          switch (status) {
            case "error" :
                var errorMessage = Json_decode.field("error", Json_decode.string, response);
                tmp = Curry._1(setState, (function (state) {
                        return {
                                edited: state.edited,
                                organization: state.organization,
                                notification: {
                                  TAG: /* Failure */2,
                                  _0: errorMessage
                                }
                              };
                      }));
                break;
            case "failure" :
                tmp = Curry._1(setState, (function (state) {
                        return {
                                edited: state.edited,
                                organization: state.organization,
                                notification: {
                                  TAG: /* Failure */2,
                                  _0: "Invalid settings"
                                }
                              };
                      }));
                break;
            case "success" :
                tmp = Curry._1(setState, (function (param) {
                        return {
                                edited: false,
                                organization: Json_decode.field("organization_profile", Organization$ReactHooksTemplate.decode, response),
                                notification: {
                                  TAG: /* Success */1,
                                  _0: "Settings Updated"
                                }
                              };
                      }));
                break;
            default:
              tmp = Curry._1(setState, (function (state) {
                      return {
                              edited: state.edited,
                              organization: state.organization,
                              notification: {
                                TAG: /* Failure */2,
                                _0: "Something went wrong. Please try\n          again later"
                              }
                            };
                    }));
          }
          return Promise.resolve(tmp);
        });
    
  };
  var alertType = state.notification;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex justify-between px-5 py-6 border-b border-gray-300 text-darkcharcoal font-bold text-xl"
                }, "Campaign Policy", React.createElement("button", {
                      className: "border rounded p-2 text-sm text-darkcharcoal bg-white",
                      onClick: updateOrganizationSettings
                    }, "Update"), alertType !== undefined ? React.createElement(AlertComponent$ReactHooksTemplate.make, {
                        alertType: alertType,
                        handleClose: (function (param) {
                            return Curry._1(setState, (function (state) {
                                          return {
                                                  edited: state.edited,
                                                  organization: state.organization,
                                                  notification: undefined
                                                };
                                        }));
                          })
                      }) : React.createElement(React.Fragment, undefined)), React.createElement("div", {
                  className: "p-8"
                }, React.createElement("div", undefined, React.createElement("div", {
                          className: "mt-8"
                        }, $$Array.of_list(List.map((function (x) {
                                    var y = ApplicationSetting$ReactHooksTemplate.individualSetting(x);
                                    var tmp;
                                    switch (y.TAG | 0) {
                                      case /* SavePartialResponse */0 :
                                      case /* GenerateTicket */1 :
                                          tmp = React.createElement(React.Fragment, undefined);
                                          break;
                                      case /* CampaignModes */2 :
                                          var partial_arg = ApplicationSetting$ReactHooksTemplate.id(x);
                                          tmp = React.createElement(CampaignMode$ReactHooksTemplate.make, {
                                                setting: y._0,
                                                enforce: ApplicationSetting$ReactHooksTemplate.enforce(x),
                                                enforced: ApplicationSetting$ReactHooksTemplate.enforced(x),
                                                onChange: (function (param, param$1) {
                                                    var newApplicationSettings = List.map((function (x) {
                                                            if (ApplicationSetting$ReactHooksTemplate.id(x) === partial_arg && !ApplicationSetting$ReactHooksTemplate.enforced(x)) {
                                                              return ApplicationSetting$ReactHooksTemplate.setEnforce(param$1, ApplicationSetting$ReactHooksTemplate.setIndividualSetting(param, x));
                                                            } else {
                                                              return x;
                                                            }
                                                          }), Organization$ReactHooksTemplate.applicationSettings(state.organization));
                                                    return Curry._1(setState, (function (state) {
                                                                  return {
                                                                          edited: state.edited,
                                                                          organization: Organization$ReactHooksTemplate.setApplicationSettings(state.organization, newApplicationSettings),
                                                                          notification: state.notification
                                                                        };
                                                                }));
                                                  })
                                              });
                                          break;
                                      
                                    }
                                    return React.createElement("div", {
                                                className: "mb-16"
                                              }, tmp);
                                  }), Organization$ReactHooksTemplate.applicationSettings(state.organization)))))));
}

var make = CampaignPolicy;

exports.initialState = initialState;
exports.make = make;
/* initialState Not a pure module */
