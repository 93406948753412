// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Campaign$ReactHooksTemplate = require("../surveys/campaign.bs.js");
var ToggleInput$ReactHooksTemplate = require("../common/ToggleInput.bs.js");
var CampaignModes$ReactHooksTemplate = require("./campaignModes.bs.js");
var CheckboxInput$ReactHooksTemplate = require("../common/CheckboxInput.bs.js");

function CampaignMode(Props) {
  var setting = Props.setting;
  var enforce = Props.enforce;
  var enforced = Props.enforced;
  var onChange = Props.onChange;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex justify-between items-center"
                }, React.createElement("div", {
                      className: "text-base font-bold"
                    }, "Campaign Modes"), React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                      checked: enforce,
                      disabled: enforced,
                      label: "Enforce",
                      handleChange: (function (param) {
                          if (!enforced) {
                            return Curry._2(onChange, {
                                        TAG: /* CampaignModes */2,
                                        _0: setting
                                      }, !enforce);
                          }
                          
                        }),
                      checkboxClassName: "custom_checkbox"
                    })), React.createElement("div", {
                  className: "p-8"
                }, React.createElement("div", undefined, React.createElement("div", {
                          className: "mt-8"
                        }, $$Array.of_list(Belt_List.map(Campaign$ReactHooksTemplate.availableCampaignModes, (function (x) {
                                    var isEnabled = Belt_List.has(CampaignModes$ReactHooksTemplate.value(setting), x, (function (a, b) {
                                            return a === b;
                                          }));
                                    return React.createElement("div", {
                                                key: x,
                                                className: "flex justify-between w-4/5 mb-10 items-start"
                                              }, React.createElement("div", {
                                                    className: "flex flex-col"
                                                  }, React.createElement("div", {
                                                        className: "font-medium capitalize text-base text-darkcharcoal"
                                                      }, x)), React.createElement("div", {
                                                    className: "flex items-center"
                                                  }, React.createElement(ToggleInput$ReactHooksTemplate.make, {
                                                        checked: isEnabled,
                                                        disabled: enforced,
                                                        handleChange: (function (param) {
                                                            if (enforced) {
                                                              return ;
                                                            } else {
                                                              var mode = Belt_List.has(CampaignModes$ReactHooksTemplate.value(setting), x, (function (a, b) {
                                                                      return a === b;
                                                                    }));
                                                              var addCampaignMode = mode ? Belt_List.keep(CampaignModes$ReactHooksTemplate.value(setting), (function (a) {
                                                                        return a !== x;
                                                                      })) : Belt_List.add(CampaignModes$ReactHooksTemplate.value(setting), x);
                                                              var updateSettings = CampaignModes$ReactHooksTemplate.setValue(setting, addCampaignMode);
                                                              return Curry._2(onChange, {
                                                                          TAG: /* CampaignModes */2,
                                                                          _0: updateSettings
                                                                        }, enforce);
                                                            }
                                                          }),
                                                        labelClassName: "flex items-center"
                                                      })));
                                  })))))));
}

var make = CampaignMode;

exports.make = make;
/* react Not a pure module */
