// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_array = require("rescript/lib/js/caml_array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Survey$ReactHooksTemplate = require("./survey.bs.js");
var UrlValidator = require("../../../packs/UrlValidator");
var SurveyTheme$ReactHooksTemplate = require("./surveyTheme.bs.js");
var ToggleInput$ReactHooksTemplate = require("../common/ToggleInput.bs.js");
var RadioButtonInput$ReactHooksTemplate = require("../common/RadioButtonInput.bs.js");

function validateUrl(prim) {
  return UrlValidator.validateUrl(prim);
}

function SurveyConfiguration(Props) {
  var onClose = Props.onClose;
  var survey = Props.survey;
  var updateSurvey = Props.updateSurvey;
  var url = Survey$ReactHooksTemplate.redirectionUrl(survey);
  var initialState_surveyTheme = Survey$ReactHooksTemplate.surveyTheme(survey);
  var initialState_logoUrl = Survey$ReactHooksTemplate.logo(survey);
  var initialState_enableRedirection = Survey$ReactHooksTemplate.enableRedirection(survey);
  var initialState_redirectionUrl = url !== undefined ? url : "";
  var initialState_singlePageSurvey = Survey$ReactHooksTemplate.singlePageSurvey(survey);
  var initialState = {
    edited: false,
    survey: survey,
    surveyThemes: /* [] */0,
    surveyTheme: initialState_surveyTheme,
    showUploadInputField: false,
    logoUrl: initialState_logoUrl,
    logoFile: undefined,
    enableRedirection: initialState_enableRedirection,
    redirectionUrl: initialState_redirectionUrl,
    invalidUrlError: "",
    singlePageSurvey: initialState_singlePageSurvey
  };
  var match = React.useState(function () {
        return initialState;
      });
  var setState = match[1];
  var state = match[0];
  var handleLogoChange = function ($$event) {
    var logoFile = Caml_array.get($$event.target.files, 0);
    var logoUrl = window.URL.createObjectURL(logoFile);
    return Curry._1(setState, (function (param) {
                  return {
                          edited: true,
                          survey: state.survey,
                          surveyThemes: state.surveyThemes,
                          surveyTheme: state.surveyTheme,
                          showUploadInputField: false,
                          logoUrl: logoUrl,
                          logoFile: Caml_option.some(logoFile),
                          enableRedirection: state.enableRedirection,
                          redirectionUrl: state.redirectionUrl,
                          invalidUrlError: state.invalidUrlError,
                          singlePageSurvey: state.singlePageSurvey
                        };
                }));
  };
  var showUploadInputField = function ($$event) {
    $$event.preventDefault();
    return Curry._1(setState, (function (state) {
                  return {
                          edited: state.edited,
                          survey: state.survey,
                          surveyThemes: state.surveyThemes,
                          surveyTheme: state.surveyTheme,
                          showUploadInputField: true,
                          logoUrl: state.logoUrl,
                          logoFile: state.logoFile,
                          enableRedirection: state.enableRedirection,
                          redirectionUrl: state.redirectionUrl,
                          invalidUrlError: state.invalidUrlError,
                          singlePageSurvey: state.singlePageSurvey
                        };
                }));
  };
  var revertLogoChanges = function ($$event) {
    $$event.preventDefault();
    return Curry._1(setState, (function (state) {
                  return {
                          edited: false,
                          survey: state.survey,
                          surveyThemes: state.surveyThemes,
                          surveyTheme: state.surveyTheme,
                          showUploadInputField: false,
                          logoUrl: Survey$ReactHooksTemplate.logo(state.survey),
                          logoFile: state.logoFile,
                          enableRedirection: state.enableRedirection,
                          redirectionUrl: state.redirectionUrl,
                          invalidUrlError: state.invalidUrlError,
                          singlePageSurvey: state.singlePageSurvey
                        };
                }));
  };
  var handleThemeChange = function ($$event) {
    var value = Utils$ReactHooksTemplate.formTargetValue($$event);
    var surveyTheme = Belt_List.getBy(state.surveyThemes, (function (surveyTheme) {
            return SurveyTheme$ReactHooksTemplate.name(surveyTheme) === value;
          }));
    if (surveyTheme !== undefined) {
      return Curry._1(setState, (function (state) {
                    return {
                            edited: state.edited,
                            survey: state.survey,
                            surveyThemes: state.surveyThemes,
                            surveyTheme: surveyTheme,
                            showUploadInputField: state.showUploadInputField,
                            logoUrl: state.logoUrl,
                            logoFile: state.logoFile,
                            enableRedirection: state.enableRedirection,
                            redirectionUrl: state.redirectionUrl,
                            invalidUrlError: state.invalidUrlError,
                            singlePageSurvey: state.singlePageSurvey
                          };
                  }));
    }
    
  };
  var discardThemeChange = function ($$event) {
    $$event.preventDefault();
    return Curry._1(setState, (function (state) {
                  return {
                          edited: state.edited,
                          survey: state.survey,
                          surveyThemes: state.surveyThemes,
                          surveyTheme: Survey$ReactHooksTemplate.surveyTheme(state.survey),
                          showUploadInputField: state.showUploadInputField,
                          logoUrl: state.logoUrl,
                          logoFile: state.logoFile,
                          enableRedirection: state.enableRedirection,
                          redirectionUrl: state.redirectionUrl,
                          invalidUrlError: state.invalidUrlError,
                          singlePageSurvey: state.singlePageSurvey
                        };
                }));
  };
  var handleLogoPositionChange = function ($$event) {
    var position = $$event.target.value;
    var updatedSurveyTheme = SurveyTheme$ReactHooksTemplate.setLogoAlignment(state.surveyTheme, position);
    return Curry._1(setState, (function (state) {
                  return {
                          edited: true,
                          survey: state.survey,
                          surveyThemes: state.surveyThemes,
                          surveyTheme: updatedSurveyTheme,
                          showUploadInputField: state.showUploadInputField,
                          logoUrl: state.logoUrl,
                          logoFile: state.logoFile,
                          enableRedirection: state.enableRedirection,
                          redirectionUrl: state.redirectionUrl,
                          invalidUrlError: state.invalidUrlError,
                          singlePageSurvey: state.singlePageSurvey
                        };
                }));
  };
  var updateTheme = function ($$event) {
    $$event.preventDefault();
    var formData = new FormData();
    var match = SurveyTheme$ReactHooksTemplate.id(state.surveyTheme);
    var match$1 = Survey$ReactHooksTemplate.id(state.survey);
    if (match !== undefined && match$1 !== undefined) {
      formData.append("survey[survey_theme_id]", String(match));
      fetch("/api/v1/surveys/" + String(match$1), Fetch.RequestInit.make(/* Put */3, [
                      [
                        "X-CSRF-TOKEN",
                        Utils$ReactHooksTemplate.csrfToken
                      ],
                      [
                        "Accept",
                        "*"
                      ]
                    ], Caml_option.some(formData), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (response) {
              return response.json();
            }).then(function (param) {
            var newSurvey = Survey$ReactHooksTemplate.setSurveyTheme(survey, state.surveyTheme);
            Curry._1(setState, (function (state) {
                    return {
                            edited: state.edited,
                            survey: newSurvey,
                            surveyThemes: state.surveyThemes,
                            surveyTheme: state.surveyTheme,
                            showUploadInputField: state.showUploadInputField,
                            logoUrl: state.logoUrl,
                            logoFile: state.logoFile,
                            enableRedirection: state.enableRedirection,
                            redirectionUrl: state.redirectionUrl,
                            invalidUrlError: state.invalidUrlError,
                            singlePageSurvey: state.singlePageSurvey
                          };
                  }));
            Curry._1(updateSurvey, newSurvey);
            return Promise.resolve(undefined);
          });
      return ;
    }
    
  };
  var saveSurveyLogo$p = function ($$event) {
    $$event.preventDefault();
    var surveyTheme = state.surveyTheme;
    var params = {};
    params["survey_theme"] = SurveyTheme$ReactHooksTemplate.encode(surveyTheme);
    var id = SurveyTheme$ReactHooksTemplate.id(surveyTheme);
    if (id !== undefined) {
      var surveyThemeId = String(id);
      Http$ReactHooksTemplate.fetchPutJSON("/api/v1/survey_themes/" + surveyThemeId, params).then(function (response) {
            var status = Json_decode.field("status", (function (param) {
                    return Json_decode.withDefault("failed", Json_decode.string, param);
                  }), response);
            if (status === "success") {
              var newSurveyTheme = Json_decode.field("survey_theme", SurveyTheme$ReactHooksTemplate.decode, response);
              var formData = new FormData();
              var newSurvey = Survey$ReactHooksTemplate.setSurveyTheme(state.survey, newSurveyTheme);
              Curry._1(setState, (function (state) {
                      return {
                              edited: false,
                              survey: newSurvey,
                              surveyThemes: state.surveyThemes,
                              surveyTheme: newSurveyTheme,
                              showUploadInputField: state.showUploadInputField,
                              logoUrl: state.logoUrl,
                              logoFile: state.logoFile,
                              enableRedirection: state.enableRedirection,
                              redirectionUrl: state.redirectionUrl,
                              invalidUrlError: state.invalidUrlError,
                              singlePageSurvey: state.singlePageSurvey
                            };
                    }));
              Curry._1(updateSurvey, newSurvey);
              var match = state.logoFile;
              var match$1 = Survey$ReactHooksTemplate.id(state.survey);
              if (match !== undefined && match$1 !== undefined) {
                formData.append("survey[logo]", Caml_option.valFromOption(match), undefined);
                fetch("/api/v1/surveys/" + String(match$1), Fetch.RequestInit.make(/* Put */3, [
                                [
                                  "X-CSRF-TOKEN",
                                  Utils$ReactHooksTemplate.csrfToken
                                ],
                                [
                                  "Accept",
                                  "*"
                                ]
                              ], Caml_option.some(formData), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (response) {
                        Curry._1(setState, (function (state) {
                                return {
                                        edited: false,
                                        survey: state.survey,
                                        surveyThemes: state.surveyThemes,
                                        surveyTheme: state.surveyTheme,
                                        showUploadInputField: state.showUploadInputField,
                                        logoUrl: state.logoUrl,
                                        logoFile: state.logoFile,
                                        enableRedirection: state.enableRedirection,
                                        redirectionUrl: state.redirectionUrl,
                                        invalidUrlError: state.invalidUrlError,
                                        singlePageSurvey: state.singlePageSurvey
                                      };
                              }));
                        return response.json();
                      }).then(function (response) {
                      var logoUrl = Json_decode.field("survey", (function (param) {
                              return Json_decode.field("logo", (function (param) {
                                            return Json_decode.optional(Json_decode.string, param);
                                          }), param);
                            }), response);
                      var newSurvey = Survey$ReactHooksTemplate.setSurveyTheme(Survey$ReactHooksTemplate.setLogo(state.survey, logoUrl), newSurveyTheme);
                      Curry._1(setState, (function (state) {
                              return {
                                      edited: state.edited,
                                      survey: newSurvey,
                                      surveyThemes: state.surveyThemes,
                                      surveyTheme: state.surveyTheme,
                                      showUploadInputField: state.showUploadInputField,
                                      logoUrl: state.logoUrl,
                                      logoFile: state.logoFile,
                                      enableRedirection: state.enableRedirection,
                                      redirectionUrl: state.redirectionUrl,
                                      invalidUrlError: state.invalidUrlError,
                                      singlePageSurvey: state.singlePageSurvey
                                    };
                            }));
                      Curry._1(updateSurvey, newSurvey);
                      return Promise.resolve(undefined);
                    });
              }
              
            } else {
              console.log("Something went wrong. Please contact admin.");
            }
            return Promise.resolve(undefined);
          });
      return ;
    }
    Http$ReactHooksTemplate.fetchPostJSON("/api/v1/survey_themes", params).then(function (response) {
          var status = Json_decode.field("status", (function (param) {
                  return Json_decode.withDefault("failed", Json_decode.string, param);
                }), response);
          if (status === "success") {
            var newSurveyTheme = Json_decode.field("survey_theme", SurveyTheme$ReactHooksTemplate.decode, response);
            var newSurvey = Survey$ReactHooksTemplate.setSurveyTheme(state.survey, newSurveyTheme);
            Curry._1(setState, (function (state) {
                    return {
                            edited: state.edited,
                            survey: newSurvey,
                            surveyThemes: state.surveyThemes,
                            surveyTheme: newSurveyTheme,
                            showUploadInputField: state.showUploadInputField,
                            logoUrl: state.logoUrl,
                            logoFile: state.logoFile,
                            enableRedirection: state.enableRedirection,
                            redirectionUrl: state.redirectionUrl,
                            invalidUrlError: state.invalidUrlError,
                            singlePageSurvey: state.singlePageSurvey
                          };
                  }));
            Curry._1(updateSurvey, newSurvey);
          } else {
            console.log("Something went wrong. Please contact admin.");
          }
          return Promise.resolve(undefined);
        });
    
  };
  React.useEffect((function () {
          Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/survey_themes", (function (param) {
                    return Json_decode.field("survey_themes", (function (param) {
                                  return Json_decode.list(SurveyTheme$ReactHooksTemplate.decode, param);
                                }), param);
                  })).then(function (surveyThemes) {
                return Promise.resolve(Curry._1(setState, (function (state) {
                                  return {
                                          edited: state.edited,
                                          survey: state.survey,
                                          surveyThemes: surveyThemes,
                                          surveyTheme: state.surveyTheme,
                                          showUploadInputField: state.showUploadInputField,
                                          logoUrl: state.logoUrl,
                                          logoFile: state.logoFile,
                                          enableRedirection: state.enableRedirection,
                                          redirectionUrl: state.redirectionUrl,
                                          invalidUrlError: state.invalidUrlError,
                                          singlePageSurvey: state.singlePageSurvey
                                        };
                                })));
              });
          
        }), []);
  var partial_arg = state.survey;
  var partial_arg$1 = state.survey;
  return React.createElement("div", {
              className: "bg-white w-5/12 fixed h-screen top-0 right-0 z-10 shadow border-l border-gray-200"
            }, React.createElement("div", {
                  className: "flex justify-between bg-antiflashwhitegrey px-6 py-5 text-lg font-semibold"
                }, "Survey Configuration", React.createElement("div", {
                      className: "flex items-center"
                    }, React.createElement("button", {
                          onClick: onClose
                        }, React.createElement("img", {
                              className: "w-3",
                              src: Image$ReactHooksTemplate.closeIcon
                            })))), React.createElement("div", {
                  className: "py-6 px-10 overflow-auto max-h-screen"
                }, React.createElement("div", {
                      className: "border-b border-gray-300 mb-8"
                    }, React.createElement("div", {
                          className: "flex justify-between pb-8"
                        }, React.createElement("div", {
                              className: "flex text-base font-medium"
                            }, React.createElement("img", {
                                  className: "w-4 mr-1",
                                  src: Image$ReactHooksTemplate.cogTransparent
                                }), "Survey Details"), React.createElement("div", {
                              className: "flex items-center"
                            }, React.createElement("img", {
                                  className: "w-3",
                                  src: Image$ReactHooksTemplate.minus
                                }))), React.createElement("div", {
                          className: "px-4 mb-8"
                        }, React.createElement("div", {
                              className: "mb-8 pb-8 border-b border-gray-300"
                            }, React.createElement("div", {
                                  className: "bg-ghostwhitegray h-16 w-full flex\n                              items-center px-4 justify-between rounded-lg\n                              border border-gray-300"
                                }, React.createElement("div", {
                                      className: "text-sm text-darkcharcoal"
                                    }, "Survey Title"), React.createElement("input", {
                                      className: "h-10 px-4 py-3 rounded border border-gray-300 text-sm",
                                      value: Survey$ReactHooksTemplate.name(state.survey),
                                      onChange: (function (param) {
                                          var name = param.target.value;
                                          var newSurvey = Survey$ReactHooksTemplate.setName(partial_arg, name);
                                          Curry._1(setState, (function (state) {
                                                  return {
                                                          edited: state.edited,
                                                          survey: newSurvey,
                                                          surveyThemes: state.surveyThemes,
                                                          surveyTheme: state.surveyTheme,
                                                          showUploadInputField: state.showUploadInputField,
                                                          logoUrl: state.logoUrl,
                                                          logoFile: state.logoFile,
                                                          enableRedirection: state.enableRedirection,
                                                          redirectionUrl: state.redirectionUrl,
                                                          invalidUrlError: state.invalidUrlError,
                                                          singlePageSurvey: state.singlePageSurvey
                                                        };
                                                }));
                                          return Curry._1(updateSurvey, newSurvey);
                                        })
                                    }))), React.createElement("div", {
                              className: "mb-8 pb-8 border-b border-gray-300"
                            }, React.createElement("div", {
                                  className: "flex justify-between pb-4"
                                }, React.createElement("div", {
                                      className: "text-base font-medium text-darkcharcoal"
                                    }, "Meta Description"), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        }))), React.createElement("div", {
                                  className: "bg-ghostwhitegray w-full flex\n                              items-center px-4 justify-between rounded-lg\n                              border border-gray-300 py-4"
                                }, React.createElement("div", {
                                      className: "text-sm text-darkcharcoal"
                                    }, "Description"), React.createElement("textArea", {
                                      className: "h-20 px-4 py-3 rounded border border-gray-300 text-sm",
                                      value: Survey$ReactHooksTemplate.name(survey)
                                    }))), React.createElement("div", {
                              className: "mb-8 pb-8 border-b border-gray-300"
                            }, React.createElement("div", {
                                  className: "flex justify-between pb-4"
                                }, React.createElement("div", {
                                      className: "text-base font-medium text-darkcharcoal"
                                    }, "Survey Logo"), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        }))), React.createElement("div", {
                                  className: "bg-ghostwhitegray w-full\n                              items-center px-4 rounded-lg\n                              border border-gray-300 py-4"
                                }, React.createElement("div", {
                                      className: "flex justify-start mb-4 items-center h-138-px"
                                    }, React.createElement("div", {
                                          className: "text-sm text-darkcharcoal pr-10 px-2"
                                        }, "Logo"), React.createElement("div", {
                                          className: "flex flex-col items-center justify-center h-128-px"
                                        }, state.showUploadInputField ? React.createElement("input", {
                                                className: "h-10 w-3/4 block border px-3 py-2 border-gray-400 bg-white rounded capitalize text-sm",
                                                accept: ".png",
                                                type: "file",
                                                onChange: handleLogoChange
                                              }) : React.createElement(React.Fragment, undefined, React.createElement("img", {
                                                    className: "h-100-px mb-1",
                                                    src: Belt_Option.getWithDefault(state.logoUrl, "#")
                                                  }), React.createElement("button", {
                                                    className: "round border text-sm bg-ghostwhite\n                           border-gray-400 p-1 rounded",
                                                    onClick: showUploadInputField
                                                  }, "Change")))), React.createElement("div", {
                                      className: "text-base font-medium text-darkcharcoal mt-4"
                                    }, "Logo Position"), React.createElement("div", {
                                      className: "flex justify-between pb-4 mt-8"
                                    }, React.createElement(RadioButtonInput$ReactHooksTemplate.make, {
                                          checked: Caml_obj.caml_equal(SurveyTheme$ReactHooksTemplate.logoAlignment(state.surveyTheme), "start"),
                                          label: "Left",
                                          handleChange: handleLogoPositionChange,
                                          className: "flex text-base font-medium text-darkcharcoal",
                                          value: "start"
                                        }), React.createElement(RadioButtonInput$ReactHooksTemplate.make, {
                                          checked: Caml_obj.caml_equal(SurveyTheme$ReactHooksTemplate.logoAlignment(state.surveyTheme), "middle"),
                                          label: "Center",
                                          handleChange: handleLogoPositionChange,
                                          className: "flex text-base font-medium text-darkcharcoal",
                                          value: "middle"
                                        }), React.createElement(RadioButtonInput$ReactHooksTemplate.make, {
                                          checked: Caml_obj.caml_equal(SurveyTheme$ReactHooksTemplate.logoAlignment(state.surveyTheme), "end"),
                                          label: "Right",
                                          handleChange: handleLogoPositionChange,
                                          className: "flex text-base font-medium text-darkcharcoal",
                                          value: "end"
                                        })), state.edited === true ? React.createElement("div", {
                                        className: "flex justify-end gap-3"
                                      }, React.createElement("button", {
                                            className: "border rounded p-2 text-sm text-darkcharcoal\n                  bg-white",
                                            disabled: !state.edited,
                                            onClick: revertLogoChanges
                                          }, "Revert"), React.createElement("button", {
                                            className: "border rounded p-2 text-sm bg-pigmentblue\n                  text-white w-2/11",
                                            disabled: !state.edited,
                                            onClick: saveSurveyLogo$p
                                          }, "Apply")) : React.createElement("div", {
                                        className: "flex justify-end gap-3"
                                      }, React.createElement("button", {
                                            className: "border text-gray-400 rounded p-2 text-sm",
                                            disabled: !state.edited,
                                            onClick: revertLogoChanges
                                          }, "Revert"), React.createElement("button", {
                                            className: "border rounded p-2 text-sm bg-pigmentblue\n                  text-white w-2/11 opacity-25",
                                            disabled: !state.edited,
                                            onClick: saveSurveyLogo$p
                                          }, "Apply")))), React.createElement("div", {
                              className: "border-b border-gray-300 mb-8"
                            }, React.createElement("div", {
                                  className: "flex justify-between pb-8"
                                }, React.createElement("div", {
                                      className: "flex text-base font-medium"
                                    }, React.createElement("img", {
                                          className: "w-4 mr-1",
                                          src: Image$ReactHooksTemplate.palette
                                        }), "Themes"), React.createElement("div", {
                                      className: "flex items-center"
                                    }, React.createElement("img", {
                                          className: "w-3",
                                          src: Image$ReactHooksTemplate.minus
                                        }))), React.createElement("div", {
                                  className: "mb-8"
                                }, React.createElement("div", {
                                      className: "p-6 bg-ghostwhite rounded-xl border border-gray-300"
                                    }, React.createElement("div", {
                                          className: "flex justify-between pb-8 items-center"
                                        }, React.createElement("div", {
                                              className: "flex text-sm font-normal"
                                            }, "Current Theme"), React.createElement("div", {
                                              className: "flex items-center cursor-pointer"
                                            }, React.createElement("select", {
                                                  className: "h-10 block border px-2 py-1 border-gray-400\n                                 bg-white rounded text-sm cursor-pointer ",
                                                  placeholder: "Select Theme",
                                                  value: SurveyTheme$ReactHooksTemplate.name(state.surveyTheme),
                                                  onChange: handleThemeChange
                                                }, React.createElement("option", {
                                                      key: "0",
                                                      disabled: true,
                                                      value: "Select Theme"
                                                    }, "Select Theme"), $$Array.of_list(Belt_List.map(state.surveyThemes, (function (surveyTheme) {
                                                            return React.createElement("option", {
                                                                        key: SurveyTheme$ReactHooksTemplate.name(surveyTheme),
                                                                        value: SurveyTheme$ReactHooksTemplate.name(surveyTheme)
                                                                      }, Utils$ReactHooksTemplate.humanize(SurveyTheme$ReactHooksTemplate.name(surveyTheme)));
                                                          })))))), React.createElement("div", {
                                          className: "flex justify-between pb-8 items-center"
                                        }, React.createElement("div", {
                                              className: "flex text-sm font-normal"
                                            }, "Color Scheme"), React.createElement("div", {
                                              className: "flex items-center border border-gray-400 rounded"
                                            }, React.createElement("div", {
                                                  className: "w-8 h-10 rounded-l border-r-1/2 border-gray-600",
                                                  style: {
                                                    backgroundColor: SurveyTheme$ReactHooksTemplate.questionColor(state.surveyTheme)
                                                  }
                                                }), React.createElement("div", {
                                                  className: "w-8 h-10 border-r-1/2 border-gray-600",
                                                  style: {
                                                    backgroundColor: SurveyTheme$ReactHooksTemplate.nextButtonColor(state.surveyTheme)
                                                  }
                                                }), React.createElement("div", {
                                                  className: "w-8 h-10 border-r-1/2 border-gray-600",
                                                  style: {
                                                    backgroundColor: SurveyTheme$ReactHooksTemplate.backButtonColor(state.surveyTheme)
                                                  }
                                                }), React.createElement("div", {
                                                  className: "w-8 h-10 border-r-1/2 border-gray-600",
                                                  style: {
                                                    backgroundColor: SurveyTheme$ReactHooksTemplate.backgroundColor(state.surveyTheme)
                                                  }
                                                }), React.createElement("div", {
                                                  className: "w-8 h-10 rounded-r",
                                                  style: {
                                                    backgroundColor: SurveyTheme$ReactHooksTemplate.answerColor(state.surveyTheme)
                                                  }
                                                }))), React.createElement("div", {
                                          className: "flex items-center gap-8 justify-center pb-8"
                                        }, React.createElement("div", {
                                              className: "flex flex-col text-xs items-center"
                                            }, React.createElement("div", {
                                                  className: "w-10 h-10 rounded-full border border-gray-400 mb-1",
                                                  style: {
                                                    backgroundColor: SurveyTheme$ReactHooksTemplate.questionColor(state.surveyTheme)
                                                  }
                                                }), "Question"), React.createElement("div", {
                                              className: "flex flex-col text-xs items-center"
                                            }, React.createElement("div", {
                                                  className: "w-10 h-10 rounded-full border border-gray-400 mb-1",
                                                  style: {
                                                    backgroundColor: SurveyTheme$ReactHooksTemplate.nextButtonColor(state.surveyTheme)
                                                  }
                                                }), "Next"), React.createElement("div", {
                                              className: "flex flex-col text-xs items-center"
                                            }, React.createElement("div", {
                                                  className: "w-10 h-10 rounded-full border border-gray-400 mb-1",
                                                  style: {
                                                    backgroundColor: SurveyTheme$ReactHooksTemplate.backButtonColor(state.surveyTheme)
                                                  }
                                                }), "Back"), React.createElement("div", {
                                              className: "flex flex-col text-xs items-center"
                                            }, React.createElement("div", {
                                                  className: "w-10 h-10 rounded-full border border-gray-400 mb-1",
                                                  style: {
                                                    backgroundColor: SurveyTheme$ReactHooksTemplate.backgroundColor(state.surveyTheme)
                                                  }
                                                }), "Background"), React.createElement("div", {
                                              className: "flex flex-col text-xs items-center"
                                            }, React.createElement("div", {
                                                  className: "w-10 h-10 rounded-full border border-gray-400 mb-1",
                                                  style: {
                                                    backgroundColor: SurveyTheme$ReactHooksTemplate.answerColor(state.surveyTheme)
                                                  }
                                                }), "Answer")), React.createElement("div", {
                                          className: "flex justify-end gap-3"
                                        }, React.createElement("button", {
                                              className: "border rounded p-2 text-sm text-darkcharcoal\n                      bg-white " + (
                                                Caml_obj.caml_equal(Survey$ReactHooksTemplate.surveyTheme(state.survey), state.surveyTheme) ? "opacity-25 disabled-input" : ""
                                              ),
                                              disabled: Caml_obj.caml_equal(Survey$ReactHooksTemplate.surveyTheme(state.survey), state.surveyTheme),
                                              onClick: discardThemeChange
                                            }, "Discard"), React.createElement("button", {
                                              className: "border rounded p-2 text-sm bg-pigmentblue\n                      text-white w-2/11 " + (
                                                Caml_obj.caml_equal(Survey$ReactHooksTemplate.surveyTheme(state.survey), state.surveyTheme) ? "opacity-25 disabled-input" : ""
                                              ),
                                              disabled: Caml_obj.caml_equal(Survey$ReactHooksTemplate.surveyTheme(state.survey), state.surveyTheme),
                                              onClick: updateTheme
                                            }, "Update"))))), React.createElement("div", {
                              className: "mb-8 pb-8 border-b border-gray-300"
                            }, React.createElement("div", {
                                  className: "flex justify-between pb-4"
                                }, React.createElement("div", {
                                      className: "text-base font-medium text-darkcharcoal"
                                    }, "Promoter Survey Redirection"), React.createElement(ToggleInput$ReactHooksTemplate.make, {
                                      checked: state.enableRedirection,
                                      handleChange: (function (param) {
                                          var toggledValue = !state.enableRedirection;
                                          var newSurvey = Survey$ReactHooksTemplate.setEnableRedirection(survey, toggledValue);
                                          var redirectionUrl = toggledValue ? state.redirectionUrl : "";
                                          Curry._1(setState, (function (state) {
                                                  return {
                                                          edited: state.edited,
                                                          survey: newSurvey,
                                                          surveyThemes: state.surveyThemes,
                                                          surveyTheme: state.surveyTheme,
                                                          showUploadInputField: state.showUploadInputField,
                                                          logoUrl: state.logoUrl,
                                                          logoFile: state.logoFile,
                                                          enableRedirection: toggledValue,
                                                          redirectionUrl: redirectionUrl,
                                                          invalidUrlError: state.invalidUrlError,
                                                          singlePageSurvey: state.singlePageSurvey
                                                        };
                                                }));
                                          return Curry._1(updateSurvey, newSurvey);
                                        }),
                                      labelClassName: "flex items-center"
                                    })), React.createElement("div", {
                                  className: "bg-ghostwhitegray w-full flex\n                              items-center p-4 rounded-lg\n                              border border-gray-300"
                                }, React.createElement("input", {
                                      className: "h-10 px-4 py-3 rounded border border-gray-300 text-sm w-full",
                                      disabled: !state.enableRedirection,
                                      placeholder: "https://abcd.com",
                                      value: state.redirectionUrl,
                                      onChange: (function (param) {
                                          var url = param.target.value;
                                          if (!UrlValidator.validateUrl(url)) {
                                            return Curry._1(setState, (function (state) {
                                                          return {
                                                                  edited: state.edited,
                                                                  survey: state.survey,
                                                                  surveyThemes: state.surveyThemes,
                                                                  surveyTheme: state.surveyTheme,
                                                                  showUploadInputField: state.showUploadInputField,
                                                                  logoUrl: state.logoUrl,
                                                                  logoFile: state.logoFile,
                                                                  enableRedirection: state.enableRedirection,
                                                                  redirectionUrl: url,
                                                                  invalidUrlError: "*Enter a valid URL",
                                                                  singlePageSurvey: state.singlePageSurvey
                                                                };
                                                        }));
                                          }
                                          var newSurvey = Survey$ReactHooksTemplate.setRedirectionUrl(partial_arg$1, url);
                                          Curry._1(setState, (function (state) {
                                                  return {
                                                          edited: state.edited,
                                                          survey: newSurvey,
                                                          surveyThemes: state.surveyThemes,
                                                          surveyTheme: state.surveyTheme,
                                                          showUploadInputField: state.showUploadInputField,
                                                          logoUrl: state.logoUrl,
                                                          logoFile: state.logoFile,
                                                          enableRedirection: state.enableRedirection,
                                                          redirectionUrl: url,
                                                          invalidUrlError: "",
                                                          singlePageSurvey: state.singlePageSurvey
                                                        };
                                                }));
                                          return Curry._1(updateSurvey, newSurvey);
                                        })
                                    })), React.createElement("span", {
                                  className: "px-4 text-xs text-firebrick"
                                }, state.invalidUrlError)), React.createElement("div", {
                              className: "mb-8"
                            }, React.createElement("div", {
                                  className: "flex justify-between pb-4"
                                }, React.createElement("div", {
                                      className: "text-base font-medium text-darkcharcoal"
                                    }, "In Active Survey Redirection"), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        }))), React.createElement("div", {
                                  className: "bg-ghostwhitegray w-full flex\n                              items-center p-4 rounded-lg\n                              border border-gray-300"
                                }, React.createElement("input", {
                                      className: "h-10 px-4 py-3 rounded border border-gray-300 text-sm w-full",
                                      placeholder: "https://abcd.com"
                                    }))))), React.createElement("div", {
                      className: "border-b border-gray-300 mb-8"
                    }, React.createElement("div", {
                          className: "flex justify-between pb-8"
                        }, React.createElement("div", {
                              className: "flex text-base font-medium"
                            }, React.createElement("img", {
                                  className: "w-4 mr-1",
                                  src: Image$ReactHooksTemplate.cogTransparent
                                }), "Survey Settings"), React.createElement("div", {
                              className: "flex items-center"
                            }, React.createElement("img", {
                                  className: "w-3",
                                  src: Image$ReactHooksTemplate.minus
                                }))), React.createElement("div", {
                          className: "px-4 mb-8"
                        }, React.createElement("div", {
                              className: "mb-8 pb-8 border-b border-gray-300"
                            }, React.createElement("div", {
                                  className: "flex justify-between pb-8"
                                }, React.createElement("div", {
                                      className: "text-sm text-darkcharcoal"
                                    }, "Single Page Survey"), React.createElement(ToggleInput$ReactHooksTemplate.make, {
                                      checked: state.singlePageSurvey,
                                      handleChange: (function (param) {
                                          var toggledValue = !state.singlePageSurvey;
                                          var newSurvey = Survey$ReactHooksTemplate.setSinglePageSurvey(survey, toggledValue);
                                          Curry._1(setState, (function (state) {
                                                  return {
                                                          edited: state.edited,
                                                          survey: newSurvey,
                                                          surveyThemes: state.surveyThemes,
                                                          surveyTheme: state.surveyTheme,
                                                          showUploadInputField: state.showUploadInputField,
                                                          logoUrl: state.logoUrl,
                                                          logoFile: state.logoFile,
                                                          enableRedirection: state.enableRedirection,
                                                          redirectionUrl: state.redirectionUrl,
                                                          invalidUrlError: state.invalidUrlError,
                                                          singlePageSurvey: toggledValue
                                                        };
                                                }));
                                          return Curry._1(updateSurvey, newSurvey);
                                        }),
                                      labelClassName: "flex items-center"
                                    })), React.createElement("div", {
                                  className: "flex justify-between pb-8"
                                }, React.createElement("div", {
                                      className: "text-sm text-darkcharcoal"
                                    }, "Progress Bar"), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        }))), React.createElement("div", {
                                  className: "flex justify-between pb-8"
                                }, React.createElement("div", {
                                      className: "text-sm text-darkcharcoal"
                                    }, "Track IP"), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        }))), React.createElement("div", {
                                  className: "flex justify-between pb-8"
                                }, React.createElement("div", {
                                      className: "text-sm text-darkcharcoal"
                                    }, "Randomize"), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        }))), React.createElement("div", {
                                  className: "flex justify-between pb-8"
                                }, React.createElement("div", {
                                      className: "text-sm text-darkcharcoal"
                                    }, "Show question number"), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        }))), React.createElement("div", {
                                  className: "flex justify-between"
                                }, React.createElement("div", {
                                      className: "text-sm text-darkcharcoal"
                                    }, "Save and continue"), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        })))), React.createElement("div", {
                              className: "mb-8 pb-8 border-b border-gray-300"
                            }, React.createElement("div", {
                                  className: "flex justify-between pb-4"
                                }, React.createElement("div", {
                                      className: "flex flex-col gap-2"
                                    }, React.createElement("div", {
                                          className: "text-base font-medium text-darkcharcoal"
                                        }, "Limit questions per page"), React.createElement("div", {
                                          className: "text-xs text-aurometalsaurus"
                                        }, "Limit the number of questions per each page/section")), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        }))), React.createElement("div", {
                                  className: "bg-ghostwhitegray h-16 w-full flex\n                              items-center px-4 justify-between rounded-lg\n                              border border-gray-300"
                                }, React.createElement("div", {
                                      className: "text-sm text-darkcharcoal"
                                    }, "Max number of questions per page"), React.createElement("input", {
                                      className: "h-10 px-4 py-3 rounded border border-gray-300 text-sm w-20"
                                    }))), React.createElement("div", {
                              className: "mb-8 pb-8"
                            }, React.createElement("div", {
                                  className: "flex justify-between pb-4"
                                }, React.createElement("div", {
                                      className: "flex flex-col gap-2"
                                    }, React.createElement("div", {
                                          className: "text-base font-medium text-darkcharcoal"
                                        }, "Resumbission"), React.createElement("div", {
                                          className: "text-xs text-aurometalsaurus"
                                        }, "Allow users to resubmit the response")), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        }))))))));
}

var make = SurveyConfiguration;

exports.validateUrl = validateUrl;
exports.make = make;
/* react Not a pure module */
