// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function name(t) {
  return t.name;
}

function value(t) {
  return t.value;
}

function setName(t, x) {
  return {
          name: x,
          value: t.value
        };
}

function setValue(t, x) {
  return {
          name: t.name,
          value: x
        };
}

function decode(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          value: Json_decode.field("value", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json)
        };
}

function encode(t) {
  return Json_encode.list((function (prim) {
                return prim;
              }), t.value);
}

exports.value = value;
exports.name = name;
exports.setValue = setValue;
exports.setName = setName;
exports.decode = decode;
exports.encode = encode;
/* No side effect */
