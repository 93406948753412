// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Campaign$ReactHooksTemplate = require("./campaign.bs.js");
var CampaignMode$ReactHooksTemplate = require("../general_settings/CampaignMode.bs.js");
var AlertComponent$ReactHooksTemplate = require("../common/AlertComponent.bs.js");
var GenerateTickets$ReactHooksTemplate = require("../general_settings/GenerateTickets.bs.js");
var ApplicationSetting$ReactHooksTemplate = require("../general_settings/applicationSetting.bs.js");
var SavePartialResponses$ReactHooksTemplate = require("../general_settings/SavePartialResponses.bs.js");

function CampaignConfiguration(Props) {
  var campaign = Props.campaign;
  var onClose = Props.onClose;
  var onUpdate = Props.onUpdate;
  var initialState = {
    campaign: campaign,
    notification: undefined,
    edited: false
  };
  var match = React.useState(function () {
        return initialState;
      });
  var setState = match[1];
  var state = match[0];
  var onChange = function (id, setting, enforce) {
    var newApplicationSettings = List.map((function (x) {
            if (ApplicationSetting$ReactHooksTemplate.id(x) === id && !ApplicationSetting$ReactHooksTemplate.enforced(x)) {
              return ApplicationSetting$ReactHooksTemplate.setEnforce(enforce, ApplicationSetting$ReactHooksTemplate.setIndividualSetting(setting, x));
            } else {
              return x;
            }
          }), Campaign$ReactHooksTemplate.applicationSettings(state.campaign));
    return Curry._1(setState, (function (state) {
                  return {
                          campaign: Campaign$ReactHooksTemplate.setApplicationSettings(state.campaign, newApplicationSettings),
                          notification: state.notification,
                          edited: true
                        };
                }));
  };
  var updateCampaignSettings = function (e) {
    e.preventDefault();
    var campaignId = Campaign$ReactHooksTemplate.id(state.campaign);
    var params = {};
    params["campaign"] = Campaign$ReactHooksTemplate.encode(state.campaign);
    Http$ReactHooksTemplate.fetchPutJSON("/api/v1/campaigns/" + String(campaignId), params).then(function (response) {
          var status = Json_decode.field("status", (function (param) {
                  return Json_decode.withDefault("error", Json_decode.string, param);
                }), response);
          var tmp;
          switch (status) {
            case "error" :
                var errorMessage = Json_decode.field("error", Json_decode.string, response);
                tmp = Curry._1(setState, (function (state) {
                        return {
                                campaign: state.campaign,
                                notification: {
                                  TAG: /* Failure */2,
                                  _0: errorMessage
                                },
                                edited: state.edited
                              };
                      }));
                break;
            case "failure" :
                tmp = Curry._1(setState, (function (state) {
                        return {
                                campaign: state.campaign,
                                notification: {
                                  TAG: /* Failure */2,
                                  _0: "Invalid settings"
                                },
                                edited: state.edited
                              };
                      }));
                break;
            case "success" :
                var campaign = Json_decode.field("campaign", Campaign$ReactHooksTemplate.decode, response);
                Curry._1(setState, (function (param) {
                        return {
                                campaign: campaign,
                                notification: {
                                  TAG: /* Success */1,
                                  _0: "Settings Updated"
                                },
                                edited: false
                              };
                      }));
                tmp = Curry._1(onUpdate, campaign);
                break;
            default:
              tmp = Curry._1(setState, (function (state) {
                      return {
                              campaign: state.campaign,
                              notification: {
                                TAG: /* Failure */2,
                                _0: "Something went wrong. Please try\n          again later"
                              },
                              edited: state.edited
                            };
                    }));
          }
          return Promise.resolve(tmp).catch(function (err) {
                      return Promise.resolve((console.log(err), Curry._1(setState, (function (state) {
                                          return {
                                                  campaign: state.campaign,
                                                  notification: {
                                                    TAG: /* Failure */2,
                                                    _0: "Something went wrong. Please contact\n        admin"
                                                  },
                                                  edited: state.edited
                                                };
                                        }))));
                    });
        });
    
  };
  var alertType = state.notification;
  return React.createElement("div", {
              className: "bg-white w-5/12 fixed h-screen top-0 right-0 z-10 shadow border-l border-gray-200"
            }, React.createElement("div", {
                  className: "flex justify-between bg-antiflashwhitegrey px-6 py-5 text-lg font-semibold"
                }, "Campaign Configuration", alertType !== undefined ? React.createElement(AlertComponent$ReactHooksTemplate.make, {
                        alertType: alertType,
                        handleClose: (function (param) {
                            return Curry._1(setState, (function (state) {
                                          return {
                                                  campaign: state.campaign,
                                                  notification: undefined,
                                                  edited: state.edited
                                                };
                                        }));
                          })
                      }) : React.createElement(React.Fragment, undefined), React.createElement("div", {
                      className: "flex items-center"
                    }, React.createElement("button", {
                          className: "focus:outline-none",
                          onClick: onClose
                        }, React.createElement("img", {
                              className: "w-3",
                              src: Image$ReactHooksTemplate.closeIcon
                            })))), React.createElement("div", {
                  className: "py-6 px-10 overflow-auto max-h-screen"
                }, React.createElement("div", {
                      className: "border-b border-gray-300 mb-8"
                    }, React.createElement("div", {
                          className: "flex justify-between pb-8"
                        }, React.createElement("div", {
                              className: "flex text-base font-medium items-center"
                            }, React.createElement("img", {
                                  className: "w-4 mr-1",
                                  src: Image$ReactHooksTemplate.cogTransparent
                                }), "Campaign Details"), React.createElement("div", {
                              className: "flex"
                            }, React.createElement("button", {
                                  className: state.edited ? "border rounded p-2 text-sm text-darkcharcoal bg-white mr-2" : "border text-gray-400 rounded p-2 text-sm disabled-input mr-2",
                                  disabled: !state.edited,
                                  onClick: updateCampaignSettings
                                }, "Update"), React.createElement("img", {
                                  className: "w-3",
                                  src: Image$ReactHooksTemplate.minus
                                }))), React.createElement("div", {
                          className: "px-4 py-8"
                        }, $$Array.of_list(List.map((function (x) {
                                    var y = ApplicationSetting$ReactHooksTemplate.individualSetting(x);
                                    var tmp;
                                    switch (y.TAG | 0) {
                                      case /* SavePartialResponse */0 :
                                          var partial_arg = ApplicationSetting$ReactHooksTemplate.id(x);
                                          tmp = React.createElement(SavePartialResponses$ReactHooksTemplate.make, {
                                                setting: y._0,
                                                enforce: ApplicationSetting$ReactHooksTemplate.enforce(x),
                                                enforced: ApplicationSetting$ReactHooksTemplate.enforced(x),
                                                onChange: (function (param, param$1) {
                                                    return onChange(partial_arg, param, param$1);
                                                  })
                                              });
                                          break;
                                      case /* GenerateTicket */1 :
                                          var partial_arg$1 = ApplicationSetting$ReactHooksTemplate.id(x);
                                          tmp = React.createElement(GenerateTickets$ReactHooksTemplate.make, {
                                                setting: y._0,
                                                enforce: ApplicationSetting$ReactHooksTemplate.enforce(x),
                                                enforced: ApplicationSetting$ReactHooksTemplate.enforced(x),
                                                onChange: (function (param, param$1) {
                                                    return onChange(partial_arg$1, param, param$1);
                                                  })
                                              });
                                          break;
                                      case /* CampaignModes */2 :
                                          var partial_arg$2 = ApplicationSetting$ReactHooksTemplate.id(x);
                                          tmp = React.createElement(CampaignMode$ReactHooksTemplate.make, {
                                                setting: y._0,
                                                enforce: ApplicationSetting$ReactHooksTemplate.enforce(x),
                                                enforced: ApplicationSetting$ReactHooksTemplate.enforced(x),
                                                onChange: (function (param, param$1) {
                                                    return onChange(partial_arg$2, param, param$1);
                                                  })
                                              });
                                          break;
                                      
                                    }
                                    return React.createElement("div", {
                                                className: "mb-16"
                                              }, tmp);
                                  }), Campaign$ReactHooksTemplate.applicationSettings(state.campaign))))), React.createElement("div", undefined)));
}

var make = CampaignConfiguration;

exports.make = make;
/* react Not a pure module */
