// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var ThankYou$ReactHooksTemplate = require("./ThankYou.bs.js");

function ThankYouForm(Props) {
  var $$default = Props.default;
  var thankYou = Props.thankYou;
  var redirectionUrl = Props.redirectionUrl;
  React.useEffect((function () {
          var timerId = setTimeout((function (param) {
                  if (redirectionUrl !== undefined) {
                    window.location.href = redirectionUrl;
                    return ;
                  }
                  
                }), 1000);
          return (function (param) {
                    clearTimeout(timerId);
                    
                  });
        }), []);
  var withDefault = function ($$default, x) {
    if (x.length === 0) {
      return $$default;
    } else {
      return x;
    }
  };
  var urlPath = ThankYou$ReactHooksTemplate.image(thankYou);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "flex flex-col w-full mt-48-px small:mt-40-px"
                }, urlPath !== undefined ? React.createElement("img", {
                        className: "w-full h-275-px small:h-200-px mb-48-px",
                        src: window.location.origin + urlPath
                      }) : React.createElement(React.Fragment, undefined), React.createElement("p", {
                      className: " mb-8-px font-semibold text-2xl leading-8 text-center\n      text-black small:text-lg mix-blend-normal"
                    }, "Thank you for your feedback!"), React.createElement("p", {
                      className: " pb-16-px font-normal leading-6 text-base text-center small:text-lg tracking-0.8"
                    }, withDefault(ThankYou$ReactHooksTemplate.text($$default), ThankYou$ReactHooksTemplate.text(thankYou)), React.createElement("br", undefined))), RandomId$ReactHooksTemplate.toString(ThankYou$ReactHooksTemplate.uuid(thankYou)) === "igbWjXwJEjAxcXEH" ? React.createElement("div", {
                    className: "w-full px-8 py-5 flex flex-col bg-pinetree justify-center mx-auto my-4"
                  }, React.createElement("div", {
                        className: "flex justify-center text-white text-xs"
                      }, "Powered by"), React.createElement("div", {
                        className: "flex justify-center items-center mt-3"
                      }, React.createElement("a", {
                            href: "https://www.geckolyst.com/"
                          }, React.createElement("img", {
                                className: "h-30-px",
                                src: Image$ReactHooksTemplate.geckolystWhiteLogo
                              }))), React.createElement("div", {
                        className: "flex flex-col px-3 mt-3 justfy-center items-center leading-loose font-sans"
                      }, React.createElement("p", {
                            className: "text-center text-sm small:text-xs font-light text-white"
                          }, "CX and Feedback Analytics with an exclusive ", React.createElement("strong", {
                                className: "underline"
                              }, "2-month free trial"), ", designed to uncover"), React.createElement("p", {
                            className: "text-center text-sm small:text-xs font-light text-white mb-10"
                          }, "valuable insights and enhance your customer experience. "), React.createElement("a", {
                            className: "block transition-transform transform hover:scale-105 hover:shadow-lg",
                            href: "https://www.geckolyst.com/ecom-churn"
                          }, React.createElement("div", {
                                className: "flex flex-wrap font-semibold small:text-xs justify-center bg-lightmintgreen px-5 small:px-2 py-2 rounded mb-5"
                              }, React.createElement("span", {
                                    className: "text-black"
                                  }, "Click Here to Claim Your Free Trial"))), React.createElement("p", {
                            className: "text-center text-white text-md small:text-xs"
                          }, "Don't miss out. Register now and start transforming your feedback into action!"))) : React.createElement(React.Fragment, undefined));
}

var make = ThankYouForm;

exports.make = make;
/* react Not a pure module */
